import React, { useEffect, useState } from "react";
import "./styles.scss";
import toast from "react-hot-toast";
import { deleteData, getData, putData } from "../../../services";
import { confimationStyles, errorStyles } from "../../../assets/styles/toast";
import { useCookies } from "react-cookie";
import Delete from "../../icons/Delete";
import SelectDropdown from "../../molecules/Dropdown";
import CustomButton from "../../atoms/CustomButton";
import DeleteModal from "../modals/DeleteModal";
import Add from "../../icons/Add";
import Modal from "../modals/Modal";
import axios from "axios";
import { formatValue } from "../../../helpers/formatValue";
import MultipleUserSelectComponent from "../../molecules/Multiselect";
import { predefinedData } from "../../../helpers/staticData";

const currencies = [
  { value: "INR", label: "INR" },
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "AED", label: "AED" },
];

const units = [
  { value: "Actual", label: "Actual" },
  { value: "Hundreds", label: "Hundreds" },
  { value: "Thousands", label: "Thousands" },
  { value: "Lakhs", label: "Lakhs" },
  { value: "Crore", label: "Crore" },
  { value: "Million", label: "Million" },
  { value: "Billion", label: "Billion" },
];

// const dropdownOptionsMapping = {
//   "CURRENT ASSETS": ["Option A", "Option B", "Option C"], // Dropdown options for key1
//   "NON-CURRENT ASSETS": ["Option X", "Option Y", "Option Z"], // Dropdown options for key2
//   "CURRENT LIABILITIES": ["Option 1", "Option 2", "Option 3"], // Dropdown options for key3
//   "NON-CURRENT LIABILITIES": ["Option 1", "Option 2", "Option 3"], // Dropdown options for key3
//   "SHAREHOLDERS EQUITY": ["Option 1", "Option 2", "Option 3"], // Dropdown options for key3
//   // Add more key-to-options mappings as needed
// };
const dropdownOptionsMapping = {
  "Sales/Revenue/Turnover": ["Sales", "Revenue", "Turnover", "Other"],
  "COGS/Cost of goods sold/Cost of Sales": ["Other"],
  "Operating Expenses/Income": [
    "Accountancy",
    "Accountancy Fees",
    "Administration Wages",
    "Administrative Expenses",
    "Advertising",
    "Advertising and Marketing",
    "Advertising and Trade Marketing",
    "Advertising and Entertaining",
    "Advertising and Promotion",
    "Amazon Advertisement",
    "Amazon Fees",
    "Amortisation",
    "Audit and Accountancy fees",
    "Audit Fees",
    "Automobile Expense",
    "Bad and doubtful debts",
    "Bank and ATM Fee Expense",
    "Bank Fees",
    "Bank Interest and Charges",
    "Bank loan interest",
    "Bank Revaluations",
    "Bank Service Charges",
    "Bonuses",
    "Books",
    "Building repairs",
    "Building Works",
    "Bureau Processing Charges",
    "Business Licenses and Permits",
    "Business Meals Expense",
    "Canteen",
    "Car hire",
    "Charitable and Political Donations",
    "Charitable Contributions",
    "Cleaning",
    "Cleaning and Laundry",
    "Cleaning and Waste",
    "Client Entertainment",
    "Clothing Costs",
    "Commercial Insurances",
    "Commissions",
    "Company Cars (DD)",
    "Compensation of Officer",
    "Computer and Internet Expenses",
    "Computer Costs",
    "Computer Equipment Expense",
    "Computer Software",
    "Consultancy Fees",
    "Contract worker salary",
    "Corporation Tax",
    "Council Chambers",
    "Credit Charges",
    "Credit Insurance",
    "Customs Charges",
    "Directors' pension costs - defined contribution scheme",
    "Directors' Remuneration",
    "Directors Salaries",
    "Directors' social security costs",
    "Discount Allowed and Doubtful Debts",
    "Donations",
    "Dues and Membership Expense",
    "Dues and Subscriptions",
    "eBay Fees",
    "Employee Training Cost",
    "Employers National Insurance",
    "Entertainment",
    "Environmental health and safety",
    "Equipment Expense",
    "Equipment Hire Costs",
    "Equipment Leasing",
    "Equipment repairs",
    "Evergreen and Charges",
    "Facebook advertisement",
    "Facility and Utilities Expense",
    "Fees",
    "Foreign agents' Fee",
    "Foreign Currency Gains and Losses",
    "Foreign Services",
    "Freight",
    "Furniture and Fixtures Expense",
    "Gas and Auto Expense",
    "General Expenses",
    "General Rates",
    "Gifts and Samples",
    "Gifts Expense",
    "Google Advertising and Marketing",
    "Gross Salaries",
    "Gross Wages",
    "Health and safety",
    "Health insurance",
    "Hitachi Charges",
    "Holiday pay adjustment",
    "Hotels",
    "Independent Contractor Expense",
    "Insurance",
    "Insurance Expense - Auto",
    "Insurance Expense - Business",
    "Insurances (not premises)",
    "Internet charges",
    "IT expenses",
    "IT Software and Consumables",
    "IT Support",
    "Job Advertisement",
    "Klarna Fees",
    "Laundry",
    "Lease and Printing Costs",
    "Leasing - motor vehicles",
    "Leasing Costs",
    "Legal and Professional Fees",
    "Legal Expenses",
    "License and Fee Expense",
    "Licenses and Permits",
    "Licensing",
    "Light, Power, water, Heating",
    "Marketing and Advertising Expense",
    "Meals and Entertainment",
    "Medical",
    "Medical Insurance",
    "Merchant Fees Expense",
    "Mileage claims",
    "Motor and Travel",
    "Motor expenses and associated exp (SUNDRY)",
    "Motor Vehicle Expenses",
    "Nondeductible Expense",
    "Nondeductible Fines and Penalties",
    "Office Cleaning",
    "Office Equipment Depreciation",
    "Office Expense",
    "Office Machine Maintenance",
    "Office Stationery",
    "Office Supplies",
    "Office Supply Expense",
    "Operating Lease Payments",
    "Outside Services",
    "Outsourced work",
    "Packaging supplies",
    "Parking and Tolls Expense",
    "Parking Fee",
    "Payroll Expense - Administration",
    "Payroll Expense - Payroll Tax",
    "Payroll Expense - Salary and Wage",
    "Payroll Expenses",
    "Payroll Tax Expenses",
    "Pension Contributions",
    "Pensions Costs",
    "Personnel Expenses",
    "Phone and Internet Expense",
    "Plant Maintenance and Service Contracts",
    "Postage and Delivery",
    "Postage and Shipping Expense",
    "Postage and Carriage",
    "Postage, courier and delivery charges",
    "Postage, Freight and Courier",
    "PPS and telephone",
    "Premises Expenses",
    "Printing and Stationery",
    "Printing and Reproduction",
    "Printing, Postage and Stationery",
    "Productive labour Rent and Rates Electricity Management charges",
    "Professional Fees",
    "Professional Service Expense",
    "Property repairs and maintenance",
    "Rates",
    "Rates and Water (DD)",
    "Recruiting and HR Expense",
    "Rent and Rates",
    "Rent Expense",
    "Rent or Lease Expense",
    "Rent receivable",
    "Repairs and Maintenance",
    "Repairs and Renewals",
    "Research and Development",
    "Research and Development Expense",
    "Revenue Grant Income",
    "Salaries",
    "Salary and Wages",
    "Security and CCTV",
    "Security Costs",
    "Shipping",
    "Social Committee Expenses",
    "Social security costs",
    "Software and Web Hosting Expense",
    "Staff Canteen",
    "Staff pension costs defined contribution",
    "Staff Salaries",
    "Staff Training",
    "Stationery (CONSUMABLES)",
    "Storage Costs",
    "Stripe Fees",
    "Subscriptions and licences",
    "Subsistence",
    "Sundry Expenses",
    "Taxes",
    "Taxes Paid",
    "Telecommunications",
    "Telephone and Internet",
    "Telephone and Fax",
    "Telephone Expense",
    "Temporary staff",
    "Trade Finance Costs",
    "Tradeshow Expense",
    "Training and Education Expense",
    "Training Costs",
    "Transport and Carriage",
    "Travel and Transportation Expense",
    "Travel Expenses",
    "Twitter Advertisement",
    "Utilities",
    "Vehicle Fuel",
    "Wages and salaries",
    "Warranty labour",
    "Waste Collection",
    "Water Rates",
    "Website fees",
    "Write-back of over provision",
    "Other",
  ],
  "Depreciation and Amortization": ["Depreciation", "Amortization", "Other"],
  "Finance Expenses/Income": [
    "Bad Debt Provision",
    "Bad Debt Write Off",
    "Bank Charges",
    "CBIL Interest credit",
    "Currency Charges",
    "Donations",
    "evergreen Credit Charges",
    "Exchange Rate Variance",
    "Factoring Charges",
    "H.P. Interest",
    "Hire purchase interest payable",
    "Insurance claim",
    "Interest Paid",
    "Invoice finance interest payable",
    "Lease & HP Charges",
    "Loan Interest",
    "Loan Interest Paid",
    "Mispostings Account",
    "P&L on sale of assets",
    "Other interest",
    "Other",
  ],
  "Other Non-Operating Expenses": [
    "Losses/(Gains) on Foreign Exchange",
    "Profit or loss on foreign exchange",
    "Profit or loss on sale of tangible assets (non exceptional)",
    "Realised Currency Gains",
    "Sale of fixed assets",
    "Unrealised Currency Gains",
    "Other",
  ],
  "Tax Expenses": ["Current Tax", "Deferred Tax", "Other"],
  "CURRENT ASSETS": [
    "Accounts Receivable",
    "Advances Suppliers",
    "Associated company accounts",
    "Cash at Bank & in Hand",
    "Corporation Tax",
    "Debtors Control Account",
    "Deferred income",
    "Directors current account",
    "Goods-in Billed Early",
    "Instant Access",
    "Inter-company account",
    "Inventory",
    "Inventory in Transit",
    "Other receivables",
    "Petty Cash",
    "Prepayments",
    "Prepayments & Other Debtors",
    "Stock - Finished Goods",
    "Stock - Raw Material",
    "Stock - WIP",
    "Trade Debtors",
    "Trade Debtors less provision",
    "Marketable Securities",
    "Short-term Investments",
    "Other",
  ],
  "NON-CURRENT ASSETS": [
    "Block plant",
    "Buildings",
    "BUSINESS CAPITAL - (PLANT)",
    "Computer equipment",
    "Fixtures and fittings",
    "Hess Project Costs",
    "Goodwill",
    "Patents",
    "Other Intangible Assets",
    "Leasehold Improvements",
    "Mass Plant Improvements",
    "Mezz Build",
    "Nlotor Vehicles",
    "Office Equipment",
    "Plant & Machinery",
    "Property",
    "Tipping Cells",
    "Long-Term Investments",
    "Other",
  ],
  "CURRENT LIABILITIES": [
    "Accounts Payable",
    "Accruals",
    "Associated company accounts",
    "Cash at Bank & in Hand",
    "Close liquidity loan",
    "Corporation Tax payable",
    "Credit card",
    "Creditors Control Account",
    "Directors' Loan Account",
    "Factoring account",
    "Finance Agreement",
    "Funding circle loan",
    "Hire Purchase due 1 year",
    "Intercompany Accounts",
    "Loan Account",
    "Loan due < 1 year",
    "Loans Due 1 year",
    "Loans due < 1 year",
    "Other Creditors",
    "Other payroll deductions",
    "PAYE Payable",
    "Payroll Taxes",
    "Pensions Payable",
    "Provision for Corporation Tax",
    "Refinance",
    "Social Security Costs",
    "Student Loan Deductions Payable",
    "Suspense account",
    "Trade Capital",
    "Trade Creditors",
    "VAT",
    "Wages Payable - Payroll",
    "Other",
  ],
  "NON-CURRENT LIABILITIES": [
    "Assets Loan (HP)",
    "Child Maintenance Payable",
    "Deferred Tax",
    "Director's Loan",
    "Funding circle - 1 - 5 years",
    "Government Grants",
    "Hire Purchase due > 1 year",
    "Hire Purchase Loan",
    "Loan accounts > 1 year",
    "Loan due > 1 year",
    "Loan Interest",
    "Long Term Bank Loans",
    "Long Term HP & Leasing",
    "Other creditor",
    "Refinance - 1 - 5 years",
    "Sundry provision",
    "Total Creditors: amounts falling due after more than one year",
    "Trade Creditors",
    "Unit refit loan > 1 year",
    "Other",
  ],
  "SHAREHOLDERS EQUITY": [
    "Called-up share capital",
    "Capital & Reserves",
    "Share Capital",
    "Capital redemption reserve",
    "Capital Stock",
    "Current Year Earnings",
    "Dividends declared",
    "Legal Reserve",
    "Loss for period",
    "P & L Account",
    "P&L brought forward",
    "Paid-up Share Capital",
    "Reserves",
    "Retained Earnings",
    "Revaluation Reserve",
    "Share premium",
    "Shareholder's Current Account",
    "Total Capital and Reserves",
    "Other",
  ],
};

const FinancialDataTable = ({
  financialData,
  handleCancel,
  sheetType,
  auditStatus,
  periodType,
  companyId,
  edit = true,
  onDataConverted1,
  onDataConverted2,
  preview,
  year,
  exunit,
  excurrency,
  view,
  unitsNew,
  currencyNew,
  extracted,
}) => {
  const [cookies] = useCookies(["t"]);
  const [editable, setEditable] = useState(false);
  const [editedData, setEditedData] = useState(financialData?.data);
  const [currency, setCurrency] = useState("");
  const [unit, setUnit] = useState("");
  const [newMetrics, setNewMetrics] = useState([]);
  const [isLoadingDel, setIsLoadingDel] = useState(false); // Add loading state
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "Financial Data",
  });
  const [editingYears, setEditingYears] = useState({});
  const [isAddMetricModalOpen, setIsAddMetricModalOpen] = useState(false);
  const [newMetricType, setNewMetricType] = useState("1");
  const [nestedLevels, setNestedLevels] = useState(1);
  const [isAddNestedRowModalOpen, setIsAddNestedRowModalOpen] = useState(false);
  const [newNestedRowType, setNewNestedRowType] = useState("1");
  const [currentParentKey, setCurrentParentKey] = useState("");
  const [thousandsRates, setThousandsRates] = useState({});
  // const [originalCurrency, setOriginalCurrency] = useState();

  // setOriginalCurrency(financialData?.currencyAndUnits?.currency);

  const originalCurrency = financialData?.currencyAndUnits?.currency;

  console.log("originalCurrency", originalCurrency);
  console.log("currency--->", currency);

  console.log("financialData---->", financialData);
  console.log("editingYears-------->", editingYears);
  const [myConversionRate, setMyConversionRate] = useState(null);

  const fetchCurrencyConversionRates1 = async () => {
    try {
      const response = await axios.get(
        `https://open.er-api.com/v6/latest/${originalCurrency}`,
      );

      if (response && response.data) {
        const rate = response.data.rates[currency];
        setMyConversionRate(rate);
      }
    } catch (error) {
      console.error("Error fetching currency conversion rates:", error);
    }
  };

  useEffect(() => {
    fetchCurrencyConversionRates1();
  }, [currency]);

  useEffect(() => {
    const fetchCurrencyConversionRates = async () => {
      try {
        const response = await axios.get(
          "https://open.er-api.com/v6/latest/INR",
        );

        if (response) {
          setThousandsRates(response?.data?.rates);
          // setConversionRates(response?.data?.rates);
        }
      } catch (error) {
        console.error("Error fetching currency conversion rates:", error);
      }
    };

    fetchCurrencyConversionRates();
  }, []);

  const conversionRates = {};

  for (const currency in thousandsRates) {
    conversionRates[currency] = {
      Actual: thousandsRates[currency] * 1, // Direct multiplier of 1
      Hundreds: thousandsRates[currency] * 0.01, // Divide by 100
      Thousands: thousandsRates[currency] * 0.001, // Divide by 1,000
      Lakhs: thousandsRates[currency] * 0.00001, // Divide by 100,000
      Million: thousandsRates[currency] * 0.000001, // Divide by 1,000,000
      Crore: thousandsRates[currency] * 0.0000001, // Divide by 10,000,000
      Billion: thousandsRates[currency] * 0.000000001, // Divide by 1,000,000,000
    };
  }

  useEffect(() => {
    if (financialData?.currencyAndUnits) {
      const years = Object.keys(financialData.currencyAndUnits);
      if (years.length > 0) {
        setCurrency(financialData.currencyAndUnits.currency);
        setUnit(financialData.currencyAndUnits.units);
      }
    }
  }, []);

  useEffect(() => {
    if (financialData?.currency || financialData?.units) {
      setCurrency(financialData?.currency);
      setUnit(financialData?.units);
    }
  }, []);

  useEffect(() => {
    setEditedData(financialData?.data);
  }, [financialData]);

  const toggleEdit = () => {
    setEditable(!editable);
    if (!editable) {
      setEditedData(financialData?.data);
      setEditingYears(Object.fromEntries(years.map((year) => [year, year])));
    } else {
      setEditingYears({});
    }
  };

  const AddMetricModal = () => (
    <Modal
      id="add-metric-modal"
      title="Add New Metric"
      isActive={isAddMetricModalOpen}
      onClose={() => setIsAddMetricModalOpen(false)}
      width="400px"
    >
      <div>
        {/* Container for radio buttons */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between", // Aligns radio buttons left and right
            // justifyContent: "space-evenly",
            marginBottom: "10px", // Space below the radio buttons
          }}
        >
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              value="1"
              checked={newMetricType === "1"}
              onChange={(e) => setNewMetricType(e.target.value)}
              style={{
                height: "20px", // Increase height for bigger size
                width: "20px", // Increase width for bigger size
                accentColor: "#145b64", // Change the color of the radio button to green
              }}
            />
            <span style={{ marginLeft: "10px", fontSize: "16px" }}>
              Normal Metric
            </span>{" "}
            {/* Adds space between radio button and text */}
          </label>

          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              value="2"
              checked={newMetricType === "2"}
              onChange={(e) => setNewMetricType(e.target.value)}
              style={{
                height: "20px", // Increase height for bigger size
                width: "20px", // Increase width for bigger size
                accentColor: "#145b64", // Change the color of the radio button to green
              }}
            />
            <span style={{ marginLeft: "10px", fontSize: "16px" }}>
              Nested Metric
            </span>{" "}
            {/* Space and size of text */}
          </label>
        </div>

        {/* Nested levels input (shown only when "Nested Metric" is selected) */}
        {newMetricType === "2" && (
          <div style={{ marginTop: "30px" }}>
            <label style={{ fontSize: "16px" }}>
              Number of nested levels :
              <input
                type="number"
                min="1"
                value={nestedLevels}
                onChange={(e) => setNestedLevels(parseInt(e.target.value, 10))}
                style={{
                  marginLeft: "10px",
                  width: "60px",
                  height: "40px",
                  padding: "10px",
                }}
              />
            </label>
          </div>
        )}

        {/* Submit button */}
        <div
          style={{
            marginTop: "20px",
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            type="btn-primary"
            iconRequired={false}
            handleClick={handleAddMetricSubmit}
          >
            Submit
          </CustomButton>
        </div>
      </div>
    </Modal>
  );

  const handleYearChange = (oldYear, newYear) => {
    // Update the state based on the new year input
    setEditingYears((prev) => ({
      ...prev,
      [oldYear]: newYear === "" ? "" : newYear, // Reset to empty string if input is cleared
    }));
  };

  const handleCellValueChange = (year, key, value) => {
    const updatedData = { ...editedData };
    const keys = key.split(".");
    let data = updatedData[year];
    for (let i = 0; i < keys.length - 1; i++) {
      data = data[keys[i]];
    }
    data[keys[keys.length - 1]] = value;
    setEditedData(updatedData);
  };

  const myyear = financialData?.data;
  const extractedYears = Object.keys(myyear).map((year) => year.trimStart());

  const validateBalanceSheet = (editedData) => {
    for (const year in editedData) {
      if (editedData.hasOwnProperty(year)) {
        const yearData = editedData[year];

        const currentAssets = yearData?.["CURRENT ASSETS"] || [];
        const nonCurrentAssets = yearData?.["NON-CURRENT ASSETS"] || [];
        const currentLiabilities = yearData?.["CURRENT LIABILITIES"] || [];
        const nonCurrentLiabilities =
          yearData?.["NON-CURRENT LIABILITIES"] || [];
        const shareholderEquity = yearData?.["SHAREHOLDERS EQUITY"] || [];

        const totalAssets =
          sumCurrentAssets(currentAssets) + sumCurrentAssets(nonCurrentAssets);
        const totalLiabilities =
          sumCurrentAssets(currentLiabilities) +
          sumCurrentAssets(nonCurrentLiabilities);
        const totalEquity = sumCurrentAssets(shareholderEquity);

        console.log(
          `Year ${year} -> Total Assets: ${totalAssets}, Total Liabilities: ${totalLiabilities}, Total Equity: ${totalEquity}`,
        );

        if (
          totalAssets?.toFixed(2) !==
          (totalLiabilities + totalEquity)?.toFixed(2)
        ) {
          console.log(`Balance sheet validation failed for year ${year}`);
          return false;
        }
      }
    }

    // Return true if all years are valid
    return true;
  };

  const handleSave = async () => {
    if (sheetType === "balance_sheet") {
      const isValid = validateBalanceSheet(editedData);

      if (!isValid) {
        alert("Balance sheet is invalid. Please check the data.");
        return;
      }
    }

    // Process editedData to trim the leading spaces in year keys
    const processEditedData = (data) => {
      const processedData = {};
      Object.keys(data).forEach((year) => {
        const newYear = editingYears[year] || year;
        const trimmedYear = newYear.trimStart(); // Trim leading spaces
        const yearData = data[year];
        processedData[trimmedYear] = {};

        const processObject = (obj, target) => {
          for (let key in obj) {
            const value = obj[key];
            if (typeof value === "object" && value !== null) {
              target[key] = {};
              processObject(value, target[key]);
            } else {
              target[key] = value;
            }
          }
        };

        processObject(yearData, processedData[trimmedYear]);

        // Add new metrics to processedData
        newMetrics.forEach((metric) => {
          processedData[trimmedYear][metric.name] = metric.values[year] || "";
        });
      });
      return processedData;
    };

    const updatedData = {
      sheetType,
      periodType,
      auditStatus,
      editedData: processEditedData(editedData),
      extractedYears: extractedYears,
    };

    try {
      const res = await putData({
        endpoint: "company/updateExtractedData",
        params: { companyId },
        token: cookies.t,
        data: updatedData,
      });

      console.log("updatedData-------->", updatedData);

      if (res.status) {
        toast.success(res.message, {
          style: confimationStyles,
          duration: 2000,
        });
        // Clear new metrics after successful save
        setNewMetrics([]);

        console.log("FUnction :", handleCancel);

        if (handleCancel) {
          console.log("HElooooo");
          console.log("Heloooo2");
          console.log(
            "companyId",
            companyId,
            auditStatus,
            periodType,
            extractedYears,
            sheetType,
          );
          await handleCancel(
            companyId,
            auditStatus,
            periodType,
            extractedYears,
            sheetType,
          );
        }
      } else {
        toast.error(res.message, { style: errorStyles, duration: 1000 });
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
    setEditable(false);
  };

  // const handleDelete = (id, name) => {
  //   setModalState({
  //     isDeleteModalOpen: true,
  //     modalId: id,
  //     name: name,
  //   });
  // };
  const handleDelete = (year) => {
    setModalState({
      isDeleteModalOpen: true,
      // modalId: id,
      name: year,
    });
    // // Create a copy of the data to maintain immutability
    // const updatedData = { ...editedData };

    // // Delete the key corresponding to the year
    // delete updatedData[year];

    // // Update the state with the modified data
    // setEditedData(updatedData);

    // // Optional: Perform additional actions like API calls to persist the change
    // console.log(`Deleted year: ${year}`, updatedData);
  };

  console.log("extractedYears------->", extractedYears);

  const handleDeleteConfirm = async (myYear) => {
    setIsLoadingDel(true); // Set loading to true when API call starts
    // Assuming `years` is an array and you want to extract only the years from some complex object

    try {
      // Add your delete logic here, for example:
      const res = await deleteData({
        endpoint: "company/deleteFinancialDataByYears",
        params: {
          companyId: companyId,
          sheetType: sheetType,
          periodType: periodType,
          auditStatus: auditStatus,
          // yearsArray: year || extractedYears,
          yearsArray: [`${myYear}`],
        },
        token: cookies.t,
      });

      if (res?.data?.status) {
        setEditedData((prevData) => {
          const updatedData = { ...prevData };
          delete updatedData[myYear];
          return updatedData;
        });
        toast.success("Financial Data Deleted Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000); // Delay the reload to ensure the toast is shown for the full duration      } else {
      }

      // Refresh the data after deletion
    } catch (error) {
      console.error(error);
      setEditedData((prevData) => {
        const updatedData = { ...prevData };
        delete updatedData[myYear];
        return updatedData;
      });
    } finally {
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
      setIsLoadingDel(false);
    }
  };

  const onCancel = async () => {
    if (handleCancel) {
      console.log("Helllooo");
      console.log("extractedYears--------->", extractedYears);
      handleCancel(
        companyId,
        auditStatus,
        periodType,
        extractedYears,
        sheetType,
      );
    }
    setEditable(false);
  };

  const convertValue = (value, year, currency, unit) => {
    // Check if value is a string and convert to a number
    if (typeof value === "string") {
      value = parseFloat(value.replace(/[^0-9.-]/g, "")); // Remove non-numeric characters except dot and minus
      // value = parseFloat(value.replace(/[^0-9,.-]/g, ""));
    }

    // Check if value is not a number
    if (isNaN(value)) {
      console.error("Invalid value for conversion:", value);
      return "-";
    }

    const currentCurrency = financialData?.currencyAndUnits?.currency;
    const currentUnit = financialData?.currencyAndUnits?.units;

    if (
      !currentCurrency ||
      !currentUnit ||
      !conversionRates[currentCurrency] ||
      !conversionRates[currentCurrency][currentUnit]
    ) {
      return value;
    }

    const inBaseUnit = value / conversionRates[currentCurrency][currentUnit];
    const convertedValue =
      inBaseUnit * (conversionRates[currency]?.[unit] || 1);

    return parseFloat(convertedValue.toFixed(2));
  };

  const handleCurrencyChange = (selectedOption) => {
    setCurrency(selectedOption);
  };

  const handleUnitChange = (selectedOption) => {
    setUnit(selectedOption);
  };

  const getAllKeys = (data) => {
    let keys = new Set();
    for (let year in data) {
      const yearData = data[year];
      const extractKeys = (obj, parentKey = "") => {
        for (let key in obj) {
          const newKey = parentKey ? `${parentKey}.${key}` : key;
          keys.add(newKey);
          if (typeof obj[key] === "object" && obj[key] !== null) {
            extractKeys(obj[key], newKey);
          }
        }
      };
      extractKeys(yearData);
    }
    return Array.from(keys);
  };

  // const years = Object.keys(editedData || {});
  // const years = Object.keys(editedData || {});
  const allKeys = getAllKeys(editedData || {});

  const [years, setYears] = useState(Object.keys(editedData || {}));

  useEffect(() => {
    setYears(Object.keys(editedData));
  }, [editedData]);

  console.log("years--->", years);

  const groupKeysByParent = (keys) => {
    const groupedKeys = {};
    keys.forEach((key) => {
      // Skip keys with space after dot
      if (key.includes(". ") || key.includes(" .")) {
        groupedKeys[key] = null;
        return;
      }

      const parts = key.split(".");
      let current = groupedKeys;
      parts.forEach((part, index) => {
        if (!current[part]) {
          current[part] = index === parts.length - 1 ? null : {};
        }
        current = current[part];
      });
    });
    return groupedKeys;
  };

  const groupedKeys = groupKeysByParent(allKeys);

  const getNestedValue = (data, key) => {
    const keys = key.split(".");
    let value = data;
    for (let k of keys) {
      if (value === undefined || value === null) return "";
      value = value[k];
    }
    return value;
  };

  const handleDeleteRow = (fullKey) => {
    // const [mycategory, mysubcategory] = fullKey.split(".");
    // const getSubCategories = doopData[mycategory].filter(
    //   (item) => item !== mysubcategory,
    // );
    // setDoopData((prev) => ({
    //   ...prev, // spread the previous state
    //   [mycategory]: getSubCategories, // update the category with the filtered subcategories
    // }));
    console.log("Hii---fullkey", fullKey);

    setRowToDelete(fullKey);
    setIsDeleteModalOpen(true);
  };

  // const confirmDeleteRow = () => {
  //   if (!rowToDelete) return;
  //   // const [mycategory, mysubcategory] = rowToDelete.split(".");
  //   // const getSubCategories = doopData[mycategory].filter(
  //   //   (item) => item !== mysubcategory,
  //   // );
  //   // setDoopData((prev) => ({
  //   //   ...prev, // spread the previous state
  //   //   [mycategory]: getSubCategories, // update the category with the filtered subcategories
  //   // }));

  //   setEditedData((prevData) => {
  //     const newData = { ...prevData };
  //     years.forEach((year) => {
  //       const keys = rowToDelete.split(".");
  //       let current = newData[year];
  //       for (let i = 0; i < keys.length - 1; i++) {
  //         if (current[keys[i]] === undefined) {
  //           return newData; // Exit if the path doesn't exist
  //         }
  //         current = current[keys[i]];
  //       }
  //       delete current[keys[keys.length - 1]];
  //     });
  //     return newData;
  //   });

  //   toast.success("Row deleted. Changes will be applied when you save.", {
  //     style: confimationStyles,
  //     duration: 1000,
  //   });

  //   setIsDeleteModalOpen(false);
  //   setRowToDelete(null);
  // };
  const confirmDeleteRow = () => {
    if (!rowToDelete) return;
    const [mycategory, mysubcategory] = rowToDelete.split(".");
    const getSubCategories = doopData[mycategory].filter(
      (item) => item !== mysubcategory,
    );
    setDoopData((prev) => ({
      ...prev, // spread the previous state
      [mycategory]: getSubCategories, // update the category with the filtered subcategories
    }));

    setEditedData((prevData) => {
      const newData = { ...prevData };
      years.forEach((year) => {
        const keys = rowToDelete.split(".");
        let current = newData[year];
        for (let i = 0; i < keys.length - 1; i++) {
          if (current[keys[i]] === undefined) {
            return newData; // Exit if the path doesn't exist
          }
          current = current[keys[i]];
        }
        delete current[keys[keys.length - 1]];
      });
      return newData;
    });

    toast.success("Row deleted. Changes will be applied when you save.", {
      style: confimationStyles,
      duration: 2000,
    });

    setIsDeleteModalOpen(false);
    setRowToDelete(null);
  };

  const handleNewMetricNameChange = (parentKey, oldKey, newKey) => {
    setEditedData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData));
      years.forEach((year) => {
        let current = newData[year];
        const keys = parentKey ? parentKey.split(".") : [];
        for (let key of keys) {
          if (!current[key]) {
            current[key] = {};
          }
          current = current[key];
        }
        if (newKey && newKey !== oldKey) {
          current[newKey] = current[oldKey];
          delete current[oldKey];
        }
      });
      return newData;
    });
  };

  // const handleAddNestedRow = (parentKey) => {
  //   setCurrentParentKey(parentKey);
  //   setIsAddNestedRowModalOpen(true);
  // };

  const handleAddNestedRowSubmit = () => {
    setEditedData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData)); // Deep clone

      const createNestedStructure = (depth) => {
        if (depth === 0) return "";
        const nestedStructure = {};
        nestedStructure[`New Nested Metric ${depth}`] = createNestedStructure(
          depth - 1,
        );
        return nestedStructure;
      };

      years.forEach((year) => {
        let current = newData[year];
        const keys = currentParentKey.split(".");
        for (let key of keys) {
          if (!current[key]) {
            current[key] = {};
          }
          current = current[key];
        }

        if (newNestedRowType === "1") {
          // Normal metric
          let index = 1;
          while (current[`New Item ${index}`] !== undefined) {
            index++;
          }
          const newItemKey = `New Item ${index}`;
          current[newItemKey] = "";
        } else {
          // Nested metric
          const timestamp = Date.now(); // Generate a unique timestamp
          current[`New Nested Structure ${timestamp}`] =
            createNestedStructure(nestedLevels);
        }
      });

      return newData;
    });

    setIsAddNestedRowModalOpen(false);
    setNewNestedRowType("1");
    setNestedLevels(1);
    setCurrentParentKey("");
  };

  const AddNestedRowModal = () => (
    <Modal
      id="add-nested-row-modal"
      title="Add New Nested Row"
      isActive={isAddNestedRowModalOpen}
      onClose={() => setIsAddNestedRowModalOpen(false)}
      width="400px"
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between", // Aligns radio buttons left and right
            // justifyContent: "space-evenly",
            marginBottom: "10px", // Space below the radio buttons
          }}
        >
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              value="1"
              checked={newNestedRowType === "1"}
              onChange={(e) => setNewNestedRowType(e.target.value)}
              style={{
                height: "20px", // Increase height for bigger size
                width: "20px", // Increase width for bigger size
                accentColor: "#145b64", // Change the color of the radio button to green
              }}
            />
            <span style={{ marginLeft: "10px", fontSize: "16px" }}>
              Normal Metric
            </span>{" "}
            {/* Adds space between radio button and text */}
          </label>

          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="radio"
              value="2"
              checked={newNestedRowType === "2"}
              onChange={(e) => setNewNestedRowType(e.target.value)}
              style={{
                height: "20px", // Increase height for bigger size
                width: "20px", // Increase width for bigger size
                accentColor: "#145b64", // Change the color of the radio button to green
              }}
            />
            <span style={{ marginLeft: "10px", fontSize: "16px" }}>
              Nested Metric
            </span>{" "}
            {/* Space and size of text */}
          </label>
        </div>
        {newNestedRowType === "2" && (
          <div style={{ marginTop: "30px" }}>
            <label style={{ fontSize: "16px" }}>
              Number of nested levels :
              <input
                type="number"
                min="1"
                value={nestedLevels}
                onChange={(e) => setNestedLevels(parseInt(e.target.value, 10))}
                style={{
                  marginLeft: "10px",
                  width: "60px",
                  height: "40px",
                  padding: "10px",
                }}
              />
            </label>
          </div>
        )}
        <div
          style={{
            marginTop: "20px",
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            type="btn-primary"
            iconRequired={false}
            handleClick={handleAddNestedRowSubmit}
          >
            Submit
          </CustomButton>
        </div>
      </div>
    </Modal>
  );

  const generateOptions = (periodType) => {
    const currentYear = new Date().getFullYear();
    // const years = Array.from({ length: 25 }, (_, i) => currentYear - 24 + i); // Past 50 years + 1 future year
    const years = Array.from({ length: 25 }, (_, i) => currentYear - i); // Last 25 years in descending order

    if (periodType === "yearly") {
      return years.map((year) => ({ label: year.toString(), value: year }));
    }

    if (periodType === "halfYearly") {
      return years.flatMap((year) => [
        // { label: `${year} - H1`, value: `${year} - H1` },
        // { label: `${year} - H2`, value: `${year} - H2` },
        { label: `${year} - H2`, value: `${year} - H2` },
        { label: `${year} - H1`, value: `${year} - H1` },
      ]);
    }

    if (periodType === "quarterly") {
      return years.flatMap((year) => [
        // { label: `${year} - Q1`, value: `${year} - Q1` },
        // { label: `${year} - Q2`, value: `${year} - Q2` },
        // { label: `${year} - Q3`, value: `${year} - Q3` },
        // { label: `${year} - Q4`, value: `${year} - Q4` },
        { label: `${year} - Q4`, value: `${year} - Q4` },
        { label: `${year} - Q3`, value: `${year} - Q3` },
        { label: `${year} - Q2`, value: `${year} - Q2` },
        { label: `${year} - Q1`, value: `${year} - Q1` },
      ]);
    }

    if (periodType === "monthly") {
      // const months = [
      //   "Jan",
      //   "Feb",
      //   "Mar",
      //   "Apr",
      //   "May",
      //   "Jun",
      //   "Jul",
      //   "Aug",
      //   "Sep",
      //   "Oct",
      //   "Nov",
      //   "Dec",
      // ];
      const months = [
        "Dec",
        "Nov",
        "Oct",
        "Sep",
        "Aug",
        "Jul",
        "Jun",
        "May",
        "Apr",
        "Mar",
        "Feb",
        "Jan",
      ];
      return years.flatMap((year) =>
        months.map((month) => ({
          label: `${year} - ${month}`,
          value: `${year} - ${month}`,
        })),
      );
    }

    return [];
  };
  const [selectedOption1, setSelectedOption1] = useState([null]);

  // const AddYearModal = ({ isOpen, onClose, onSubmit }) => {
  //   const [newYear, setNewYear] = useState("");

  //   const handleSubmit = () => {
  //     const cleanedYears = years.map((year) => year?.toString()?.trim());
  //     const cleanedSelectedOptions = selectedOption1.map((year) =>
  //       year?.toString()?.trim(),
  //     );

  //     console.log("cleanedYears", cleanedYears);

  //     // Find the duplicates by filtering the selected options that exist in the years array
  //     const duplicateYears = cleanedSelectedOptions.filter((year) =>
  //       cleanedYears.includes(year),
  //     );

  //     if (duplicateYears.length > 0) {
  //       // If duplicates exist, join them into a comma-separated string
  //       const duplicateYearsStr = duplicateYears.join(", ");

  //       // Show error with the specific duplicate years
  //       toast.error(
  //         `Financial data already exists for ${duplicateYearsStr}. Please select different years.`,
  //         {
  //           style: errorStyles,
  //         },
  //       );
  //       return;
  //     }
  //     onSubmit(selectedOption1);
  //     setNewYear("");
  //     onClose();
  //   };

  //   return (
  //     <Modal
  //       id="add-year-modal"
  //       title={`Add ${
  //         periodType === "yearly"
  //           ? "Year"
  //           : periodType === "halfYearly"
  //           ? "Half Year"
  //           : periodType === "quarterly"
  //           ? "Quarter"
  //           : "Month"
  //       }`}
  //       isActive={isOpen}
  //       onClose={onClose}
  //       width="400px"
  //       scrollBar="visible" // Pass 'hidden' to hide overflow
  //     >
  //       <div className="p-0">
  //         <SelectDropdown
  //           data={generateOptions(periodType)}
  //           selectedValue={selectedOption1}
  //           onChange={(option) => {
  //             const sortedOptions = [...option].sort((a, b) => {
  //               if (typeof a === "string" && typeof b === "string") {
  //                 return b.localeCompare(a); // Descending order for strings
  //               }
  //               return b - a; // Descending order for numbers
  //             });
  //             setSelectedOption1(sortedOptions);
  //           }}
  //           placeholder={`Select ${
  //             periodType === "yearly"
  //               ? "Year"
  //               : periodType === "halfYearly"
  //               ? "Half Year"
  //               : periodType === "quarterly"
  //               ? "Quarter"
  //               : "Month"
  //           }`}
  //           isClearable
  //           multiSelect={true}
  //           onClear={() => setSelectedOption1([])} // Clear selected options
  //           onClose={false}
  //         />
  //         <div
  //           style={{
  //             display: "flex",
  //             justifyContent: "flex-end",
  //             marginTop: "20px",
  //           }}
  //         >
  //           <CustomButton
  //             type="btn-primary"
  //             iconRequired={false}
  //             handleClick={handleSubmit}
  //           >
  //             {/* Add Year */}
  //             {`Add ${
  //               periodType === "yearly"
  //                 ? "Year"
  //                 : periodType === "halfYearly"
  //                 ? "Half Year"
  //                 : periodType === "quarterly"
  //                 ? "Quarter"
  //                 : "Month"
  //             }`}
  //           </CustomButton>
  //         </div>
  //       </div>
  //     </Modal>
  //   );
  // };

  const AddYearModal = ({ isOpen, onClose, onSubmit }) => {
    const [newYear, setNewYear] = useState(""); // For custom input
    const [selectedOptions, setSelectedOptions] = useState([]); // For dropdown selections

    const handleSubmit = () => {
      const cleanedYears = years.map((year) => year?.toString()?.trim());
      const cleanedSelectedOptions = selectedOptions.map((year) =>
        year?.toString()?.trim(),
      );
      const customYear = newYear?.toString()?.trim();

      // Combine dropdown options and custom input
      const allSelectedYears = [
        ...cleanedSelectedOptions,
        ...(customYear ? [customYear] : []),
      ];

      // Find duplicates
      const duplicateYears = allSelectedYears.filter((year) =>
        cleanedYears.includes(year),
      );

      if (duplicateYears.length > 0) {
        const duplicateYearsStr = duplicateYears.join(", ");
        toast.error(
          `Financial data already exists for ${duplicateYearsStr}. Please select different years.`,
          { style: errorStyles },
        );
        return;
      }

      // Pass combined years to parent component
      onSubmit(allSelectedYears);
      setNewYear(""); // Clear custom input
      setSelectedOptions([]); // Clear dropdown selections
      onClose(); // Close modal
    };

    return (
      <Modal
        id="add-year-modal"
        title={`Add ${
          periodType === "yearly"
            ? "Year"
            : periodType === "halfYearly"
            ? "Half Year"
            : periodType === "quarterly"
            ? "Quarter"
            : "Month"
        }`}
        isActive={isOpen}
        onClose={onClose}
        width="400px"
        scrollBar="visible"
      >
        <div className="p-0">
          <SelectDropdown
            data={generateOptions(periodType)}
            selectedValue={selectedOptions}
            onChange={(options) => {
              const sortedOptions = [...options].sort((a, b) => {
                if (typeof a === "string" && typeof b === "string") {
                  return b.localeCompare(a); // Descending order for strings
                }
                return b - a; // Descending order for numbers
              });
              setSelectedOptions(sortedOptions);
            }}
            placeholder={`Select ${
              periodType === "yearly"
                ? "Year"
                : periodType === "halfYearly"
                ? "Half Year"
                : periodType === "quarterly"
                ? "Quarter"
                : "Month"
            }`}
            isClearable
            multiSelect={true}
            onClear={() => setSelectedOptions([])}
            onClose={false}
          />
          <div style={{ marginTop: "20px" }}>
            <input
              type="text"
              value={newYear}
              onChange={(e) => setNewYear(e.target.value)}
              placeholder="Enter custom name (e.g., YTD(Jan-Feb))"
              className="form-control"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <CustomButton
              type="btn-primary"
              iconRequired={false}
              handleClick={handleSubmit}
            >
              {`Add ${
                periodType === "yearly"
                  ? "Year"
                  : periodType === "halfYearly"
                  ? "Half Year"
                  : periodType === "quarterly"
                  ? "Quarter"
                  : "Month"
              }`}
            </CustomButton>
          </div>
        </div>
      </Modal>
    );
  };

  const [isAddYearModalOpen, setIsAddYearModalOpen] = useState(false);

  // const handleAddYear = (newYear) => {
  //   setEditedData((prevData) => {
  //     const newData = { ...prevData };

  //     // Copy structure from the first available year
  //     const firstYear = Object.keys(prevData)[0];
  //     const yearStructure = JSON.parse(JSON.stringify(prevData[firstYear]));

  //     console.log("firstYear", firstYear);
  //     console.log("yearStructure", yearStructure);

  //     // Clear all values in the structure
  //     const clearValues = (obj) => {
  //       Object.keys(obj).forEach((key) => {
  //         if (typeof obj[key] === "object" && obj[key] !== null) {
  //           clearValues(obj[key]);
  //         } else {
  //           obj[key] = "";
  //         }
  //       });
  //     };

  //     clearValues(yearStructure);
  //     newData[newYear] = yearStructure;

  //     console.log("newData", newData);

  //     setEditedData(newData);

  //     return newData;
  //   });

  //   toast.success(`Year ${newYear} added successfully`, {
  //     style: confimationStyles,
  //     duration: 2000,
  //   });
  // };

  // const handleAddYear = (newYears) => {
  //   setEditedData((prevData) => {
  //     const newData = { ...prevData };

  //     // Get the structure from the first available year
  //     const firstYear = Object.keys(prevData)[0];
  //     const yearStructure = JSON.parse(JSON.stringify(prevData[firstYear]));

  //     // Clear all values in the structure
  //     const clearValues = (obj) => {
  //       Object.keys(obj).forEach((key) => {
  //         if (typeof obj[key] === "object" && obj[key] !== null) {
  //           clearValues(obj[key]);
  //         } else {
  //           obj[key] = "";
  //         }
  //       });
  //     };

  //     clearValues(yearStructure);

  //     // Add each new year to the data
  //     newYears.forEach((year) => {
  //       if (!newData[year]) {
  //         newData[year] = yearStructure;
  //       }
  //     });

  //     console.log("newData", newData);

  //     setEditedData(newData);
  //     return newData;
  //   });

  //   toast.success(`Years ${newYears.join(", ")} added successfully`, {
  //     style: confimationStyles,
  //     duration: 2000,
  //   });
  // };

  const handleAddYear = (newYears) => {
    setEditedData((prevData) => {
      const newData = { ...prevData };

      // Copy structure from the first available year
      const firstYear = Object.keys(prevData)[0];
      const yearStructure = JSON.parse(JSON.stringify(prevData[firstYear]));

      console.log("firstYear", firstYear);
      console.log("yearStructure", yearStructure);

      // Function to clear all values in the structure
      const clearValues = (obj) => {
        Object.keys(obj).forEach((key) => {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            clearValues(obj[key]);
          } else {
            obj[key] = "";
          }
        });
      };

      clearValues(yearStructure);

      // Add each new year to the data
      newYears.forEach((year) => {
        if (!newData[year]) {
          newData[year] = JSON.parse(JSON.stringify(yearStructure));
        }
      });

      console.log("newData", newData);

      setEditedData(newData);

      return newData;
    });

    toast.success(`Years ${newYears.join(", ")} added successfully`, {
      style: confimationStyles,
      duration: 2000,
    });
  };

  console.log("editedDatatTATAT", editedData);
  // const renderRows = (data, parentKey = "", depth = 0) => {
  //   const rows = Object.entries(data).flatMap(([key, value]) => {
  //     const fullKey = parentKey ? `${parentKey}.${key}` : key;
  //     const isObject = typeof value === "object" && value !== null;
  //     const isNewRow = key.startsWith("New") || key.startsWith("Metrics");

  //     const paddingLeft = depth === 0 ? "10px" : `${depth * 40}px`;

  //     const currentRow = (
  //       <tr key={fullKey}>
  //         <td
  //           style={{
  //             paddingLeft,
  //             fontWeight: isObject ? "bold" : "normal",
  //           }}
  //         >
  //           <div className="d-flex align-items-center gap-2">
  //             {isNewRow && editable ? (
  //               <input
  //                 type="text"
  //                 id={`${parentKey}-${key}`}
  //                 // value={key}
  //                 onBlur={(e) =>
  //                   handleNewMetricNameChange(parentKey, key, e.target.value)
  //                 }
  //                 style={{ width: "100%" }}
  //               />
  //             ) : (
  //               key
  //             )}
  //             {isObject && editable && (
  //               <CustomButton
  //                 type="btn-primary"
  //                 iconRequired={true}
  //                 handleClick={() => handleAddNestedRow(fullKey)}
  //                 classes="button-height-varun"
  //               >
  //                 Add
  //               </CustomButton>
  //             )}
  //           </div>
  //         </td>
  //         {years.map((year) => (
  //           <td key={`${year}-${fullKey}`}>
  //             {isObject ? (
  //               ""
  //             ) : editable ? (
  //               <input
  //                 type="text"
  //                 value={getNestedValue(editedData[year], fullKey) || ""}
  //                 onChange={(e) =>
  //                   handleCellValueChange(year, fullKey, e.target.value)
  //                 }
  //               />
  //             ) : excurrency ? (
  //               getNestedValue(editedData[year], fullKey) ? (
  //                 formatValue(
  //                   getNestedValue(editedData[year], fullKey),
  //                   unit,
  //                   currency,
  //                 )
  //               ) : (
  //                 getNestedValue(editedData[year], fullKey)
  //               )
  //             ) : convertValue(
  //                 getNestedValue(editedData[year], fullKey)?.toString() || "-",
  //                 year.trimStart(),
  //                 currency,
  //                 unit,
  //               ) ? (
  //               formatValue(
  //                 convertValue(
  //                   getNestedValue(editedData[year], fullKey)?.toString() ||
  //                     "-",
  //                   year.trimStart(),
  //                   currency,
  //                   unit,
  //                 ),
  //                 unit,
  //                 currency,
  //               )
  //             ) : (
  //               convertValue(
  //                 getNestedValue(editedData[year], fullKey)?.toString() || "-",
  //                 year.trimStart(),
  //                 currency,
  //                 unit,
  //               )
  //             )}
  //           </td>
  //         ))}
  //         {editable && (
  //           <td style={{ border: "none" }}>
  //             <button
  //               onClick={() => handleDeleteRow(fullKey)}
  //               style={{
  //                 background: "none",
  //                 border: "none",
  //                 cursor: "pointer",
  //                 color: "red",
  //                 fontSize: "1.2em",
  //               }}
  //             >
  //               <Delete />
  //             </button>
  //           </td>
  //         )}
  //       </tr>
  //     );

  //     if (isObject) {
  //       return [currentRow, ...renderRows(value, fullKey, depth + 1)];
  //     }

  //     return currentRow;
  //   });

  //   // Add new metrics
  //   if (depth === 0 && editable) {
  //     newMetrics.forEach((metric, index) => {
  //       rows.push(
  //         <tr key={`new-metric-${index}`}>
  //           <td style={{ paddingLeft: "10px" }}>
  //             <input
  //               type="text"
  //               value={metric.name}
  //               onChange={(e) =>
  //                 handleNewMetricChange(index, "name", e.target.value)
  //               }
  //               placeholder="New Metric Name"
  //             />
  //           </td>
  //           {years.map((year) => (
  //             <td key={`new-metric-${index}-${year}`}>
  //               <input
  //                 type="text"
  //                 value={metric.values[year] || ""}
  //                 onChange={(e) =>
  //                   handleNewMetricChange(index, year, e.target.value)
  //                 }
  //                 placeholder="Value"
  //               />
  //             </td>
  //           ))}
  //           <td style={{ border: "none" }}>
  //             <button
  //               onClick={() => {
  //                 const updatedMetrics = newMetrics.filter(
  //                   (_, i) => i !== index,
  //                 );
  //                 setNewMetrics(updatedMetrics);
  //               }}
  //               style={{
  //                 background: "none",
  //                 border: "none",
  //                 cursor: "pointer",
  //                 color: "red",
  //                 fontSize: "1.2em",
  //               }}
  //             >
  //               <Delete />
  //             </button>
  //           </td>
  //         </tr>,
  //       );
  //     });
  //   }

  //   return rows;
  // };

  // const renderRows = (data, parentKey = "", depth = 0) => {
  //   const rows = Object.entries(data).flatMap(([key, value]) => {
  //     const fullKey = parentKey ? `${parentKey}.${key}` : key;
  //     const isObject = typeof value === "object" && value !== null;
  //     const isNewRow = key.startsWith("New") || key.startsWith("Metrics");
  //     const isExactCategory = key === "LIABILITIES" || key === "ASSETS"; // Exact match for LIABILITIES or ASSETS

  //     const paddingLeft = depth === 0 ? "10px" : `${depth * 40}px`;

  //     const currentRow = (
  //       <tr key={fullKey}>
  //         <td
  //           style={{
  //             paddingLeft,
  //             fontWeight: isObject ? "bold" : "normal",
  //           }}
  //         >
  //           <div className="d-flex align-items-center gap-2">
  //             {isNewRow && editable ? (
  //               <input
  //                 type="text"
  //                 id={`${parentKey}-${key}`}
  //                 onBlur={(e) =>
  //                   handleNewMetricNameChange(parentKey, key, e.target.value)
  //                 }
  //                 style={{ width: "100%" }}
  //                 className="table-input-field" // Add your class here
  //               />
  //             ) : (
  //               key
  //             )}
  //             {isObject && editable && (
  //               <CustomButton
  //                 type="btn-primary"
  //                 iconRequired={true}
  //                 handleClick={() => handleAddNestedRow(fullKey)}
  //                 classes="button-height-varun"
  //               >
  //                 Add
  //               </CustomButton>
  //             )}
  //           </div>
  //         </td>
  //         {years.map((year) => (
  //           <td key={`${year}-${fullKey}`}>
  //             {isExactCategory ? null : isObject ? ( // Do not render input for exact "LIABILITIES" or "ASSETS"
  //               ""
  //             ) : editable ? (
  //               <input
  //                 type="text"
  //                 value={getNestedValue(editedData[year], fullKey) || ""}
  //                 onChange={(e) =>
  //                   handleCellValueChange(year, fullKey, e.target.value)
  //                 }
  //                 className="table-input-field" // Add your class here
  //               />
  //             ) : excurrency ? (
  //               getNestedValue(editedData[year], fullKey) ? (
  //                 formatValue(
  //                   getNestedValue(editedData[year], fullKey),
  //                   unit,
  //                   currency,
  //                 )
  //               ) : (
  //                 getNestedValue(editedData[year], fullKey)
  //               )
  //             ) : convertValue(
  //                 getNestedValue(editedData[year], fullKey)?.toString() || "-",
  //                 year.trimStart(),
  //                 currency,
  //                 unit,
  //               ) ? (
  //               formatValue(
  //                 convertValue(
  //                   getNestedValue(editedData[year], fullKey)?.toString() ||
  //                     "-",
  //                   year.trimStart(),
  //                   currency,
  //                   unit,
  //                 ),
  //                 unit,
  //                 currency,
  //               )
  //             ) : (
  //               convertValue(
  //                 getNestedValue(editedData[year], fullKey)?.toString() || "-",
  //                 year.trimStart(),
  //                 currency,
  //                 unit,
  //               )
  //             )}
  //           </td>
  //         ))}
  //         {editable && (
  //           <td style={{ border: "none" }}>
  //             <button
  //               onClick={() => handleDeleteRow(fullKey)}
  //               style={{
  //                 background: "none",
  //                 border: "none",
  //                 cursor: "pointer",
  //                 color: "red",
  //                 fontSize: "1.2em",
  //               }}
  //             >
  //               <Delete />
  //             </button>
  //           </td>
  //         )}
  //       </tr>
  //     );

  //     if (isObject) {
  //       return [currentRow, ...renderRows(value, fullKey, depth + 1)];
  //     }

  //     return currentRow;
  //   });

  //   // Add new metrics
  //   if (depth === 0 && editable) {
  //     newMetrics.forEach((metric, index) => {
  //       rows.push(
  //         <tr key={`new-metric-${index}`}>
  //           <td style={{ paddingLeft: "10px" }}>
  //             <input
  //               type="text"
  //               value={metric.name}
  //               onChange={(e) =>
  //                 handleNewMetricChange(index, "name", e.target.value)
  //               }
  //               placeholder="New Metric Name"
  //               className="table-input-field" // Add your class here
  //             />
  //           </td>
  //           {years.map((year) => (
  //             <td key={`new-metric-${index}-${year}`}>
  //               <input
  //                 type="text"
  //                 value={metric.values[year] || ""}
  //                 onChange={(e) =>
  //                   handleNewMetricChange(index, year, e.target.value)
  //                 }
  //                 placeholder="Value"
  //                 className="table-input-field" // Add your class here
  //               />
  //             </td>
  //           ))}
  //           <td style={{ border: "none" }}>
  //             <button
  //               onClick={() => {
  //                 const updatedMetrics = newMetrics.filter(
  //                   (_, i) => i !== index,
  //                 );
  //                 setNewMetrics(updatedMetrics);
  //               }}
  //               style={{
  //                 background: "none",
  //                 border: "none",
  //                 cursor: "pointer",
  //                 color: "red",
  //                 fontSize: "1.2em",
  //               }}
  //             >
  //               <Delete />
  //             </button>
  //           </td>
  //         </tr>,
  //       );
  //     });
  //   }

  //   return rows;
  // };

  // const renderRows = (data, parentKey = "", depth = 0) => {
  //   const rows = Object.entries(data).flatMap(([key, value]) => {
  //     const fullKey = parentKey ? `${parentKey}.${key}` : key;
  //     const isObject = typeof value === "object" && value !== null;
  //     const isNestedRow = depth > 0; // Consider rows as nested if depth > 0

  //     const paddingLeft = depth === 0 ? "10px" : `${depth * 40}px`;

  //     const currentRow = (
  //       <tr key={fullKey}>
  //         <td
  //           style={{
  //             paddingLeft,
  //             fontWeight: isObject ? "bold" : "normal",
  //           }}
  //         >
  //           <div className="d-flex align-items-center gap-2">
  //             {key}
  //             {isObject && editable && (
  //               <CustomButton
  //                 type="btn-primary"
  //                 iconRequired={true}
  //                 handleClick={() => handleAddNestedRow(fullKey)}
  //                 classes="button-height-varun"
  //               >
  //                 Add
  //               </CustomButton>
  //             )}
  //           </div>
  //         </td>
  //         {years.map((year) => (
  //           <td key={`${year}-${fullKey}`}>
  //             {isObject ? (
  //               ""
  //             ) : editable ? (
  //               <input
  //                 type="text"
  //                 value={getNestedValue(editedData[year], fullKey) || ""}
  //                 onChange={(e) =>
  //                   handleCellValueChange(year, fullKey, e.target.value)
  //                 }
  //               />
  //             ) : excurrency ? (
  //               getNestedValue(editedData[year], fullKey) ? (
  //                 formatValue(
  //                   getNestedValue(editedData[year], fullKey),
  //                   unit,
  //                   currency,
  //                 )
  //               ) : (
  //                 getNestedValue(editedData[year], fullKey)
  //               )
  //             ) : convertValue(
  //                 getNestedValue(editedData[year], fullKey)?.toString() || "-",
  //                 year.trimStart(),
  //                 currency,
  //                 unit,
  //               ) ? (
  //               formatValue(
  //                 convertValue(
  //                   getNestedValue(editedData[year], fullKey)?.toString() ||
  //                     "-",
  //                   year.trimStart(),
  //                   currency,
  //                   unit,
  //                 ),
  //                 unit,
  //                 currency,
  //               )
  //             ) : (
  //               convertValue(
  //                 getNestedValue(editedData[year], fullKey)?.toString() || "-",
  //                 year.trimStart(),
  //                 currency,
  //                 unit,
  //               )
  //             )}
  //           </td>
  //         ))}
  //         {editable &&
  //           isNestedRow && ( // Show delete only for nested rows
  //             <td style={{ border: "none" }}>
  //               <button
  //                 onClick={() => handleDeleteRow(fullKey)}
  //                 style={{
  //                   background: "none",
  //                   border: "none",
  //                   cursor: "pointer",
  //                   color: "red",
  //                   fontSize: "1.2em",
  //                 }}
  //               >
  //                 <Delete />
  //               </button>
  //             </td>
  //           )}
  //       </tr>
  //     );

  //     if (isObject) {
  //       return [currentRow, ...renderRows(value, fullKey, depth + 1)];
  //     }

  //     return currentRow;
  //   });

  //   return rows;
  // };
  const [selectedOption, setSelectedOption] = useState(null);
  // const handleAddNestedRow = (parentKey, selectedKey) => {
  //   console.log("Adding nested row...");
  //   console.log("Parent Key:", parentKey);
  //   console.log("Selected Key:", selectedKey);
  //   setSelectedOption(selectedKey);

  //   if (!selectedKey) {
  //     console.warn("No key selected, aborting operation.");
  //     return; // Do nothing if no key is selected
  //   }

  //   setEditedData((prevData) => {
  //     console.log("Previous Data:", prevData);

  //     // Deep clone the current data
  //     const newData = JSON.parse(JSON.stringify(prevData));
  //     console.log("----newData---", newData);

  //     // Navigate to the correct parent object
  //     // console.log("",newData?.CURRENT ASSETS)
  //     const years = Object.keys(editedData || {});

  //     console.log("Amandeep", newData?.years?.["CURRENT ASSETS"]);

  //     // const parentObject = getNestedValue(newData, parentKey);
  //     const parentObject = newData?.[`${years[0]}`]?.[parentKey];
  //     console.log("Parent Object before modification:", parentObject);

  //     // Check if the parentObject exists and is an object
  //     if (typeof parentObject === "object" && parentObject !== null) {
  //       // Add the new key with an empty string as its value
  //       parentObject[selectedKey] = "";
  //       console.log(`Added key "${selectedKey}" with an empty value.`);
  //     } else {
  //       console.error("Parent Object is invalid or not found.");
  //     }

  //     console.log("Modified Data:", newData);
  //     return newData;
  //   });
  // };

  // const handleAddNestedRow = (parentKey, selectedKeys) => {
  //   console.log("Adding nested rows...");
  //   console.log("Parent Key:", parentKey);
  //   console.log("Selected Keys:", selectedKeys);

  //   if (!Array.isArray(selectedKeys) || selectedKeys.length === 0) {
  //     console.warn("No keys selected, aborting operation.");
  //     return; // Exit if no keys are selected
  //   }

  //   setSelectedOption(selectedKeys);

  //   setEditedData((prevData) => {
  //     console.log("Previous Data:", prevData);

  //     // Deep clone the current data to avoid mutating the original state
  //     const newData = structuredClone(prevData); // Modern alternative to JSON.parse(JSON.stringify)

  //     // Retrieve all years
  //     const years = Object.keys(newData || {});
  //     if (years.length === 0) {
  //       console.error("No years found in the data structure.");
  //       return prevData; // Exit if no years are available
  //     }

  //     console.log("Years:", years);

  //     // Access the parent object for the specified year and key
  //     const parentObject = newData?.[years[0]]?.[parentKey];
  //     console.log("Parent Object before modification:", parentObject);

  //     if (parentObject && typeof parentObject === "object") {
  //       // Iterate over selected keys and add them to the parent object
  //       selectedKeys.forEach((key) => {
  //         if (!parentObject.hasOwnProperty(key)) {
  //           parentObject[key] = ""; // Add the key with an empty string as its value
  //           console.log(`Added key "${key}" with an empty value.`);
  //         } else {
  //           console.warn(`Key "${key}" already exists.`);
  //         }
  //       });
  //     } else {
  //       console.error("Parent Object is invalid or not found.");
  //     }

  //     console.log("Modified Data:", newData);
  //     return newData; // Update the state with modified data
  //   });
  // };

  // const handleAddNestedRow = (parentKey, selectedKeys) => {
  //   console.log("Adding or Removing nested rows...");
  //   console.log("Parent Key:", parentKey);
  //   console.log("Selected Keys:", selectedKeys);

  //   if (!Array.isArray(selectedKeys)) {
  //     console.warn("Selected keys should be an array.");
  //     return; // Exit if selectedKeys is not an array
  //   }

  //   setSelectedOption(selectedKeys);

  //   setEditedData((prevData) => {
  //     console.log("Previous Data:", prevData);

  //     // Deep clone the current data to avoid mutating the original state
  //     const newData = structuredClone(prevData); // Modern alternative to JSON.parse(JSON.stringify)

  //     // Retrieve all years
  //     const years = Object.keys(newData || {});
  //     if (years.length === 0) {
  //       console.error("No years found in the data structure.");
  //       return prevData; // Exit if no years are available
  //     }

  //     console.log("Years:", years);

  //     // Access the parent object for the specified year and key
  //     const parentObject = newData?.[years[0]]?.[parentKey];
  //     console.log("Parent Object before modification:", parentObject);

  //     if (parentObject && typeof parentObject === "object") {
  //       // Iterate over selected keys and either add or remove them
  //       selectedKeys.forEach((key) => {
  //         // If the key is selected and doesn't exist, add it
  //         if (!parentObject.hasOwnProperty(key)) {
  //           parentObject[key] = ""; // Add the key with an empty string as its value
  //           console.log(`Added key "${key}" with an empty value.`);
  //         }
  //       });

  //       // Check for keys that were previously selected but are no longer selected
  //       Object.keys(parentObject).forEach((key) => {
  //         if (!selectedKeys.includes(key)) {
  //           delete parentObject[key]; // Remove the key if it's no longer selected
  //           console.log(`Removed key "${key}".`);
  //         }
  //       });
  //     } else {
  //       console.error("Parent Object is invalid or not found.");
  //     }

  //     console.log("Modified Data:", newData);
  //     return newData; // Update the state with modified data
  //   });
  // };

  // Function to extract subcategories from categories dynamically based on the first year index
  // const extractSubcategories = (data) => {
  //   // Get the first year dynamically (index 0)
  //   const yearData = data[Object.keys(data)[0]];
  //   const subcategories = {};

  //   // Loop through the keys of the year data
  //   Object.keys(yearData).forEach((key) => {
  //     // If the value is an empty object (subcategory), add it to subcategories
  //     if (typeof yearData[key] === "object") {
  //       subcategories[key] = []; // Initialize as empty array for empty objects
  //     }
  //   });

  //   return subcategories;
  // };

  // // Extracted subcategories

  // useEffect(() => {
  //   const subcategories = extractSubcategories(editedData);
  //   console.log("subcategories---->", subcategories);
  // }, [editedData]);

  // const [doopData, setDoopData] = useState({});

  // const [doopData, setDoopData] = useState({
  //   ...Object?.keys(editedData[Object?.keys(editedData)[0]]).reduce(
  //     (acc, category) => {
  //       if (
  //         editedData[Object?.keys(editedData)[0]][category] &&
  //         typeof editedData[Object?.keys(editedData)[0]][category] === "object"
  //       ) {
  //         acc[category] = Object?.keys(
  //           editedData[Object?.keys(editedData)[0]][category],
  //         );
  //       }
  //       return acc;
  //     },
  //     {},
  //   ),
  // });
  const [doopData, setDoopData] = useState(() => {
    if (!editedData || Object.keys(editedData).length === 0) return {}; // Check for empty or undefined data

    const firstKey = Object.keys(editedData)[0];
    const firstCategory = editedData[firstKey];

    if (!firstCategory || typeof firstCategory !== "object") return {}; // Check for valid object

    return Object.keys(firstCategory).reduce((acc, category) => {
      if (
        firstCategory[category] &&
        typeof firstCategory[category] === "object"
      ) {
        acc[category] = Object.keys(firstCategory[category]);
      }
      return acc;
    }, {});
  });

  console.log("doopDtatatata", doopData);

  const handleAddNestedRow = (parentKey, selectedKeys) => {
    console.log("Adding or Removing nested rows...");
    console.log("Parent Key:", parentKey);
    console.log("Selected Keys:", selectedKeys);

    setDoopData((prev) => ({
      ...prev, // spread the previous state
      [parentKey]: selectedKeys, // update the category with selected subcategories
    }));

    if (!Array.isArray(selectedKeys)) {
      console.warn("Selected keys should be an array.");
      return; // Exit if selectedKeys is not an array
    }

    setSelectedOption(selectedKeys); // Update selected options state for dropdown

    setEditedData((prevData) => {
      console.log("Previous Data:", prevData);

      // Deep clone the current data to avoid mutating the original state
      const newData = structuredClone(prevData); // Modern alternative to JSON.parse(JSON.stringify)

      // Retrieve all years
      const years = Object.keys(newData || {});
      if (years.length === 0) {
        console.error("No years found in the data structure.");
        return prevData; // Exit if no years are available
      }

      console.log("Years:", years);

      // Access the parent object for the specified year and key
      const parentObject = newData?.[years[0]]?.[parentKey];
      console.log("Parent Object before modification:", parentObject);

      if (parentObject && typeof parentObject === "object") {
        // Iterate over selected keys and either add or remove them
        selectedKeys.forEach((key) => {
          // If the key is selected and doesn't exist, add it
          if (!parentObject.hasOwnProperty(key)) {
            parentObject[key] = ""; // Add the key with an empty string as its value
            console.log(`Added key "${key}" with an empty value.`);
          }
        });

        // // Check for keys that were previously selected but are no longer selected
        // Object.keys(parentObject).forEach((key) => {
        //   if (!selectedKeys.includes(key)) {
        //     delete parentObject[key]; // Remove the key if it's no longer selected
        //     console.log(`Removed key "${key}".`);
        //   }
        // });
        setEditedData((prevData) => {
          console.log("Previous Data:", prevData);

          // Deep clone the current data to avoid mutating the original state
          const newData = structuredClone(prevData); // Safely clone the state

          // Retrieve all years
          const years = Object.keys(newData || {});
          if (years.length === 0) {
            console.error("No years found in the data structure.");
            return prevData; // Exit if no years are available
          }

          console.log("Years:", years);

          // Access the parent object for the specified year and key
          const parentObject = newData?.[years[0]]?.[parentKey];
          console.log("Parent Object before modification:", parentObject);

          if (parentObject && typeof parentObject === "object") {
            // Get predefined keys for comparison
            const predefinedKeys = Object.values(
              predefinedData[parentKey] || {},
            ).flat();

            // Add or retain keys that are selected
            selectedKeys.forEach((key) => {
              if (!parentObject.hasOwnProperty(key)) {
                parentObject[key] = ""; // Add the key with an empty value
                console.log(`Added key "${key}" with an empty value.`);
              }
            });

            // Handle removal of keys
            Object.keys(parentObject).forEach((key) => {
              if (
                (!selectedKeys.includes(key) && predefinedKeys.includes(key)) || // Key exists in predefined but not selected
                (!selectedKeys.includes(key) && !predefinedKeys.includes(key)) // Key is neither selected nor predefined
              ) {
                delete parentObject[key]; // Remove the key
                console.log(`Removed key "${key}".`);
              }
            });
          } else {
            console.error("Parent Object is invalid or not found.");
          }

          console.log("Modified Data:", newData);
          return newData; // Update the state with modified data
        });
      } else {
        console.error("Parent Object is invalid or not found.");
      }

      console.log("Modified Data:", newData);
      return newData; // Update the state with modified data
    });
  };
  // const handleAddNestedRow1 = (parentKey, selectedKeys) => {
  //   console.log("Adding nested rows...");
  //   console.log("Parent Key:", parentKey);
  //   console.log("Selected Keys:", selectedKeys);

  //   if (!Array.isArray(selectedKeys)) {
  //     console.warn("Selected keys should be an array.");
  //     return; // Exit if selectedKeys is not an array
  //   }

  //   return setEditedData((prevData) => {
  //     console.log("Previous Data:", prevData);

  //     // Deep clone the current data to avoid mutating the original state
  //     const newData = structuredClone(prevData);

  //     // Retrieve all years
  //     const years = Object.keys(newData || {});
  //     if (years.length === 0) {
  //       console.error("No years found in the data structure.");
  //       return prevData; // Exit if no years are available
  //     }

  //     console.log("Years:", years);

  //     // Access the parent object for the specified year and key
  //     const parentObject = newData?.[years[0]]?.[parentKey];
  //     console.log("Parent Object before modification:", parentObject);

  //     if (parentObject && typeof parentObject === "object") {
  //       // Iterate over selected keys and add them to the parent object
  //       selectedKeys.forEach((key) => {
  //         if (!parentObject.hasOwnProperty(key)) {
  //           parentObject[key] = ""; // Add the key with an empty value
  //           console.log(`Added key "${key}" with an empty value.`);
  //         }
  //       });
  //     } else {
  //       console.error("Parent Object is invalid or not found.");
  //     }

  //     console.log("Modified Data:", newData);
  //     return newData; // Return the updated data
  //   });
  // };

  const handleAddNestedRow1 = (parentKey, selectedKeys) => {
    console.log("Adding nested rows...");
    console.log("Parent Key:", parentKey);
    console.log("Selected Keys:", selectedKeys);

    if (!Array.isArray(selectedKeys)) {
      console.warn("Selected keys should be an array.");
      return; // Exit if selectedKeys is not an array
    }

    // Update editedData
    setEditedData((prevData) => {
      console.log("Previous Data:", prevData);

      const newData = structuredClone(prevData); // Deep clone the current data

      const years = Object.keys(newData || {});
      if (years.length === 0) {
        console.error("No years found in the data structure.");
        return prevData;
      }

      console.log("Years:", years);

      const parentObject = newData?.[years[0]]?.[parentKey];
      console.log("Parent Object before modification:", parentObject);

      if (parentObject && typeof parentObject === "object") {
        selectedKeys.forEach((key) => {
          if (!parentObject.hasOwnProperty(key)) {
            parentObject[key] = ""; // Add the key with an empty value
            console.log(`Added key "${key}" with an empty value.`);
          }
        });
      } else {
        console.error("Parent Object is invalid or not found.");
      }

      console.log("Modified Data:", newData);
      return newData;
    });

    // Update your provided structured data
    setDoopData((prevDoopData) => {
      console.log("Previous DoopData:", prevDoopData);

      const newDoopData = structuredClone(prevDoopData); // Deep clone to avoid mutation

      // Add keys to the respective parentKey array
      if (newDoopData[parentKey] && Array.isArray(newDoopData[parentKey])) {
        selectedKeys.forEach((key) => {
          if (!newDoopData[parentKey].includes(key)) {
            newDoopData[parentKey].push(key); // Push the key if it doesn't already exist
            console.log(`Added "${key}" to doopData[${parentKey}].`);
          }
        });
      } else {
        console.error(`Parent key "${parentKey}" not found or invalid.`);
      }

      console.log("Updated DoopData:", newDoopData);
      return newDoopData;
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customInput, setCustomInput] = useState("");
  const [modalFullKey, setModalFullKey] = useState(null); // State to store the fullKey for the modal

  const handleOptionChange = (option, fullKey) => {
    console.log("option", option);
    setSelectedOption(option);
    if (option === "Other") {
      setIsModalOpen(true); // Open the modal when "Other" is selected
      setModalFullKey(fullKey); // Save the fullKey to use in the modal
    } else {
      handleAddNestedRow(fullKey, option);
    }
  };
  console.log("customInpit", customInput);

  const handleModalSubmit = (fullKey) => {
    console.log("fullKey", fullKey);
    console.log("modalFullKey", modalFullKey);
    console.log("customInputttttttttt", customInput);
    setIsModalOpen(false);
    handleAddNestedRow1(modalFullKey, [customInput]);
    setCustomInput(""); // Reset the input
  };
  const boldKeys = [
    "ASSETS",
    "CURRENT ASSETS",
    "Total Current Assets",
    "NON-CURRENT ASSETS",
    "Total Non-Current Assets",
    "TOTAL ASSETS",
    "LIABILITIES",
    "CURRENT LIABILITIES",
    "Total Current Liabilities",
    "NON-CURRENT LIABILITIES",
    "Total Non-Current Liabilities",
    "TOTAL LIABILITIES",
    "SHAREHOLDERS EQUITY",
    "Total Shareholder's Equity",
    "TOTAL SHAREHOLDER'S EQUITY AND LIABILITIES",
    "Total Shareholder's Equity",
    "Sales/Revenue/Turnover",
    "Total Sales/Revenue/Turnover",
    "COGS/Cost of goods sold/Cost of Sales",
    "Total COGS",
    "Gross Profit",
    "Operating Expenses/Income",
    "Total Operating Expenses",
    "EBITDA",
    "Depreciation and Amortization",
    "EBIT",
    "Finance Expenses/Income",
    "Total Finance Expenses/Income",
    "Other Non-Operating Expenses",
    "Total Non-Operating Expenses",
    "EBT",
    "Tax Expenses",
    "Net Income/ Profit/ (Loss)",
  ];

  const sumCurrentAssets = (currentAssets) => {
    console.log("currentAssets--->", currentAssets);
    if (!currentAssets) return 0; // Return 0 if currentAssets is undefined or null
    return Object.values(currentAssets).reduce((sum, value) => {
      return sum + (parseFloat(value) || 0); // Ensures non-numeric values are treated as 0
    }, 0);
  };

  const calculateGrossProfit = (year, editedData) => {
    // Ensure the year exists in the editedData object
    if (!editedData[year]) {
      return 0; // Return 0 if the year doesn't exist
    }

    // Get the total for Sales/Revenue/Turnover
    const salesRevenue = sumCurrentAssets(
      editedData?.[year]?.["Sales/Revenue/Turnover"],
    );

    // Get the total for COGS/Cost of goods sold/Cost of Sales
    const cogs = sumCurrentAssets(
      editedData?.[year]?.["COGS/Cost of goods sold/Cost of Sales"],
    );

    // Calculate the Gross Profit by subtracting COGS from Sales/Revenue
    const grossProfit = salesRevenue - cogs;
    editedData[year]["Gross Profit"] = grossProfit;

    return grossProfit || 0; // Return the calculated Gross Profit, or 0 if undefined
  };

  const calculateEBITDA = (year, editedData) => {
    // Ensure the year exists in the editedData object
    if (!editedData[year]) {
      return 0; // Return 0 if the year doesn't exist
    }

    // Get the total for Sales/Revenue/Turnover
    const salesRevenue = sumCurrentAssets(
      editedData?.[year]?.["Sales/Revenue/Turnover"],
    );

    // Get the total for COGS/Cost of goods sold/Cost of Sales
    const cogs = sumCurrentAssets(
      editedData?.[year]?.["COGS/Cost of goods sold/Cost of Sales"],
    );

    // Get the total for Operating Expenses/Income
    const operatingExpenses = sumCurrentAssets(
      editedData?.[year]?.["Operating Expenses/Income"],
    );

    // Calculate EBITDA by subtracting COGS and Operating Expenses from Sales/Revenue
    const ebitda = salesRevenue - cogs - operatingExpenses;
    editedData[year]["EBITDA"] = ebitda;

    return ebitda || 0; // Return the calculated EBITDA, or 0 if undefined
  };

  const calculateEBIT = (year, editedData) => {
    // Get the calculated EBITDA
    const ebitda = calculateEBITDA(year, editedData);

    // Get the total for Depreciation and Amortization
    const depreciationAmortization = sumCurrentAssets(
      editedData?.[year]?.["Depreciation and Amortization"],
    );

    // Calculate EBIT by subtracting Depreciation and Amortization from EBITDA
    const ebit = ebitda - depreciationAmortization;
    editedData[year]["EBIT"] = ebit;

    return ebit || 0; // Return the calculated EBIT, or 0 if undefined
  };

  const calculateEBT = (year, editedData) => {
    // First, get the calculated EBIT
    const ebit = calculateEBIT(year, editedData);

    // Subtract Operating Expenses/Income and Other Non-Operating Expenses
    const operatingExpensesIncome = sumCurrentAssets(
      editedData?.[year]?.["Finance Expenses/Income"],
    );
    const otherNonOperatingExpenses = sumCurrentAssets(
      editedData?.[year]?.["Other Non-Operating Expenses"],
    );

    // Calculate EBT
    const ebt = ebit - operatingExpensesIncome - otherNonOperatingExpenses;
    editedData[year]["EBT"] = ebt;

    return ebt || 0; // Return the calculated EBT, or 0 if undefined
  };

  const calculateNetIncome = (year, editedData) => {
    // Calculate EBT using previously defined calculateEBT function
    const ebt = calculateEBT(year, editedData);

    // Subtract Tax Expenses from EBT to get Net Income/Profit/(Loss)
    const taxExpenses = sumCurrentAssets(editedData?.[year]?.["Tax Expenses"]);

    // Calculate Net Income/Profit/(Loss)
    const netIncome = ebt - taxExpenses;
    editedData[year]["Net Income/ Profit/ (Loss)"] = netIncome;

    return netIncome || 0; // Return Net Income/Profit/(Loss), or 0 if undefined
  };

  const sumCurrentAssets1 = (lineItemKeys, year, editedData, updateKey) => {
    // Ensure the year exists in the editedData object
    if (!editedData[year]) {
      editedData[year] = {}; // Create an empty object for the year if it doesn't exist
    }

    // Sum up all the values under the specified lineItemKeys
    const total = lineItemKeys.reduce((acc, key) => {
      const values = editedData[year]?.[key] || {}; // Get the values for the key (default to empty object)

      // Sum the values of the object for each key
      return (
        acc +
        Object.values(values).reduce(
          (sum, value) => sum + (parseFloat(value) || 0),
          0,
        )
      );
    }, 0);

    // Update the editedData object with the calculated total under the updateKey
    editedData[year][updateKey] = total;

    // Return the calculated total
    return total || 0;
  };

  const renderRows = (data, parentKey = "", depth = 0) => {
    const rows = Object.entries(data).flatMap(([key, value]) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      const isObject = typeof value === "object" && value !== null;
      const isNestedRow = depth > 0; // Consider rows as nested if depth > 0
      const isExactCategory =
        key === "LIABILITIES" ||
        key === "ASSETS" ||
        key === "Sales/Revenue/Turnover" ||
        key === "COGS/Cost of goods sold/Cost of Sales" ||
        key === "Operating Expenses/Income" ||
        key === "Depreciation and Amortization" ||
        key === "Finance Expenses/Income" ||
        key === "Other Non-Operating Expenses" ||
        key === "Tax Expenses" ||
        key === "CURRENT ASSETS" ||
        key === "NON-CURRENT ASSETS" ||
        key === "CURRENT LIABILITIES" ||
        key === "NON-CURRENT LIABILITIES" ||
        key === "SHAREHOLDERS EQUITY" ||
        key === "SHAREHOLDERS EQUITY";
      // Exact match for LIABILITIES or ASSETS
      const isExactCategoryBold = boldKeys.includes(key); // Check if the key matches any in the boldKeys array

      const paddingLeft = depth === 0 ? "10px" : `${depth * 40}px`;

      // const currentRow = (
      //   <tr key={fullKey}>
      //     <td
      //       style={{
      //         paddingLeft,
      //         fontWeight: isObject ? "bold" : "normal",
      //       }}
      //     >
      //       <div className="gap-2">
      //         <span
      //           style={{
      //             fontWeight:
      //               isExactCategoryBold || isObject ? "bold" : "normal", // Bold only the header values
      //           }}
      //         >
      //           {key === "Total Sales/Revenue/Turnover"
      //             ? `Total ${doopData["Sales/Revenue/Turnover"]?.join("/")}`
      //             : key}
      //         </span>
      //         {[
      //           "SHAREHOLDERS EQUITY",
      //           "NON-CURRENT LIABILITIES",
      //           "CURRENT LIABILITIES",
      //           "NON-CURRENT ASSETS",
      //           "CURRENT ASSETS",
      //           "Operating Expenses/Income",
      //           "Depreciation and Amortization",
      //           "Finance Expenses/Income",
      //           "Other Non-Operating Expenses",
      //           "Tax Expenses",
      //           "Sales/Revenue/Turnover",
      //           "COGS/Cost of goods sold/Cost of Sales",
      //         ].includes(key) &&
      //           editable && (
      //             <div style={{ width: "250px", marginTop: "10px" }}>
      //               <MultipleUserSelectComponent
      //                 title="Add Line Item"
      //                 data={(dropdownOptionsMapping[key] || []).map(
      //                   (option) => ({
      //                     name: option, // labelKey
      //                     emp_id: option, // returnValue
      //                   }),
      //                 )}
      //                 onSelect={(selectedItems) => {
      //                   handleOptionChange(selectedItems, fullKey);
      //                 }}
      //                 selectAllLabel="All"
      //                 labelKey="name" // Key for displaying labels
      //                 selectedEmployeeIds={doopData[key]}
      //                 type="edit"
      //                 setIsModalOpen={setIsModalOpen}
      //                 setModalFullKey={setModalFullKey}
      //                 modalFullKey={fullKey}
      //               />
      //             </div>
      //           )}
      //       </div>
      //     </td>
      //     {years.map((year) => {
      //       const isTotalCurrentAssets = key === "Total Current Assets";
      //       const currentAssets = editedData?.[year]?.["CURRENT ASSETS"];

      //       return (
      //         <td key={`${year}-${fullKey}`} style={{ width: "150px" }}>
      //           {isExactCategory ? null : isObject ? (
      //             ""
      //           ) : editable ? (
      //             // If it's not "Total Current Assets", show input field
      //             !isTotalCurrentAssets ? (
      //               <input
      //                 type="text"
      //                 value={getNestedValue(editedData[year], fullKey) || ""}
      //                 onChange={(e) =>
      //                   handleCellValueChange(year, fullKey, e.target.value)
      //                 }
      //                 onKeyDown={(e) => {
      //                   const allowedKeys = [
      //                     "Backspace",
      //                     "Tab",
      //                     "ArrowLeft",
      //                     "ArrowRight",
      //                     "Delete",
      //                     "Enter",
      //                     "-",
      //                     ".",
      //                     ",",
      //                   ];
      //                   if (
      //                     !allowedKeys.includes(e.key) && // Allow control keys
      //                     !/^\d$/.test(e.key) // Allow digits
      //                   ) {
      //                     e.preventDefault();
      //                   }
      //                 }}
      //                 className="table-input-field" // Add your class here
      //               />
      //             ) : (
      //               // If it's "Total Current Assets", show the sum
      //               <span>{sumCurrentAssets(currentAssets)?.toFixed(2)}</span>
      //             )
      //           ) : isTotalCurrentAssets ? (
      //             // If it's "Total Current Assets", show the sum
      //             <span>{sumCurrentAssets(currentAssets)?.toFixed(2)}</span>
      //           ) : excurrency ? (
      //             getNestedValue(editedData[year], fullKey) ? (
      //               formatValue(
      //                 getNestedValue(editedData[year], fullKey),
      //                 unit,
      //                 currency,
      //               )
      //             ) : (
      //               getNestedValue(editedData[year], fullKey)
      //             )
      //           ) : convertValue(
      //               getNestedValue(editedData[year], fullKey)?.toString() ||
      //                 "-",
      //               year.trimStart(),
      //               currency,
      //               unit,
      //             ) ? (
      //             formatValue(
      //               convertValue(
      //                 getNestedValue(editedData[year], fullKey)?.toString() ||
      //                   "-",
      //                 year.trimStart(),
      //                 currency,
      //                 unit,
      //               ),
      //               unit,
      //               currency,
      //             )
      //           ) : (
      //             convertValue(
      //               getNestedValue(editedData[year], fullKey)?.toString() ||
      //                 "-",
      //               year.trimStart(),
      //               currency,
      //               unit,
      //             )
      //           )}
      //         </td>
      //       );
      //     })}
      //     {editable && (
      //       <td style={{ width: "20px" }}>
      //         {isNestedRow && (
      //           <button
      //             onClick={() => handleDeleteRow(fullKey)}
      //             style={{
      //               background: "none",
      //               border: "none",
      //               cursor: "pointer",
      //               color: "red",
      //               fontSize: "1.2em",
      //             }}
      //           >
      //             <Delete />
      //           </button>
      //         )}
      //       </td>
      //     )}
      //   </tr>
      // );
      const currentRow = (
        <tr key={fullKey}>
          <td
            style={{
              paddingLeft,
              fontWeight: isObject ? "bold" : "normal",
            }}
          >
            <div className="gap-2">
              <span
                style={{
                  fontWeight:
                    isExactCategoryBold || isObject ? "bold" : "normal", // Bold only the header values
                }}
              >
                {/* {key === "Total Sales/Revenue/Turnover"
                  ? `Total ${doopData["Sales/Revenue/Turnover"]?.join("/")}`
                  : key} */}
                {key}
              </span>
              {[
                "SHAREHOLDERS EQUITY",
                "NON-CURRENT LIABILITIES",
                "CURRENT LIABILITIES",
                "NON-CURRENT ASSETS",
                "CURRENT ASSETS",
                "Operating Expenses/Income",
                "Depreciation and Amortization",
                "Finance Expenses/Income",
                "Other Non-Operating Expenses",
                "Tax Expenses",
                "Sales/Revenue/Turnover",
                "COGS/Cost of goods sold/Cost of Sales",
              ].includes(key) &&
                editable && (
                  <div style={{ width: "250px", marginTop: "10px" }}>
                    <MultipleUserSelectComponent
                      title="Add Line Item"
                      data={(dropdownOptionsMapping[key] || []).map(
                        (option) => ({
                          name: option, // labelKey
                          emp_id: option, // returnValue
                        }),
                      )}
                      onSelect={(selectedItems) => {
                        handleOptionChange(selectedItems, fullKey);
                      }}
                      selectAllLabel="All"
                      labelKey="name" // Key for displaying labels
                      selectedEmployeeIds={doopData[key]}
                      type="edit"
                      setIsModalOpen={setIsModalOpen}
                      setModalFullKey={setModalFullKey}
                      modalFullKey={fullKey}
                    />
                  </div>
                )}
            </div>
          </td>
          {years.map((year) => {
            return (
              <td key={`${year}-${fullKey}`} style={{ width: "150px" }}>
                {isExactCategory ? null : isObject ? (
                  ""
                ) : editable ? (
                  // If it's not "Total Current Assets", show input field
                  ![
                    "Total Current Assets",
                    "Total Non-Current Assets",
                    "TOTAL ASSETS",
                    "Total Current Liabilities",
                    "Total Non-Current Liabilities",
                    "TOTAL LIABILITIES",
                    "Total Shareholder's Equity",
                    "TOTAL SHAREHOLDER'S EQUITY AND LIABILITIES",
                    "Total Sales/Revenue/Turnover",
                    "Total COGS",
                    "Gross Profit",
                    "Total Operating Expenses",
                    "EBITDA",
                    "EBIT",
                    "Total Finance Expenses/Income",
                    "Total Non-Operating Expenses",
                    "EBT",
                    "Net Income/ Profit/ (Loss)",
                  ].includes(key) ? (
                    <input
                      type="text"
                      value={getNestedValue(editedData[year], fullKey) || ""}
                      onChange={(e) =>
                        handleCellValueChange(year, fullKey, e.target.value)
                      }
                      // onKeyDown={(e) => {
                      //   const allowedKeys = [
                      //     "Backspace",
                      //     "Tab",
                      //     "ArrowLeft",
                      //     "ArrowRight",
                      //     "Delete",
                      //     "Enter",
                      //     "-",
                      //     ".",
                      //     ",",
                      //   ];
                      //   if (
                      //     !allowedKeys.includes(e.key) && // Allow control keys
                      //     !/^\d$/.test(e.key) // Allow digits
                      //   ) {
                      //     e.preventDefault();
                      //   }
                      // }}
                      onKeyDown={(e) => {
                        const allowedKeys = [
                          "Backspace",
                          "Tab",
                          "ArrowLeft",
                          "ArrowRight",
                          "Delete",
                          "Enter",
                          "-",
                          ".",
                        ];

                        // Allow only digits and control keys
                        if (
                          !allowedKeys.includes(e.key) &&
                          !/^\d$/.test(e.key)
                        ) {
                          e.preventDefault();
                          return;
                        }

                        const inputValue = e.target.value;

                        // Allow only one negative sign at the beginning
                        if (e.key === "-" && inputValue.includes("-")) {
                          e.preventDefault();
                          return;
                        }

                        // Allow only one dot (.) in the input
                        if (e.key === "." && inputValue.includes(".")) {
                          e.preventDefault();
                          return;
                        }

                        // Prevent commas
                        if (e.key === ",") {
                          e.preventDefault();
                        }
                      }}
                      className="table-input-field" // Add your class here
                    />
                  ) : (
                    // If it's "Total Current Assets", show the sum
                    <span>
                      {key === "Total Current Assets"
                        ? // formatValue(
                          //     sumCurrentAssets(
                          //       editedData?.[year]?.["CURRENT ASSETS"],
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )

                          formatValue(
                            sumCurrentAssets1(
                              ["CURRENT ASSETS"],
                              year,
                              editedData,
                              "Total Current Assets",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "Total Non-Current Assets"
                        ? // formatValue(
                          //     sumCurrentAssets(
                          //       editedData?.[year]?.["NON-CURRENT ASSETS"],
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            sumCurrentAssets1(
                              ["NON-CURRENT ASSETS"],
                              year,
                              editedData,
                              "Total Non-Current Assets",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "TOTAL ASSETS"
                        ? // formatValue(
                          //     (
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["CURRENT ASSETS"],
                          //       ) +
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["NON-CURRENT ASSETS"],
                          //       )
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            sumCurrentAssets1(
                              ["CURRENT ASSETS", "NON-CURRENT ASSETS"],
                              year,
                              editedData,
                              "TOTAL ASSETS",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "Total Current Liabilities"
                        ? // formatValue(
                          //     sumCurrentAssets(
                          //       editedData?.[year]?.["CURRENT LIABILITIES"],
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            sumCurrentAssets1(
                              ["CURRENT LIABILITIES"],
                              year,
                              editedData,
                              "Total Current Liabilities",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "Total Non-Current Liabilities"
                        ? // formatValue(
                          //     sumCurrentAssets(
                          //       editedData?.[year]?.["NON-CURRENT LIABILITIES"],
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            sumCurrentAssets1(
                              ["NON-CURRENT LIABILITIES"],
                              year,
                              editedData,
                              "Total Non-Current Liabilities",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "TOTAL LIABILITIES"
                        ? // formatValue(
                          //     (
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["CURRENT LIABILITIES"],
                          //       ) +
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["NON-CURRENT LIABILITIES"],
                          //       )
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            sumCurrentAssets1(
                              [
                                "NON-CURRENT LIABILITIES",
                                "CURRENT LIABILITIES",
                              ],
                              year,
                              editedData,
                              "TOTAL LIABILITIES",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "Total Shareholder's Equity"
                        ? // formatValue(
                          //     sumCurrentAssets(
                          //       editedData?.[year]?.["SHAREHOLDERS EQUITY"],
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            sumCurrentAssets1(
                              ["SHAREHOLDERS EQUITY"],
                              year,
                              editedData,
                              "Total Shareholder's Equity",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "TOTAL SHAREHOLDER'S EQUITY AND LIABILITIES"
                        ? // formatValue(
                          //     (
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["SHAREHOLDERS EQUITY"],
                          //       ) +
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["CURRENT LIABILITIES"],
                          //       ) +
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["NON-CURRENT LIABILITIES"],
                          //       )
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            sumCurrentAssets1(
                              [
                                "SHAREHOLDERS EQUITY",
                                "CURRENT LIABILITIES",
                                "NON-CURRENT LIABILITIES",
                              ],
                              year,
                              editedData,
                              "TOTAL SHAREHOLDER'S EQUITY AND LIABILITIES",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === `Total Sales/Revenue/Turnover`
                        ? // formatValue(
                          //     sumCurrentAssets(
                          //       editedData?.[year]?.["Sales/Revenue/Turnover"],
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            sumCurrentAssets1(
                              ["Sales/Revenue/Turnover"],
                              year,
                              editedData,
                              "Total Sales/Revenue/Turnover",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "Total COGS"
                        ? // formatValue(
                          //     sumCurrentAssets(
                          //       editedData?.[year]?.[
                          //         "COGS/Cost of goods sold/Cost of Sales"
                          //       ],
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            sumCurrentAssets1(
                              ["COGS/Cost of goods sold/Cost of Sales"],
                              year,
                              editedData,
                              "Total COGS",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "Gross Profit"
                        ? // formatValue(
                          //     (
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["Sales/Revenue/Turnover"],
                          //       ) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.[
                          //           "COGS/Cost of goods sold/Cost of Sales"
                          //         ],
                          //       )
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            calculateGrossProfit(year, editedData)?.toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "Total Operating Expenses"
                        ? // formatValue(
                          //     sumCurrentAssets(
                          //       editedData?.[year]?.["Operating Expenses/Income"],
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            sumCurrentAssets1(
                              ["Operating Expenses/Income"],
                              year,
                              editedData,
                              "Total Operating Expenses",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "EBITDA"
                        ? // formatValue(
                          //     (
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["Sales/Revenue/Turnover"],
                          //       ) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.[
                          //           "COGS/Cost of goods sold/Cost of Sales"
                          //         ],
                          //       ) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.[
                          //           "Operating Expenses/Income"
                          //         ],
                          //       )
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            calculateEBITDA(year, editedData)?.toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "EBIT"
                        ? // formatValue(
                          //     (
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["Sales/Revenue/Turnover"],
                          //       ) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.[
                          //           "COGS/Cost of goods sold/Cost of Sales"
                          //         ],
                          //       ) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.[
                          //           "Operating Expenses/Income"
                          //         ],
                          //       ) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.[
                          //           "Depreciation and Amortization"
                          //         ],
                          //       )
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            calculateEBIT(year, editedData)?.toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "Total Finance Expenses/Income"
                        ? // formatValue(
                          //     sumCurrentAssets(
                          //       editedData?.[year]?.["Finance Expenses/Income"],
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            sumCurrentAssets1(
                              ["Finance Expenses/Income"],
                              year,
                              editedData,
                              "Total Finance Expenses/Income",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "Total Non-Operating Expenses"
                        ? // formatValue(
                          //     sumCurrentAssets(
                          //       editedData?.[year]?.[
                          //         "Other Non-Operating Expenses"
                          //       ],
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            sumCurrentAssets1(
                              ["Other Non-Operating Expenses"],
                              year,
                              editedData,
                              "Total Non-Operating Expenses",
                            ).toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "EBT"
                        ? // formatValue(
                          //     (
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["Sales/Revenue/Turnover"],
                          //       ) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.[
                          //           "COGS/Cost of goods sold/Cost of Sales"
                          //         ],
                          //       ) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.[
                          //           "Operating Expenses/Income"
                          //         ],
                          //       ) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.[
                          //           "Depreciation and Amortization"
                          //         ],
                          //       ) -
                          //       (sumCurrentAssets(
                          //         editedData?.[year]?.["Finance Expenses/Income"],
                          //       ) +
                          //         sumCurrentAssets(
                          //           editedData?.[year]?.[
                          //             "Other Non-Operating Expenses"
                          //           ],
                          //         ))
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            calculateEBT(year, editedData)?.toFixed(2),
                            unit,
                            currency,
                          )
                        : key === "Net Income/ Profit/ (Loss)"
                        ? // formatValue(
                          //     (
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["Sales/Revenue/Turnover"],
                          //       ) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.[
                          //           "COGS/Cost of goods sold/Cost of Sales"
                          //         ],
                          //       ) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.[
                          //           "Operating Expenses/Income"
                          //         ],
                          //       ) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.[
                          //           "Depreciation and Amortization"
                          //         ],
                          //       ) -
                          //       (sumCurrentAssets(
                          //         editedData?.[year]?.["Finance Expenses/Income"],
                          //       ) +
                          //         sumCurrentAssets(
                          //           editedData?.[year]?.[
                          //             "Other Non-Operating Expenses"
                          //           ],
                          //         )) -
                          //       sumCurrentAssets(
                          //         editedData?.[year]?.["Tax Expenses"],
                          //       )
                          //     )?.toFixed(2),
                          //     unit,
                          //     currency,
                          //   )
                          formatValue(
                            calculateNetIncome(year, editedData)?.toFixed(2),
                            unit,
                            currency,
                          )
                        : ""}
                    </span>
                  )
                ) : [
                    "Total Current Assets",
                    "Total Non-Current Assets",
                    "TOTAL ASSETS",
                    "Total Current Liabilities",
                    "Total Non-Current Liabilities",
                    "TOTAL LIABILITIES",
                    "Total Shareholder's Equity",
                    "TOTAL SHAREHOLDER'S EQUITY AND LIABILITIES",
                    "Total Sales/Revenue/Turnover",
                    "Total COGS",
                    "Gross Profit",
                    "Total Operating Expenses",
                    "EBITDA",
                    "EBIT",
                    "Total Finance Expenses/Income",
                    "Total Non-Operating Expenses",
                    "EBT",
                    "Net Income/ Profit/ (Loss)",
                  ].includes(key) ? (
                  // If it's "Total Current Assets", show the sum
                  <span>
                    {key === "Total Current Assets"
                      ? formatValue(
                          sumCurrentAssets(
                            editedData?.[year]?.["CURRENT ASSETS"],
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "Total Non-Current Assets"
                      ? formatValue(
                          sumCurrentAssets(
                            editedData?.[year]?.["NON-CURRENT ASSETS"],
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "TOTAL ASSETS"
                      ? formatValue(
                          (
                            sumCurrentAssets(
                              editedData?.[year]?.["CURRENT ASSETS"],
                            ) +
                            sumCurrentAssets(
                              editedData?.[year]?.["NON-CURRENT ASSETS"],
                            )
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "Total Current Liabilities"
                      ? formatValue(
                          sumCurrentAssets(
                            editedData?.[year]?.["CURRENT LIABILITIES"],
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "Total Non-Current Liabilities"
                      ? formatValue(
                          sumCurrentAssets(
                            editedData?.[year]?.["NON-CURRENT LIABILITIES"],
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "TOTAL LIABILITIES"
                      ? formatValue(
                          (
                            sumCurrentAssets(
                              editedData?.[year]?.["CURRENT LIABILITIES"],
                            ) +
                            sumCurrentAssets(
                              editedData?.[year]?.["NON-CURRENT LIABILITIES"],
                            )
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "Total Shareholder's Equity"
                      ? formatValue(
                          sumCurrentAssets(
                            editedData?.[year]?.["SHAREHOLDERS EQUITY"],
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "TOTAL SHAREHOLDER'S EQUITY AND LIABILITIES"
                      ? formatValue(
                          (
                            sumCurrentAssets(
                              editedData?.[year]?.["SHAREHOLDERS EQUITY"],
                            ) +
                            sumCurrentAssets(
                              editedData?.[year]?.["CURRENT LIABILITIES"],
                            ) +
                            sumCurrentAssets(
                              editedData?.[year]?.["NON-CURRENT LIABILITIES"],
                            )
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === `Total Sales/Revenue/Turnover`
                      ? formatValue(
                          sumCurrentAssets(
                            editedData?.[year]?.["Sales/Revenue/Turnover"],
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "Total COGS"
                      ? formatValue(
                          sumCurrentAssets(
                            editedData?.[year]?.[
                              "COGS/Cost of goods sold/Cost of Sales"
                            ],
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "Gross Profit"
                      ? formatValue(
                          (
                            sumCurrentAssets(
                              editedData?.[year]?.["Sales/Revenue/Turnover"],
                            ) -
                            sumCurrentAssets(
                              editedData?.[year]?.[
                                "COGS/Cost of goods sold/Cost of Sales"
                              ],
                            )
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "Total Operating Expenses"
                      ? formatValue(
                          sumCurrentAssets(
                            editedData?.[year]?.["Operating Expenses/Income"],
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "EBITDA"
                      ? formatValue(
                          (
                            sumCurrentAssets(
                              editedData?.[year]?.["Sales/Revenue/Turnover"],
                            ) -
                            sumCurrentAssets(
                              editedData?.[year]?.[
                                "COGS/Cost of goods sold/Cost of Sales"
                              ],
                            ) -
                            sumCurrentAssets(
                              editedData?.[year]?.["Operating Expenses/Income"],
                            )
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "EBIT"
                      ? formatValue(
                          (
                            sumCurrentAssets(
                              editedData?.[year]?.["Sales/Revenue/Turnover"],
                            ) -
                            sumCurrentAssets(
                              editedData?.[year]?.[
                                "COGS/Cost of goods sold/Cost of Sales"
                              ],
                            ) -
                            sumCurrentAssets(
                              editedData?.[year]?.["Operating Expenses/Income"],
                            ) -
                            sumCurrentAssets(
                              editedData?.[year]?.[
                                "Depreciation and Amortization"
                              ],
                            )
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "Total Finance Expenses/Income"
                      ? formatValue(
                          sumCurrentAssets(
                            editedData?.[year]?.["Finance Expenses/Income"],
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "Total Non-Operating Expenses"
                      ? formatValue(
                          sumCurrentAssets(
                            editedData?.[year]?.[
                              "Other Non-Operating Expenses"
                            ],
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "EBT"
                      ? formatValue(
                          (
                            sumCurrentAssets(
                              editedData?.[year]?.["Sales/Revenue/Turnover"],
                            ) -
                            sumCurrentAssets(
                              editedData?.[year]?.[
                                "COGS/Cost of goods sold/Cost of Sales"
                              ],
                            ) -
                            sumCurrentAssets(
                              editedData?.[year]?.["Operating Expenses/Income"],
                            ) -
                            sumCurrentAssets(
                              editedData?.[year]?.[
                                "Depreciation and Amortization"
                              ],
                            ) -
                            (sumCurrentAssets(
                              editedData?.[year]?.["Finance Expenses/Income"],
                            ) +
                              sumCurrentAssets(
                                editedData?.[year]?.[
                                  "Other Non-Operating Expenses"
                                ],
                              ))
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : key === "Net Income/ Profit/ (Loss)"
                      ? formatValue(
                          (
                            sumCurrentAssets(
                              editedData?.[year]?.["Sales/Revenue/Turnover"],
                            ) -
                            sumCurrentAssets(
                              editedData?.[year]?.[
                                "COGS/Cost of goods sold/Cost of Sales"
                              ],
                            ) -
                            sumCurrentAssets(
                              editedData?.[year]?.["Operating Expenses/Income"],
                            ) -
                            sumCurrentAssets(
                              editedData?.[year]?.[
                                "Depreciation and Amortization"
                              ],
                            ) -
                            (sumCurrentAssets(
                              editedData?.[year]?.["Finance Expenses/Income"],
                            ) +
                              sumCurrentAssets(
                                editedData?.[year]?.[
                                  "Other Non-Operating Expenses"
                                ],
                              )) -
                            sumCurrentAssets(
                              editedData?.[year]?.["Tax Expenses"],
                            )
                          )?.toFixed(2),
                          unit,
                          currency,
                        )
                      : ""}
                  </span>
                ) : excurrency ? (
                  getNestedValue(editedData[year], fullKey) ? (
                    formatValue(
                      getNestedValue(editedData[year], fullKey),
                      unit,
                      currency,
                    )
                  ) : (
                    getNestedValue(editedData[year], fullKey)
                  )
                ) : convertValue(
                    getNestedValue(editedData[year], fullKey)?.toString() ||
                      "-",
                    year.trimStart(),
                    currency,
                    unit,
                  ) ? (
                  formatValue(
                    convertValue(
                      getNestedValue(editedData[year], fullKey)?.toString() ||
                        "-",
                      year.trimStart(),
                      currency,
                      unit,
                    ),
                    unit,
                    currency,
                  )
                ) : (
                  convertValue(
                    getNestedValue(editedData[year], fullKey)?.toString() ||
                      "-",
                    year.trimStart(),
                    currency,
                    unit,
                  )
                )}
              </td>
            );
          })}
          {editable && (
            <td style={{ width: "20px" }}>
              {isNestedRow && (
                <button
                  onClick={() => handleDeleteRow(fullKey)}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "red",
                    fontSize: "1.2em",
                  }}
                >
                  <Delete />
                </button>
              )}
            </td>
          )}
        </tr>
      );

      if (isObject) {
        return [currentRow, ...renderRows(value, fullKey, depth + 1)];
      }

      return currentRow;
    });

    return rows;
  };

  // const renderRows = (data, parentKey = "", depth = 0) => {
  //   const rows = Object.entries(data).flatMap(([key, value]) => {
  //     const fullKey = parentKey ? `${parentKey}.${key}` : key;
  //     const isObject = typeof value === "object" && value !== null;
  //     const isNestedRow = depth > 0;
  //     const isExactCategory =
  //       key === "LIABILITIES" ||
  //       key === "ASSETS" ||
  //       key === "Sales/Revenue/Turnover" ||
  //       key === "COGS/Cost of goods sold/Cost of Sales" ||
  //       key === "Operating Expenses/Income" ||
  //       key === "Depreciation and Amortization" ||
  //       key === "Finance Expenses/Income" ||
  //       key === "Other Non-Operating Expenses" ||
  //       key === "Tax Expenses" ||
  //       key === "CURRENT ASSETS" ||
  //       key === "NON-CURRENT ASSETS" ||
  //       key === "CURRENT LIABILITIES" ||
  //       key === "NON-CURRENT LIABILITIES" ||
  //       key === "SHAREHOLDERS EQUITY";

  //     const paddingLeft = depth === 0 ? "10px" : `${depth * 40}px`;

  //     // Check if it's the Total Current Assets row
  //     const isTotalCurrentAssets = key === "Total Current Assets";

  //     const currentRow = (
  //       <tr key={fullKey}>
  //         <td
  //           style={{
  //             paddingLeft,
  //             fontWeight: isObject ? "bold" : "normal",
  //           }}
  //         >
  //           <div className="gap-2">
  //             <span
  //               style={{
  //                 fontWeight: isExactCategory ? "bold" : "normal",
  //               }}
  //             >
  //               {key === "Total Sales/Revenue/Turnover"
  //                 ? `Total ${doopData["Sales/Revenue/Turnover"]?.join("/")}`
  //                 : key}
  //             </span>
  //           </div>
  //         </td>
  //         {years.map((year) => (
  //           <td key={`${year}-${fullKey}`} style={{ width: "150px" }}>
  //             {isExactCategory ? null : isObject ? (
  //               ""
  //             ) : isTotalCurrentAssets ? (
  //               // Dynamically calculate the sum for Total Current Assets
  //               sumCurrentAssets(
  //                 editedData?.[year]?.["CURRENT ASSETS"],
  //               )?.toFixed(2)
  //             ) : editable ? (
  //               <input
  //                 type="text"
  //                 value={getNestedValue(editedData[year], fullKey) || ""}
  //                 onChange={(e) =>
  //                   handleCellValueChange(year, fullKey, e.target.value)
  //                 }
  //                 onKeyDown={(e) => {
  //                   const allowedKeys = [
  //                     "Backspace",
  //                     "Tab",
  //                     "ArrowLeft",
  //                     "ArrowRight",
  //                     "Delete",
  //                     "Enter",
  //                     "-",
  //                     ".",
  //                     ",",
  //                   ];
  //                   if (!allowedKeys.includes(e.key) && !/^\d$/.test(e.key)) {
  //                     e.preventDefault();
  //                   }
  //                 }}
  //                 className="table-input-field"
  //               />
  //             ) : excurrency ? (
  //               getNestedValue(editedData[year], fullKey) ? (
  //                 formatValue(
  //                   getNestedValue(editedData[year], fullKey),
  //                   unit,
  //                   currency,
  //                 )
  //               ) : (
  //                 getNestedValue(editedData[year], fullKey)
  //               )
  //             ) : convertValue(
  //                 getNestedValue(editedData[year], fullKey)?.toString() || "-",
  //                 year.trimStart(),
  //                 currency,
  //                 unit,
  //               ) ? (
  //               formatValue(
  //                 convertValue(
  //                   getNestedValue(editedData[year], fullKey)?.toString() ||
  //                     "-",
  //                   year.trimStart(),
  //                   currency,
  //                   unit,
  //                 ),
  //                 unit,
  //                 currency,
  //               )
  //             ) : (
  //               convertValue(
  //                 getNestedValue(editedData[year], fullKey)?.toString() || "-",
  //                 year.trimStart(),
  //                 currency,
  //                 unit,
  //               )
  //             )}
  //           </td>
  //         ))}
  //         {editable && (
  //           <td style={{ width: "20px" }}>
  //             {isNestedRow && (
  //               <button
  //                 onClick={() => handleDeleteRow(fullKey)}
  //                 style={{
  //                   background: "none",
  //                   border: "none",
  //                   cursor: "pointer",
  //                   color: "red",
  //                   fontSize: "1.2em",
  //                 }}
  //               >
  //                 <Delete />
  //               </button>
  //             )}
  //           </td>
  //         )}
  //       </tr>
  //     );

  //     if (isObject) {
  //       return [currentRow, ...renderRows(value, fullKey, depth + 1)];
  //     }

  //     return currentRow;
  //   });

  //   return rows;
  // };

  const containerStyle = {
    margin: edit ? "" : "0",
  };

  const logConvertedData1 = () => {
    if (!financialData?.data) return;

    const convertedData = {};
    const years = Object.keys(financialData.data);

    years.forEach((year) => {
      const yearData = financialData.data[year];
      convertedData[year] = {};

      const processObject = (obj, target) => {
        for (let key in obj) {
          const value = obj[key];
          if (typeof value === "object" && value !== null) {
            target[key] = {};
            processObject(value, target[key]);
          } else {
            target[key] = convertValue(value, year.trimStart(), currency, unit);
          }
        }
      };

      processObject(yearData, convertedData[year]);
    });

    if (onDataConverted1) {
      // Store converted data based on sheet type
      onDataConverted1({ sheetType, data: convertedData, currency, unit });
    }
  };
  const logConvertedData2 = () => {
    if (!financialData?.data) return;

    const convertedData = {};
    const years = Object.keys(financialData.data);

    years.forEach((year) => {
      const yearData = financialData.data[year];
      convertedData[year] = {};

      const processObject = (obj, target) => {
        for (let key in obj) {
          const value = obj[key];
          if (typeof value === "object" && value !== null) {
            target[key] = {};
            processObject(value, target[key]);
          } else {
            target[key] = convertValue(value, year.trimStart(), currency, unit);
          }
        }
      };

      processObject(yearData, convertedData[year]);
    });

    if (onDataConverted2) {
      // Store converted data based on sheet type
      onDataConverted2({ sheetType, data: convertedData, currency, unit });
    }
  };

  useEffect(() => {
    logConvertedData1();
    logConvertedData2();
  }, [currency, unit, currencyNew, unitsNew, currencyNew, excurrency, exunit]);

  const handleAddMetric = () => {
    setIsAddMetricModalOpen(true);
  };

  const handleAddMetricSubmit = () => {
    setEditedData((prevData) => {
      const newData = JSON.parse(JSON.stringify(prevData)); // Deep clone

      const createNestedStructure = (depth) => {
        if (depth === 0) return "";
        const nestedStructure = {};
        nestedStructure[`New Nested Metric ${depth}`] = createNestedStructure(
          depth - 1,
        );
        return nestedStructure;
      };

      years.forEach((year) => {
        if (newMetricType === "1") {
          // Normal metric
          let index = 1;
          while (newData[year][`New Metric ${index}`] !== undefined) {
            index++;
          }
          const newMetricKey = `New Metric ${index}`;
          newData[year][newMetricKey] = "";
        } else {
          // Nested metric
          const timestamp = Date.now(); // Generate a unique timestamp
          if (!newData[year].Metrics) {
            newData[year].Metrics = {};
          }
          newData[year].Metrics[`New Nested Structure ${timestamp}`] =
            createNestedStructure(nestedLevels - 1);
        }
      });

      return newData;
    });

    setIsAddMetricModalOpen(false);
    setNewMetricType("1");
    setNestedLevels(1);
  };

  const handleNewMetricChange = (index, field, value) => {
    const updatedMetrics = [...newMetrics];
    if (field === "name") {
      updatedMetrics[index].name = value;
    } else {
      updatedMetrics[index].values[field] = value;
    }
    setNewMetrics(updatedMetrics);
  };

  return (
    <>
      {modalState.isDeleteModalOpen && (
        <DeleteModal
          id={modalState.modalId}
          name={`data of ${sheetType} of year ${modalState.name}`}
          // name={`data of ${sheetType} of years ${
          //   JSON.stringify(year) || JSON.stringify(extractedYears)
          // }`}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Financial Data"
          onClick={() => handleDeleteConfirm(modalState.name)} // Directly pass the confirm handler without arguments
        />
      )}
      <div className="table-container" style={containerStyle}>
        {edit && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                fontSize: "28px",
                fontWeight: "bold",
                marginBottom: "30px",
                alignItems: "center",
              }}
            >
              {sheetType === "balance_sheet"
                ? "Balance Sheet Table"
                : sheetType === "pl"
                ? "Profit and Loss Table"
                : "Table"}
            </div>

            {!editable && (
              <div style={{ display: "flex", gap: "20px" }}>
                {/* <CustomButton
                  type="btn-primary"
                  disabled={isLoadingDel}
                  iconRequired={false}
                  handleClick={() => handleDelete()}
                >
                  Delete
                  {isLoadingDel && (
                    <div
                      className="spinner-border spinner-border-sm text-light ms-3"
                      role="status"
                    ></div>
                  )}
                </CustomButton> */}
                <CustomButton
                  type="btn-primary"
                  iconRequired={false}
                  handleClick={() => toggleEdit()}
                >
                  Edit
                </CustomButton>
              </div>
            )}
            {editable && (
              <div style={{ display: "flex", gap: "20px" }}>
                <CustomButton
                  type="btn-primary"
                  iconRequired={true}
                  handleClick={() => setIsAddYearModalOpen(true)}
                >
                  {/* Add Year */}
                  {`Add ${
                    periodType === "yearly"
                      ? "Year"
                      : periodType === "halfYearly"
                      ? "Half Year"
                      : periodType === "quarterly"
                      ? "Quarter"
                      : "Month"
                  }`}
                </CustomButton>
              </div>
            )}
          </div>
        )}

        {preview && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="d-flex mb-3 align-items-center">
              <div style={{ width: "200px", marginRight: "10px" }}>
                <SelectDropdown
                  selectedValue={currency}
                  onChange={handleCurrencyChange}
                  data={currencies}
                  placeholder="Select Currency"
                />
              </div>

              {currency && unit && (
                <>
                  <div>in</div>
                  <div style={{ width: "200px", marginLeft: "10px" }}>
                    <SelectDropdown
                      selectedValue={unit}
                      onChange={handleUnitChange}
                      data={units}
                      placeholder="Select Unit"
                    />
                  </div>
                </>
              )}
            </div>
            <div>
              {originalCurrency !== currency && (
                <div>
                  <div style={{ fontWeight: "bold" }}>Conversion Factor</div>
                  {myConversionRate
                    ? `(1 ${originalCurrency} = ${myConversionRate} ${currency})`
                    : ""}
                </div>
              )}
            </div>
          </div>
        )}

        {/* {!preview && (
          <div>
            {originalCurrency !== currency && (
              <div>
                <div style={{ fontWeight: "bold" }}>Conversion Factor</div>
                {myConversionRate
                  ? `(1 ${originalCurrency} = ${myConversionRate} ${currency})`
                  : ""}
              </div>
            )}
          </div>
        )} */}

        {years.length > 0 ? (
          <>
            <div style={{ overflowX: "scroll" }}>
              <table>
                <thead style={{ background: "#f2f2f2" }}>
                  <tr>
                    <th>
                      {sheetType === "pl"
                        ? "Profit and Loss"
                        : sheetType === "balance_sheet"
                        ? "Balance Sheet"
                        : sheetType}{" "}
                      {extracted === true
                        ? `(${excurrency} in ${exunit})`
                        : view === true
                        ? `(${currencyNew} in ${unitsNew})`
                        : `(${currency} in ${unit})`}
                    </th>

                    {years.map((year) => (
                      <th key={year}>
                        {/* {editable ? (
                          <div style={{ display: "flex" }}>
                            {console.log("Year :", year)}
                            <input
                              type="text"
                              value={year}
                              onChange={(e) =>
                                handleYearChange(year, e.target.value)
                              }
                              style={{ width: "100%", boxSizing: "border-box" }}
                              className="table-input-field" // Add your class here
                            />

                            <div onClick={() => handleDelete(year)}>
                              <Delete />
                            </div>
                          </div>
                        ) : (
                          year
                        )} */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {year}
                          {editable && (
                            <div onClick={() => handleDelete(year)}>
                              <Delete />
                            </div>
                          )}
                        </div>
                      </th>
                    ))}
                    {editable && <th style={{ width: "30px" }}>Actions</th>}
                  </tr>
                </thead>
                <tbody>{renderRows(groupedKeys)}</tbody>
                {/* <tbody>{renderRows(editedData?.[years?.[0]] || {})}</tbody> */}
              </table>
            </div>
            {isAddMetricModalOpen && <AddMetricModal />}
            {isAddNestedRowModalOpen && <AddNestedRowModal />}

            {/* {editable && (
              <div style={{ marginTop: "10px", display: "flex", gap: "25px" }}>
                <CustomButton
                  type="btn-primary"
                  iconRequired={true}
                  handleClick={handleAddMetric}
                >
                  Add New Metric
                </CustomButton>
              </div>
            )} */}
            {editable && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <CustomButton
                  type="btn-primary"
                  iconRequired={false}
                  handleClick={() => onCancel()}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  type="btn-primary"
                  iconRequired={false}
                  handleClick={() => handleSave()}
                >
                  Save
                </CustomButton>

                {isDeleteModalOpen && (
                  <DeleteModal
                    isActive={isDeleteModalOpen}
                    onClose={() => setIsDeleteModalOpen(false)}
                    title="Financial Data Row"
                    name={`${rowToDelete}`}
                    onClick={confirmDeleteRow}
                    type="Delete"
                  />
                )}

                {/* <div style={{ display: "flex", gap: "20px" }}>
                  <CustomButton
                    type="btn-primary"
                    iconRequired={true}
                    handleClick={() => setIsAddYearModalOpen(true)}
                  >
                    Add Year
                  </CustomButton>
                </div> */}
              </div>
            )}
            {isAddYearModalOpen && (
              <AddYearModal
                isOpen={isAddYearModalOpen}
                onClose={() => setIsAddYearModalOpen(false)}
                onSubmit={handleAddYear}
              />
            )}
          </>
        ) : (
          <p>No data available</p>
        )}
        {/* Modal for custom input */}
        {isModalOpen && (
          <Modal
            title="Add Subcategory"
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            contentLabel="Custom Input Modal"
            style={{
              overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
              content: { margin: "auto", width: "300px", height: "200px" },
            }}
          >
            <input
              type="text"
              value={customInput}
              onChange={(e) => setCustomInput(e.target.value)}
              placeholder="Enter Subcategory"
              style={{ width: "100%", marginBottom: "10px", padding: "5px" }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleModalSubmit();
                }
              }}
            />
            {/* <button onClick={handleModalSubmit} style={{ marginRight: "10px" }}>
              Submit
            </button> */}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                type="btn-primary"
                iconRequired={false}
                handleClick={handleModalSubmit}
              >
                Save
              </CustomButton>
            </div>
            {/* <button onClick={() => setIsModalOpen(false)}>Cancel</button> */}
          </Modal>
        )}
      </div>
    </>
  );
};

export default FinancialDataTable;
