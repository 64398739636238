import { Formik } from "formik";
import PageCard from "../../../atoms/PageCard";
import PageHeader from "../../../atoms/PageHeader";
import SelectDropdown from "../../../molecules/Dropdown";
import CustomButton from "../../../atoms/CustomButton";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import { getData, postData, postFormData } from "../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import CustomDropzone from "../../../molecules/uploadFile";
import FinancialDataTable from "../../../organisms/viewDataTable/index";
import BackBtn from "../../../atoms/BackBtn";
import TableBuilder from "../../../organisms/createTable";

const AddFinancialData = () => {
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [rows, setRows] = useState([0]);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  // const validationSchema = Yup.object().shape({
  //   companyname: Yup.string().required("Name is Required"),
  // });
  const validationSchema = Yup.object().shape({
    company: Yup.string().required("Company is required"),
    sheetType: Yup.string().required("Sheet Type is required"),
    periodType: Yup.string().required("Period Type is required"),
    auditStatus: Yup.string().required("Audit Status is required"),
    // currencyType: Yup.string().required("Currency is required"),
    unitType: Yup.string().required("Unit is required"),
    // file: Yup.mixed().required("File is required"),
  });

  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [sheetType, setSheetType] = useState(null);
  const [auditStatus, setAuditStatus] = useState(null);
  const [periodType, setPeriodType] = useState(null);
  const [unit, setUnit] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [subcategories, setSubcategories] = useState();
  const [data, setData] = useState({});
  const [years, setYears] = useState([]);

  const plCategories = [
    "Sales/Revenue/Turnover",
    "Total Sales/Revenue/Turnover",
    "COGS/Cost of goods sold/Cost of Sales",
    "Total COGS",
    "Gross Profit",
    "Operating Expenses/Income",
    "Total Operating Expenses",
    "EBITDA",
    "Depreciation and Amortization",
    "EBIT",
    "Finance Expenses/Income",
    "Total Finance Expenses/Income",
    "Other Non-Operating Expenses",
    "Total Non-Operating Expenses",
    "EBT",
    "Tax Expenses",
    "Net Income/ Profit/ (Loss)",
  ];
  const BSCategories = [
    "ASSETS",
    "CURRENT ASSETS",
    "Total Current Assets",
    "NON-CURRENT ASSETS",
    "Total Non-Current Assets",
    "TOTAL ASSETS",
    "LIABILITIES",
    "CURRENT LIABILITIES",
    "Total Current Liabilities",
    "NON-CURRENT LIABILITIES",
    "Total Non-Current Liabilities",
    "TOTAL LIABILITIES",
    "SHAREHOLDERS EQUITY",
    "Total Shareholder's Equity",
    "TOTAL SHAREHOLDER'S EQUITY AND LIABILITIES",
  ];

  const [categories, setCategories] = useState([]);

  const PLsubcategories = {
    "Sales/Revenue/Turnover": ["Sales", "Revenue", "Turnover", "Other"],
    "COGS/Cost of goods sold/Cost of Sales": ["Other"],
    "Operating Expenses/Income": [
      "Accountancy",
      "Accountancy Fees",
      "Administration Wages",
      "Administrative Expenses",
      "Advertising",
      "Advertising and Marketing",
      "Advertising and Trade Marketing",
      "Advertising and Entertaining",
      "Advertising and Promotion",
      "Amazon Advertisement",
      "Amazon Fees",
      "Amortisation",
      "Audit and Accountancy fees",
      "Audit Fees",
      "Automobile Expense",
      "Bad and doubtful debts",
      "Bank and ATM Fee Expense",
      "Bank Fees",
      "Bank Interest and Charges",
      "Bank loan interest",
      "Bank Revaluations",
      "Bank Service Charges",
      "Bonuses",
      "Books",
      "Building repairs",
      "Building Works",
      "Bureau Processing Charges",
      "Business Licenses and Permits",
      "Business Meals Expense",
      "Canteen",
      "Car hire",
      "Charitable and Political Donations",
      "Charitable Contributions",
      "Cleaning",
      "Cleaning and Laundry",
      "Cleaning and Waste",
      "Client Entertainment",
      "Clothing Costs",
      "Commercial Insurances",
      "Commissions",
      "Company Cars (DD)",
      "Compensation of Officer",
      "Computer and Internet Expenses",
      "Computer Costs",
      "Computer Equipment Expense",
      "Computer Software",
      "Consultancy Fees",
      "Contract worker salary",
      "Corporation Tax",
      "Council Chambers",
      "Credit Charges",
      "Credit Insurance",
      "Customs Charges",
      "Directors' pension costs - defined contribution scheme",
      "Directors' Remuneration",
      "Directors Salaries",
      "Directors' social security costs",
      "Discount Allowed and Doubtful Debts",
      "Donations",
      "Dues and Membership Expense",
      "Dues and Subscriptions",
      "eBay Fees",
      "Employee Training Cost",
      "Employers National Insurance",
      "Entertainment",
      "Environmental health and safety",
      "Equipment Expense",
      "Equipment Hire Costs",
      "Equipment Leasing",
      "Equipment repairs",
      "Evergreen and Charges",
      "Facebook advertisement",
      "Facility and Utilities Expense",
      "Fees",
      "Foreign agents' Fee",
      "Foreign Currency Gains and Losses",
      "Foreign Services",
      "Freight",
      "Furniture and Fixtures Expense",
      "Gas and Auto Expense",
      "General Expenses",
      "General Rates",
      "Gifts and Samples",
      "Gifts Expense",
      "Google Advertising and Marketing",
      "Gross Salaries",
      "Gross Wages",
      "Health and safety",
      "Health insurance",
      "Hitachi Charges",
      "Holiday pay adjustment",
      "Hotels",
      "Independent Contractor Expense",
      "Insurance",
      "Insurance Expense - Auto",
      "Insurance Expense - Business",
      "Insurances (not premises)",
      "Internet charges",
      "IT expenses",
      "IT Software and Consumables",
      "IT Support",
      "Job Advertisement",
      "Klarna Fees",
      "Laundry",
      "Lease and Printing Costs",
      "Leasing - motor vehicles",
      "Leasing Costs",
      "Legal and Professional Fees",
      "Legal Expenses",
      "License and Fee Expense",
      "Licenses and Permits",
      "Licensing",
      "Light, Power, water, Heating",
      "Marketing and Advertising Expense",
      "Meals and Entertainment",
      "Medical",
      "Medical Insurance",
      "Merchant Fees Expense",
      "Mileage claims",
      "Motor and Travel",
      "Motor expenses and associated exp (SUNDRY)",
      "Motor Vehicle Expenses",
      "Nondeductible Expense",
      "Nondeductible Fines and Penalties",
      "Office Cleaning",
      "Office Equipment Depreciation",
      "Office Expense",
      "Office Machine Maintenance",
      "Office Stationery",
      "Office Supplies",
      "Office Supply Expense",
      "Operating Lease Payments",
      "Outside Services",
      "Outsourced work",
      "Packaging supplies",
      "Parking and Tolls Expense",
      "Parking Fee",
      "Payroll Expense - Administration",
      "Payroll Expense - Payroll Tax",
      "Payroll Expense - Salary and Wage",
      "Payroll Expenses",
      "Payroll Tax Expenses",
      "Pension Contributions",
      "Pensions Costs",
      "Personnel Expenses",
      "Phone and Internet Expense",
      "Plant Maintenance and Service Contracts",
      "Postage and Delivery",
      "Postage and Shipping Expense",
      "Postage and Carriage",
      "Postage, courier and delivery charges",
      "Postage, Freight and Courier",
      "PPS and telephone",
      "Premises Expenses",
      "Printing and Stationery",
      "Printing and Reproduction",
      "Printing, Postage and Stationery",
      "Productive labour Rent and Rates Electricity Management charges",
      "Professional Fees",
      "Professional Service Expense",
      "Property repairs and maintenance",
      "Rates",
      "Rates and Water (DD)",
      "Recruiting and HR Expense",
      "Rent and Rates",
      "Rent Expense",
      "Rent or Lease Expense",
      "Rent receivable",
      "Repairs and Maintenance",
      "Repairs and Renewals",
      "Research and Development",
      "Research and Development Expense",
      "Revenue Grant Income",
      "Salaries",
      "Salary and Wages",
      "Security and CCTV",
      "Security Costs",
      "Shipping",
      "Social Committee Expenses",
      "Social security costs",
      "Software and Web Hosting Expense",
      "Staff Canteen",
      "Staff pension costs defined contribution",
      "Staff Salaries",
      "Staff Training",
      "Stationery (CONSUMABLES)",
      "Storage Costs",
      "Stripe Fees",
      "Subscriptions and licences",
      "Subsistence",
      "Sundry Expenses",
      "Taxes",
      "Taxes Paid",
      "Telecommunications",
      "Telephone and Internet",
      "Telephone and Fax",
      "Telephone Expense",
      "Temporary staff",
      "Trade Finance Costs",
      "Tradeshow Expense",
      "Training and Education Expense",
      "Training Costs",
      "Transport and Carriage",
      "Travel and Transportation Expense",
      "Travel Expenses",
      "Twitter Advertisement",
      "Utilities",
      "Vehicle Fuel",
      "Wages and salaries",
      "Warranty labour",
      "Waste Collection",
      "Water Rates",
      "Website fees",
      "Write-back of over provision",
      "Other",
    ],
    "Depreciation and Amortization": ["Depreciation", "Amortization", "Other"],
    "Finance Expenses/Income": [
      "Bad Debt Provision",
      "Bad Debt Write Off",
      "Bank Charges",
      "CBIL Interest credit",
      "Currency Charges",
      "Donations",
      "evergreen Credit Charges",
      "Exchange Rate Variance",
      "Factoring Charges",
      "H.P. Interest",
      "Hire purchase interest payable",
      "Insurance claim",
      "Interest Paid",
      "Invoice finance interest payable",
      "Lease & HP Charges",
      "Loan Interest",
      "Loan Interest Paid",
      "Mispostings Account",
      "P&L on sale of assets",
      "Other interest",
      "Other",
    ],
    "Other Non-Operating Expenses": [
      "Losses/(Gains) on Foreign Exchange",
      "Profit or loss on foreign exchange",
      "Profit or loss on sale of tangible assets (non exceptional)",
      "Realised Currency Gains",
      "Sale of fixed assets",
      "Unrealised Currency Gains",
      "Other",
    ],
    "Tax Expenses": ["Current Tax", "Deferred Tax", "Other"],
  };
  const BSsubcategories = {
    "CURRENT ASSETS": [
      "Accounts Receivable",
      "Advances Suppliers",
      "Associated company accounts",
      "Cash at Bank & in Hand",
      "Corporation Tax",
      "Debtors Control Account",
      "Deferred income",
      "Directors current account",
      "Goods-in Billed Early",
      "Instant Access",
      "Inter-company account",
      "Inventory",
      "Inventory in Transit",
      "Other receivables",
      "Petty Cash",
      "Prepayments",
      "Prepayments & Other Debtors",
      "Stock - Finished Goods",
      "Stock - Raw Material",
      "Stock - WIP",
      "Trade Debtors",
      "Trade Debtors less provision",
      "Marketable Securities",
      "Short-term Investments",
      "Other",
    ],
    "NON-CURRENT ASSETS": [
      "Block plant",
      "Buildings",
      "BUSINESS CAPITAL - (PLANT)",
      "Computer equipment",
      "Fixtures and fittings",
      "Hess Project Costs",
      "Goodwill",
      "Patents",
      "Other Intangible Assets",
      "Leasehold Improvements",
      "Mass Plant Improvements",
      "Mezz Build",
      "Nlotor Vehicles",
      "Office Equipment",
      "Plant & Machinery",
      "Property",
      "Tipping Cells",
      "Long-Term Investments",
      "Other",
    ],
    "CURRENT LIABILITIES": [
      "Accounts Payable",
      "Accruals",
      "Associated company accounts",
      "Cash at Bank & in Hand",
      "Close liquidity loan",
      "Corporation Tax payable",
      "Credit card",
      "Creditors Control Account",
      "Directors' Loan Account",
      "Factoring account",
      "Finance Agreement",
      "Funding circle loan",
      "Hire Purchase due 1 year",
      "Intercompany Accounts",
      "Loan Account",
      "Loan due < 1 year",
      "Loans Due 1 year",
      "Loans due < 1 year",
      "Other Creditors",
      "Other payroll deductions",
      "PAYE Payable",
      "Payroll Taxes",
      "Pensions Payable",
      "Provision for Corporation Tax",
      "Refinance",
      "Social Security Costs",
      "Student Loan Deductions Payable",
      "Suspense account",
      "Trade Capital",
      "Trade Creditors",
      "VAT",
      "Wages Payable - Payroll",
      "Other",
    ],
    "NON-CURRENT LIABILITIES": [
      "Assets Loan (HP)",
      "Child Maintenance Payable",
      "Deferred Tax",
      "Director's Loan",
      "Funding circle - 1 - 5 years",
      "Government Grants",
      "Hire Purchase due > 1 year",
      "Hire Purchase Loan",
      "Loan accounts > 1 year",
      "Loan due > 1 year",
      "Loan Interest",
      "Long Term Bank Loans",
      "Long Term HP & Leasing",
      "Other creditor",
      "Refinance - 1 - 5 years",
      "Sundry provision",
      "Total Creditors: amounts falling due after more than one year",
      "Trade Creditors",
      "Unit refit loan > 1 year",
      "Other",
    ],
    "SHAREHOLDERS EQUITY": [
      "Called-up share capital",
      "Capital & Reserves",
      "Share Capital",
      "Capital redemption reserve",
      "Capital Stock",
      "Current Year Earnings",
      "Dividends declared",
      "Legal Reserve",
      "Loss for period",
      "P & L Account",
      "P&L brought forward",
      "Paid-up Share Capital",
      "Reserves",
      "Retained Earnings",
      "Revaluation Reserve",
      "Share premium",
      "Shareholder's Current Account",
      "Total Capital and Reserves",
      "Other",
    ],
  };

  const getCompanyList = useCallback(async () => {
    try {
      // setLoading(true);
      const res = await getData({
        endpoint: "company/getCompanyList",
        token: cookies.t,
      });

      if (res) {
        setFormData(res?.data);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [id]);

  useEffect(() => {
    getCompanyList();
  }, [getCompanyList]);

  const companyNameData = formData?.data?.map((company) => ({
    value: company._id,
    label: company.name,
  }));

  const initialValues = {
    company: "",
    sheetType: "",
    periodType: "",
    auditStatus: "",
    currencyType: "",
    unitType: "",
    // file: null,
    // pdftype: "",
  };
  const getCurrencyByCompanyId = useCallback(async (companyId) => {
    try {
      const res = await getData({
        endpoint: "company/getCurrencyByCompanyId",
        params: {
          companyId: companyId,
        },
        token: cookies.t,
      });

      if (res) {
        setCurrency(res?.currency);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, []);

  const [myCompanyId, setMyCompanyId] = useState("");
  const [availableData, setAvailableData] = useState("");

  // useEffect(() => {
  //   (async () => {
  //     const res = await getData({
  //       endpoint: "company/getCompanyListByCompanyId",
  //       token: cookies.t,
  //       params: { companyId: myComoanyId },
  //     });
  //     setAvailableData(res?.data);
  //   })();
  // }, []);
  // if (myCompanyId !== "") {
  //   const res = getData({
  //     endpoint: "company/getCompanyListByCompanyId",
  //     token: cookies.t,
  //     params: { companyId: myCompanyId },
  //   });
  //   setAvailableData(res?.data);
  // }
  useEffect(() => {
    if (myCompanyId !== "") {
      // Assuming getData is an async function
      const fetchData = async () => {
        try {
          const res = await getData({
            endpoint: "company/getCompanyById",
            token: cookies.t,
            params: { companyId: myCompanyId },
          });

          // Check if res and res.data exist
          if (res && res?.data) {
            setAvailableData(res?.data?.data);
          }
        } catch (error) {
          console.error("Error fetching company data:", error);
          // Handle error appropriately (e.g., show a toast or set an error state)
        }
      };

      fetchData(); // Call the fetchData function to execute the API request
    }
  }, [myCompanyId, sheetType, periodType, companyId]);

  console.log("availableData", availableData);
  console.log("myCompanyId", myCompanyId);

  // const handleSubmit = async (values) => {
  //   setIsLoading(true); // Set loading to true when API call starts

  //   const payload = {
  //     sheetType: values.sheetType,
  //     periodType: values.periodType,
  //     auditStatus: values.auditStatus,
  //     units: values.unitType,
  //     currency: values.currencyType,
  //     // file: fileData,
  //     // isSearchable: values.pdftype ? values.pdftype : null,
  //   };

  //   // setCurrency(values.currencyType);
  //   setUnit(values.unitType);

  //   try {
  //     const res = await postFormData({
  //       endpoint: "extract",
  //       params: {
  //         companyId: values.company,
  //       },
  //       token: cookies.t,
  //       data: payload,
  //     });

  //     if (res) {
  //       setTableData(res?.data?.data?.data);
  //       setCompanyId(res?.data?.data?.companyId); // Set companyId from response
  //       setSheetType(res?.data?.data?.sheetType); // Set dataId from response
  //       setPeriodType(res?.data?.data?.periodType); // Set periodType from response
  //       setAuditStatus(res?.data?.data?.auditStatus); // Set auditStatus from response
  //       setShowTable(true);
  //       const currency = await getCurrencyByCompanyId(values.company);
  //       if (currency) {
  //         setCurrency(currency); // Set the currency state from the response
  //       }
  //     }

  //     if (res?.data?.status) {
  //       toast.success("Financial Data Extracted Successfully", {
  //         style: confimationStyles,
  //         duration: 1000,
  //       });
  //     } else {
  //       toast.error(res?.data?.message || "Failed to add financial data", {
  //         style: errorStyles,
  //         duration: 1000,
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error adding financial data:", error);
  //     // toast.error("An error occurred while adding financial data", {
  //     //   style: errorStyles,
  //     //   duration: 1000,
  //     // });
  //     toast.error(error?.error?.response?.data?.error, {
  //       duration: 1000,
  //       style: errorStyles,
  //     });
  //   } finally {
  //     setIsLoading(false); // Set loading to false when API call completes
  //   }
  // };

  useEffect(() => {
    if (sheetType) {
      console.log("sheetTypeAman", sheetType);
      setData({});
      setSubcategories({});
      setYears([]);
      if (sheetType === "pl") {
        setCategories(plCategories);
        setSubcategories(PLsubcategories);
      } else {
        setCategories(BSCategories);
        setSubcategories(BSsubcategories);
      }
    }
  }, [sheetType, periodType]);

  const [currentTime, setCurrentTime] = useState("");

  const handleSubmit = async (values) => {
    setIsLoading(true); // Set loading to true when the process starts
    setCurrentTime(new Date().toISOString());

    // Update the relevant state with values from the form
    setUnit(values.unitType);
    // setCurrency(values.currencyType);
    setTableData([]); // Clear table data if needed
    setCompanyId(values.company); // Set companyId from form values
    setSheetType(values.sheetType); // Set sheetType from form values
    setPeriodType(values.periodType); // Set periodType from form values
    setAuditStatus(values.auditStatus); // Set auditStatus from form values
    setShowTable(true); // Show the table

    const currency = await getCurrencyByCompanyId(values.company);
    if (currency) {
      setCurrency(currency); // Set the currency state from the response
    }

    // Optionally log the updated values for debugging
    console.log("Form submitted with values:", values);
    console.log("PLsubcategories", PLsubcategories);

    setIsLoading(false); //pl Set loading to false immediately since there's no API call
  };

  const handleCancel = async (
    companyId,
    auditStatus,
    periodType,
    year,
    sheetType,
  ) => {
    try {
      const res = await getData({
        endpoint: "company/getFilteredFinancialData",
        params: {
          companyId: companyId,
          sheetType: sheetType,
          periodType: periodType,
          auditStatus: auditStatus,
          yearsArray: year,
        },
        token: cookies.t,
      });

      if (res) {
        setTableData(res?.data);
        setAuditStatus(res?.data?.auditStatus);
        setPeriodType(res?.data?.periodType);
        setSheetType(res?.data?.sheetType);
        setShowTable(true);
      }

      // if (res.status) {
      //   toast.success(res.message, {
      //     style: confimationStyles,
      //     duration: 1000,
      //   });
      // } else {
      //   toast.error(res.message, { style: errorStyles, duration: 1000 });
      // }
    } catch (error) {
      console.error("Error canceling changes:", error);
    }
  };

  return (
    <>
      <div style={{ width: "20px", marginBottom: "10px" }}>
        <BackBtn />
      </div>
      <PageHeader title="Add Financial Data" />
      <PageCard>
        <Formik
          key={Math.random}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({
            isValid,
            dirty,
            isSubmitting,
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="sheetType">Company</label>
                    <SelectDropdown
                      data={companyNameData}
                      placeholder="Select Company"
                      onChange={(option) => {
                        setMyCompanyId(option);
                        setFieldValue("company", option);
                      }}
                      isClearable={true}
                      selectedValue={values.company}
                      onClear={() => setFieldValue("company", " ")}
                      onBlur={() => setFieldTouched("company", true)}
                    />
                    {touched.company && errors.company && (
                      <div className="error-message">{errors.company}</div>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="sheetType">Audit Status</label>
                    <SelectDropdown
                      data={[
                        {
                          value: "audited",
                          label: "Audited",
                        },
                        { value: "unaudited", label: "Unaudited" },
                      ]}
                      placeholder="Select Audit Status"
                      onChange={(option) =>
                        setFieldValue("auditStatus", option)
                      }
                      isClearable={true}
                      selectedValue={values.auditStatus}
                      onClear={() => setFieldValue("auditStatus", " ")}
                    />
                    {touched.auditStatus && errors.auditStatus && (
                      <div className="error-message">{errors.auditStatus}</div>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="periodType">Period Type</label>
                    <SelectDropdown
                      data={[
                        { value: "yearly", label: "Yearly" },
                        { value: "halfYearly", label: "Half Yearly" },
                        { value: "quarterly", label: "Quarterly" },
                        { value: "monthly", label: "Monthly" },
                      ]}
                      placeholder="Select Period Type"
                      onChange={(option) => setFieldValue("periodType", option)}
                      isClearable={true}
                      selectedValue={values.periodType}
                      onClear={() => setFieldValue("periodType", " ")}
                    />
                    {touched.periodType && errors.periodType && (
                      <div className="error-message">{errors.periodType}</div>
                    )}
                  </div>
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="sheetType">Sheet Type</label>
                    <SelectDropdown
                      data={[
                        {
                          value: "balance_sheet",
                          label: "Balance Sheet",
                        },
                        { value: "pl", label: "P&L" },
                        // { value: "other", label: "Other" },
                      ]}
                      placeholder="Select Sheet Type"
                      onChange={(option) => setFieldValue("sheetType", option)}
                      isClearable={true}
                      selectedValue={values.sheetType}
                      onClear={() => setFieldValue("sheetType", " ")}
                    />
                    {touched.sheetType && errors.sheetType && (
                      <div className="error-message">{errors.sheetType}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="currencyType">Currency</label>
                    <SelectDropdown
                      data={[
                        { value: "INR", label: "INR" },
                        { value: "USD", label: "USD" },
                        { value: "EUR", label: "EUR" },
                        { value: "GBP", label: "GBP" },
                        { value: "AED", label: "AED" },
                        // { value: "GBP", label: "GBP" },
                        // { value: "JPY", label: "JPY" },
                        // { value: "CNY", label: "CNY" },
                        // { value: "AUD", label: "AUD" },
                        // { value: "CAD", label: "CAD" },
                      ]}
                      placeholder="Select Currency"
                      onChange={(option) =>
                        setFieldValue("currencyType", option)
                      }
                      isClearable={true}
                      selectedValue={values.currencyType}
                      onClear={() => setFieldValue("currencyType", " ")}
                    />
                    {touched.currencyType && errors.currencyType && (
                      <div className="error-message">{errors.currencyType}</div>
                    )}
                  </div> */}
                  <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="unitType">Units</label>
                    <SelectDropdown
                      data={[
                        { value: "Actual", label: "Actual" },
                        { value: "Hundreds", label: "Hundreds" },
                        { value: "Thousands", label: "Thousands" },
                        { value: "Lakhs", label: "Lakhs" },
                        { value: "Crore", label: "Crore" },
                        { value: "Million", label: "Million" },
                        { value: "Billion", label: "Billion" },
                      ]}
                      placeholder="Select Unit"
                      onChange={(option) => setFieldValue("unitType", option)}
                      isClearable={true}
                      selectedValue={values.unitType}
                      onClear={() => setFieldValue("unitType", " ")}
                    />
                    {touched.unitType && errors.unitType && (
                      <div className="error-message">{errors.unitType}</div>
                    )}
                  </div>
                  {/* <div className="col-lg-3 col-md-6 mt-3">
                    <label htmlFor="pdftype">PDF Type (In case of PDF)</label>
                    <SelectDropdown
                      data={[
                        {
                          value: "true",
                          label: "Searchable PDF",
                        },
                        {
                          value: "false",
                          label: "Non - Searchable PDF",
                        },
                      ]}
                      placeholder="Select PDF Type"
                      onChange={(option) => setFieldValue("pdftype", option)}
                      isClearable={true}
                      selectedValue={values.pdftype}
                      onClear={() => setFieldValue("pdftype", " ")}
                      onBlur={() => setFieldTouched("pdftype", true)}
                    />
                    {touched.pdftype && errors.pdftype && (
                      <div className="error-message">{errors.pdftype}</div>
                    )}
                  </div> */}
                </div>

                {/* <div className="row mt-4">
                  <div className="col-lg-12 col-md-12">
                    <CustomDropzone
                      // onFileSelect={(file) => setFileData(file)}
                      onFileSelect={(file) => {
                        setFileData(file); // Optional: If you still need to manage fileData separately
                        setFieldValue("file", file); // Set Formik's 'file' field
                      }}
                      description="Supported File type : Pdf and Image"
                    />
                    {touched.file && errors.file && (
                      <div className="error-message">{errors.file}</div>
                    )}
                  </div>
                </div> */}
                <div className="row mt-4">
                  <div className="col-lg-12 col-md-12">
                    <CustomButton
                      type="btn-primary"
                      disabled={isLoading || !isValid || !dirty}
                      iconRequired={false}
                      handleClick={() => handleSubmit(values)}
                    >
                      Submit
                      {isLoading && (
                        <div
                          className="spinner-border spinner-border-sm text-light ms-3"
                          role="status"
                        ></div>
                      )}
                    </CustomButton>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </PageCard>

      {/* {showTable && (
        <PageCard classes="mt-3">
          <FinancialDataTable
            financialData={tableData}
            handleCancel={handleCancel}
            companyId={companyId}
            sheetType={sheetType}
            periodType={periodType}
            auditStatus={auditStatus}
            excurrency={currency}
            exunit={unit}
            extracted={true}
          />
        </PageCard>
      )} */}
      {showTable && (
        <PageCard classes="mt-3">
          <TableBuilder
            companyId={companyId}
            sheetType={sheetType}
            periodType={periodType}
            auditStatus={auditStatus}
            excurrency={currency}
            exunit={unit}
            categories={categories}
            setCategories={setCategories}
            subcategories={subcategories}
            data={data}
            setData={setData}
            years={years}
            setYears={setYears}
            availableData={availableData}
            currentTime={currentTime}
          />
        </PageCard>
      )}
    </>
  );
};
export default AddFinancialData;
