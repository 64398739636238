// import { Box, IconButton } from "@mui/material";
// import PropTypes from "prop-types";
// import { useEffect, useRef, useState } from "react";
// import "./styles.scss";

// const MultipleUserSelectComponent = ({
//   title,
//   subtitle,
//   data,
//   returnValue = "emp_id",
//   onSelect,
//   selectAllLabel = "",
//   selectedEmployeeIds = [],
//   labelKey = "name",
// }) => {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [filteredData, setFilteredData] = useState(data);
//   const [selectedItems, setSelectedItems] = useState(selectedEmployeeIds);
//   const [selectedEmployeeNames, setSelectedEmployeeNames] = useState([]);
//   const [selectAll, setSelectAll] = useState(false);

//   const dropdownRef = useRef(null);

//   // Close dropdown when clicked outside
//   useEffect(() => {
//     const handleDocumentClick = (e) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
//         setMenuOpen(false);
//       }
//     };
//     document.addEventListener("click", handleDocumentClick);
//     return () => {
//       document.removeEventListener("click", handleDocumentClick);
//     };
//   }, []);

//   // Filter data based on search term
//   useEffect(() => {
//     const filtered = data
//       ? data.filter((item) => {
//           return item[labelKey]
//             ?.toLowerCase()
//             ?.includes(searchTerm.toLowerCase());
//         })
//       : [];
//     setFilteredData(filtered);
//   }, [searchTerm, data, labelKey]);

//   // Update selectedItems and names when selectedEmployeeIds changes
//   useEffect(() => {
//     if (Array.isArray(selectedEmployeeIds) && selectedEmployeeIds.length > 0) {
//       const selectedItems = selectedEmployeeIds;
//       const selectedNames = data
//         ?.filter((item) => selectedEmployeeIds.includes(item.emp_id))
//         .map((item) => item.name);

//       setSelectedItems(selectedItems);
//       setSelectedEmployeeNames(selectedNames);
//     }
//   }, [selectedEmployeeIds, data]);

//   // Update selectAll based on selectedItems length
//   useEffect(() => {
//     if (
//       selectedItems?.length === filteredData?.length &&
//       filteredData?.length > 0
//     ) {
//       setSelectAll(true);
//     } else {
//       setSelectAll(false);
//     }
//   }, [selectedItems, filteredData]);

//   const toggleDropdown = () => {
//     setMenuOpen(!menuOpen);
//   };

//   // Handle item selection or deselection
//   const handleItemClick = (item) => {
//     const itemIndex = selectedItems.findIndex((id) => id === item[returnValue]);
//     if (itemIndex !== -1) {
//       // Deselect item
//       const updatedSelectedItems = [...selectedItems];
//       const updatedSelectedNames = [...selectedEmployeeNames];
//       updatedSelectedItems.splice(itemIndex, 1);
//       updatedSelectedNames.splice(itemIndex, 1);
//       setSelectedItems(updatedSelectedItems);
//       setSelectedEmployeeNames(updatedSelectedNames);
//     } else {
//       // Select item
//       const updatedSelectedItems = [...selectedItems, item[returnValue]];
//       const updatedSelectedNames = [...selectedEmployeeNames, item[labelKey]];
//       setSelectedItems(updatedSelectedItems);
//       setSelectedEmployeeNames(updatedSelectedNames);
//     }
//   };

//   // Handle select all or deselect all
//   const handleSelectAll = () => {
//     if (!selectAll) {
//       const empIds = filteredData.map((item) => item[returnValue]);
//       const empNames = filteredData.map((item) => item[labelKey]);
//       setSelectedItems(empIds);
//       setSelectedEmployeeNames(empNames);
//     } else {
//       setSelectedItems([]);
//       setSelectedEmployeeNames([]);
//     }
//     setSelectAll(!selectAll);
//   };

//   // Handle search input change
//   const handleSearchInputChange = (e) => {
//     setSearchTerm(e.target.value);
//     if (selectAll === false) setSelectAll(false);
//   };

//   // Get selected names for display
//   const getSelectedNames = () => {
//     if (
//       (selectAll || selectedItems.length === filteredData.length) &&
//       !searchTerm
//     ) {
//       return selectAllLabel || "All Subcategories";
//     } else if (selectAll && searchTerm) {
//       return selectAllLabel || "All Subcategories";
//     } else if (selectedItems.length > 0 && !selectAll) {
//       return selectedEmployeeNames.join(", ");
//     } else {
//       return "";
//     }
//   };

//   const isItemSelected = (item) => {
//     return selectedItems.includes(item[returnValue]);
//   };

//   // Trigger onSelect callback when selectedItems changes
//   useEffect(() => {
//     onSelect(selectedItems);
//   }, [selectedItems]);

//   return (
//     <div
//       ref={dropdownRef}
//       className={`user-select-container ${menuOpen ? "open" : ""}`}
//     >
//       <div
//         className={`form-floating ${menuOpen ? "label-up" : ""}`}
//         onClick={toggleDropdown}
//       >
//         <input
//           type="text"
//           className="form-control"
//           id={title}
//           value={getSelectedNames()}
//           onChange={handleSearchInputChange}
//           readOnly={true}
//           aria-label={title}
//           role="combobox"
//           style={{
//             maxHeight: "46px",
//             minHeight: "initial ",
//             borderRadius: "4px",
//           }}
//         />
//         <label htmlFor={title}>{title || "Subcategory"}</label>
//       </div>
//       {menuOpen && (
//         <div className="user-select-dropdown-menu">
//           <ul className="user-select-dropdown-list">
//             <Box
//               display="flex"
//               backgroundColor="#F4F5FA"
//               borderRadius="6px"
//               marginX="10px"
//               height="40px"
//               marginBottom="10px"
//             >
//               <input
//                 type="text"
//                 className="p-2"
//                 style={{
//                   border: "none",
//                   outline: "none",
//                   background: "transparent",
//                   width: "100%",
//                   height: "unset !important",
//                 }}
//                 id={title}
//                 placeholder="Search"
//                 autoFocus={true}
//                 value={searchTerm}
//                 autoComplete="off"
//                 onChange={handleSearchInputChange}
//               />
//             </Box>
//             {searchTerm === "" && filteredData.length !== 0 && (
//               <li
//                 className="user-select-dropdown-listItem d-flex align-items-center"
//                 onClick={handleSelectAll}
//                 style={{ display: "flex" }}
//               >
//                 <input
//                   type="checkbox"
//                   checked={selectAll}
//                   onChange={handleSelectAll}
//                   style={{
//                     padding: "0px !important",
//                     flex: "unset !important",
//                   }}
//                 />
//                 <span
//                   className="ms-3"
//                   style={{ lineHeight: "40px !important" }}
//                 >
//                   {selectAllLabel || "All Subcategories"}
//                 </span>
//               </li>
//             )}
//             {filteredData.length === 0 && (
//               <li className="user-select-dropdown-listItem d-flex align-items-center">
//                 <span className="ms-3">Not Found</span>
//               </li>
//             )}
//             {filteredData.map((item, index) => {
//               return (
//                 <li
//                   key={index}
//                   className="user-select-dropdown-listItem d-flex align-items-center"
//                   role="option"
//                   aria-selected={isItemSelected(item)}
//                   tabIndex={0}
//                   onClick={() => handleItemClick(item)}
//                   style={{ display: "flex" }}
//                 >
//                   <input
//                     type="checkbox"
//                     checked={isItemSelected(item)}
//                     onChange={() => handleItemClick(item)}
//                   />
//                   <div className="d-flex justify-content-between w-100">
//                     <div className="d-flex align-items-center ms-3">
//                       <span>{item[labelKey]}</span>
//                     </div>
//                   </div>
//                 </li>
//               );
//             })}
//           </ul>
//         </div>
//       )}
//     </div>
//   );
// };

// MultipleUserSelectComponent.propTypes = {
//   title: PropTypes.string,
//   subtitle: PropTypes.string,
//   data: PropTypes.arrayOf(PropTypes.object),
//   returnValue: PropTypes.string,
//   onSelect: PropTypes.func,
//   selectAllLabel: PropTypes.string,
//   selectedEmployeeIds: PropTypes.arrayOf(PropTypes.string),
//   labelKey: PropTypes.string,
// };

// export default MultipleUserSelectComponent;

import { Box, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import "./styles.scss";
import ArrowDown from "../../icons/ArrowDown";

const MultipleUserSelectComponent = ({
  title,
  subtitle,
  data,
  returnValue = "emp_id",
  onSelect,
  selectAllLabel = "",
  selectedEmployeeIds = [],
  labelKey = "name",
  setIsSubcategoryModalOpen,
  selectedCategory,
  setSelectedCategory,
  selectedYear,
  setSelectedYear,
  setIsModalOpen,
  type,
  modalFullKey,
  setModalFullKey,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [selectedItems, setSelectedItems] = useState(selectedEmployeeIds);
  const [selectedEmployeeNames, setSelectedEmployeeNames] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const dropdownRef = useRef(null);

  // Close dropdown when clicked outside
  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  // Filter data based on search term
  // useEffect(() => {
  //   const filtered = data
  //     ? data.filter((item) => {
  //         return item[labelKey]
  //           ?.toLowerCase()
  //           ?.includes(searchTerm.toLowerCase());
  //       })
  //     : [];
  //   setFilteredData(filtered);
  // }, [searchTerm, data, labelKey]);

  useEffect(() => {
    const filtered = data
      ? data.filter((item) => {
          return item[labelKey]
            ?.toLowerCase()
            .startsWith(searchTerm.toLowerCase());
        })
      : [];
    setFilteredData(filtered);
  }, [searchTerm, data, labelKey]);

  // Update selectedItems and names when selectedEmployeeIds changes
  useEffect(() => {
    if (Array.isArray(selectedEmployeeIds) && selectedEmployeeIds.length > 0) {
      const selectedItems = selectedEmployeeIds;
      const selectedNames = data
        ?.filter((item) => selectedEmployeeIds.includes(item.emp_id))
        .map((item) => item.name);

      setSelectedItems(selectedItems);
      setSelectedEmployeeNames(selectedNames);
    }
  }, [selectedEmployeeIds, data]);

  // Update selectAll based on selectedItems length
  useEffect(() => {
    if (
      selectedItems?.length === filteredData?.length - 1 && // Subtract 1 to exclude "Other"
      filteredData?.length > 0
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedItems, filteredData]);

  const toggleDropdown = () => {
    setMenuOpen(!menuOpen);
  };

  // Handle item selection or deselection
  const handleItemClick = (item) => {
    if (item[labelKey] === "Other") {
      // Open the dropdown for "Other" but don't select it
      if (type !== "edit") {
        setSelectedCategory(selectedCategory);
        setSelectedYear(selectedYear);
        setIsSubcategoryModalOpen(true);
      } else {
        setIsModalOpen(true);
        setModalFullKey(modalFullKey);
      }
      return;
    }

    const itemIndex = selectedItems.findIndex((id) => id === item[returnValue]);
    if (itemIndex !== -1) {
      // Deselect item
      const updatedSelectedItems = [...selectedItems];
      const updatedSelectedNames = [...selectedEmployeeNames];
      updatedSelectedItems.splice(itemIndex, 1);
      updatedSelectedNames.splice(itemIndex, 1);
      setSelectedItems(updatedSelectedItems);
      setSelectedEmployeeNames(updatedSelectedNames);
    } else {
      // Select item
      const updatedSelectedItems = [...selectedItems, item[returnValue]];
      const updatedSelectedNames = [...selectedEmployeeNames, item[labelKey]];
      setSelectedItems(updatedSelectedItems);
      setSelectedEmployeeNames(updatedSelectedNames);
    }
  };

  // Handle select all or deselect all
  const handleSelectAll = () => {
    if (!selectAll) {
      // Select all except "Other"
      const empIds = filteredData
        .filter((item) => item[labelKey] !== "Other")
        .map((item) => item[returnValue]);
      const empNames = filteredData
        .filter((item) => item[labelKey] !== "Other")
        .map((item) => item[labelKey]);
      setSelectedItems(empIds);
      setSelectedEmployeeNames(empNames);
    } else {
      setSelectedItems([]);
      setSelectedEmployeeNames([]);
    }
    setSelectAll(!selectAll);
  };

  // Handle search input change
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    if (selectAll === false) setSelectAll(false);
  };

  // Get selected names for display
  const getSelectedNames = () => {
    if (
      (selectAll || selectedItems?.length === filteredData?.length - 1) && // Subtract 1 to exclude "Other"
      !searchTerm
    ) {
      return selectAllLabel || "All";
    } else if (selectAll && searchTerm) {
      return selectAllLabel || "All";
    } else if (selectedItems?.length > 0 && !selectAll) {
      return selectedEmployeeNames.join(", ");
    } else {
      return "";
    }
  };

  const isItemSelected = (item) => {
    return selectedItems.includes(item[returnValue]);
  };

  // Trigger onSelect callback when selectedItems changes
  useEffect(() => {
    onSelect(selectedItems);
  }, [selectedItems]);

  return (
    <div
      ref={dropdownRef}
      className={`user-select-container ${menuOpen ? "open" : ""}`}
    >
      {/* <div
        className={`form-floating1 ${menuOpen ? "label-up" : ""}`}
        onClick={toggleDropdown}
      >
        <input
          type="text"
          className="form-control"
          role="combobox"
          style={{
            maxHeight: "40px",
            minHeight: "initial ",
            borderRadius: "4px",
          }}
          placeholder={title}
          onChange={handleSearchInputChange}
        />
      </div> */}
      <div
        className={`form-floating1 ${menuOpen ? "label-up" : ""}`}
        onClick={toggleDropdown}
        style={{ position: "relative", display: "inline-block", width: "100%" }}
      >
        <input
          type="text"
          className="form-control"
          role="combobox"
          style={{
            maxHeight: "40px",
            minHeight: "initial",
            borderRadius: "4px",
            paddingRight: "30px", // Ensure space for the arrow
          }}
          placeholder={title}
          onChange={handleSearchInputChange}
        />
        <span
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            pointerEvents: "none", // Avoid blocking input clicks
            cursor: "pointer",
          }}
        >
          <ArrowDown />
        </span>
      </div>

      {menuOpen && (
        <div className="user-select-dropdown-menu">
          <ul className="user-select-dropdown-list">
            {searchTerm === "" && filteredData.length !== 0 && (
              <li
                className="user-select-dropdown-listItem d-flex align-items-center"
                onClick={handleSelectAll}
                style={{ display: "flex" }}
              >
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                  style={{
                    padding: "0px !important",
                    flex: "unset !important",
                    height: "unset !important",
                  }}
                />
                <span
                  className="ms-3"
                  style={{ lineHeight: "40px !important" }}
                >
                  {selectAllLabel || "All"}
                </span>
              </li>
            )}
            {filteredData.length === 0 && (
              <li className="user-select-dropdown-listItem d-flex align-items-center">
                <span className="ms-3">Not Found</span>
              </li>
            )}
            {filteredData.map((item, index) => {
              if (item[labelKey] === "Other") {
                return (
                  <li
                    key={index}
                    className="user-select-dropdown-listItem d-flex align-items-center"
                    role="option"
                    aria-selected={isItemSelected(item)}
                    tabIndex={0}
                    onClick={() => handleItemClick(item)}
                    style={{ display: "flex" }}
                  >
                    <span className="ms-3">{item[labelKey]}</span>
                  </li>
                );
              } else {
                return (
                  <li
                    key={index}
                    className="user-select-dropdown-listItem d-flex align-items-center"
                    role="option"
                    aria-selected={isItemSelected(item)}
                    tabIndex={0}
                    onClick={() => handleItemClick(item)}
                    style={{ display: "flex" }}
                  >
                    <input
                      type="checkbox"
                      checked={isItemSelected(item)}
                      onChange={() => handleItemClick(item)}
                      style={{
                        padding: "0px !important",
                        flex: "unset !important",
                        height: "unset !important",
                      }}
                    />
                    <span className="ms-3">{item[labelKey]}</span>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
MultipleUserSelectComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  returnValue: PropTypes.string,
  onSelect: PropTypes.func,
  selectAllLabel: PropTypes.string,
  selectedEmployeeIds: PropTypes.arrayOf(PropTypes.string),
  labelKey: PropTypes.string,
};

export default MultipleUserSelectComponent;
