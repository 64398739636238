export const formatValue = (value, unit, currency) => {
  if (typeof value === "string") {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      value = parsedValue; // Use the parsed number
    } else {
      return value; // Return the original value if it's not a valid number
    }
  }

  if (typeof value !== "number") {
    return value;
  }

  const cleanUnit = unit?.trim().toLowerCase();
  const cleanCurrency = currency?.trim().toUpperCase(); // Normalize currency for consistent comparison

  // Format for Indian numbering system
  if (
    cleanUnit === "lakhs" ||
    cleanUnit === "crore" ||
    (cleanUnit === "hundreds" && cleanCurrency === "INR") ||
    (cleanUnit === "thousands" && cleanCurrency === "INR") ||
    (cleanUnit === "actual" && cleanCurrency === "INR")
  ) {
    return value.toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  // Format for Western numbering system
  if (
    cleanUnit === "million" ||
    cleanUnit === "billion" ||
    (cleanUnit === "hundreds" && cleanCurrency !== "INR") ||
    (cleanUnit === "thousands" && cleanCurrency !== "INR") ||
    (cleanUnit === "actual" && cleanCurrency !== "INR")
  ) {
    return value.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  // Fallback to general formatting (default to Western system)
  return value.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

// export const formatValue = (value, unit, currency) => {
//   const numericValue = typeof value === "number" ? value : Number(value);

//   // Return "-" if the conversion fails
//   if (isNaN(numericValue)) {
//     return "-";
//   }

//   // if (typeof value !== "number") {
//   //   return value;
//   // }

//   console.log("value--->", numericValue, unit);

//   const cleanUnit = unit?.trim().toLowerCase();
//   const cleanCurrency = currency?.trim().toUpperCase(); // Normalize currency for consistent comparison

//   // Format for Indian numbering system
//   if (
//     cleanUnit === "lakhs" ||
//     cleanUnit === "crore" ||
//     (cleanUnit === "hundreds" && cleanCurrency === "INR") ||
//     (cleanUnit === "thousands" && cleanCurrency === "INR") ||
//     (cleanUnit === "actual" && cleanCurrency === "INR")
//   ) {
//     return numericValue.toLocaleString("en-IN", { maximumFractionDigits: 2 });
//   }

//   // Format for Western numbering system
//   if (
//     cleanUnit === "million" ||
//     cleanUnit === "billion" ||
//     (cleanUnit === "hundreds" && cleanCurrency !== "INR") ||
//     (cleanUnit === "thousands" && cleanCurrency !== "INR") ||
//     (cleanUnit === "actual" && cleanCurrency !== "INR")
//   ) {
//     return numericValue.toLocaleString("en-US", { maximumFractionDigits: 2 });
//   }

//   // Fallback to general formatting (default to Western system)
//   return numericValue.toLocaleString("en-US", { maximumFractionDigits: 2 });
// };
