import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Select from "react-select";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import CustomPrompt from "../../../../organisms/customPrompt/index";
import CustomButton from "../../../../atoms/CustomButton";
import { display } from "@mui/system";
import Download from "../../../../icons/Download";
import "./styles.scss";
import { capitalizeWords } from "../../../../../helpers/capitalize";
import FinancialDataTable from "../../../../organisms/viewDataTable/index2";
import FinancialAnalysisGenerator from "./addAnalysis";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";

const ToggleSwitch = ({ isToggled, onToggle }) => (
  <div
    className={`toggle-button ${isToggled ? "enabled" : "disabled"}`}
    onClick={onToggle}
  >
    <span className="button-text">{isToggled ? "Mode" : "Mode"}</span>
  </div>
);

// const data = {
//   response: 200,
//   status: true,
//   data: {
//     YTD: {
//       "Equity Share Capital": "2,876.60",
//       "Total Share Capital": "2,876.60",
//       "Reserves and Surplus": "97,985.30",
//       "Total Reserves and Surplus": "97,985.30",
//       "Employees Stock Options": "0",
//       "Total Shareholders Funds": "100,861.90",
//       "Hybrid/Debt/Other Securities": "0",
//       "Long Term Borrowings": "119,499.60",
//       "Other Long Term Liabilities": "42,876.10",
//       "Long Term Provisions": "284.90",
//       "Total Non-Current Liabilities": "162,660.60",
//       "Short Term Borrowings": "6,482.60",
//       "Trade Payables": "28,753.10",
//       "Other Current Liabilities": "29,751.60",
//       "Short Term Provisions": "23,303.50",
//       "Total Current Liabilities": "88,290.80",
//       "Total Capital And Liabilities": "351,813.30",
//       "Tangible Assets": "119,113.00",
//       "Intangible Assets": "102,604.40",
//       "Capital Work-In-Progress": "4,322.60",
//       "Intangible Assets Under Development": "7,689.10",
//       "Fixed Assets": "233,729.10",
//       "Non-Current Investments": "57,640.60",
//       "Deferred Tax Assets [Net]": "12,993.80",
//       "Long Term Loans And Advances": "0",
//       "Other Non-Current Assets": "9,415.40",
//       "Total Non-Current Assets": "313,778.90",
//       "Current Investments": "0",
//       Inventories: "0",
//       "Trade Receivables": "2,500.30",
//       "Cash And Cash Equivalents": "740.80",
//       "Short Term Loans And Advances": "4,216.20",
//       "Other Current Assets": "30,577.10",
//       "Total Current Assets": "38,034.40",
//       "Total Assets": "351,813.30",
//       "Contingent Liabilities": "25,890",
//       "Expenditure In Foreign Currency": "13,124.20",
//       "Other Earnings": "3,647.80",
//       "Bonus Equity Share Capital": "1,566.39",
//       "Non-Current Investments Quoted Market Value": "37,624.20",
//       "Non-Current Investments Unquoted Book Value": "33,223.00",
//       "Current Investments Quoted Market Value": "-3,519.90",
//       "Current Investments Unquoted Book Value": "----",
//     },
//   },
//   message: "YTD calculated successfully",
// };

const FinancialSheetDataTable = ({ data, periodType, auditStatus }) => {
  const [cookies] = useCookies(["t"]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [metricChartTypes, setMetricChartTypes] = useState({
    value: "bar",
    label: "Bar Chart",
  });
  const [selectedPLMetrics, setSelectedPLMetrics] = useState([]);
  const [selectedPLYears, setSelectedPLYears] = useState([]);
  const [plMetricChartTypes, setPlMetricChartTypes] = useState({});
  const printRef = useRef(null); // Reference for capturing the component
  const [preview, setPreview] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedCurrency2, setSelectedCurrency2] = useState("");
  const [selectedUnit2, setSelectedUnit2] = useState("");
  const [convertedBalanceSheetData, setConvertedBalanceSheetData] = useState(
    {},
  );
  const [convertedPlData, setConvertedPlData] = useState({});
  const [showGeneral, setShowGeneral] = useState(false);
  const [convertedData, setConvertedData] = useState({
    balance_sheet: {},
    pl: {},
    // Add more types as needed
  });

  const [convertedDataPl, setConvertedDataPl] = useState({});
  const [convertedDataBs, setConvertedDataBs] = useState({});
  const [currencyPl, setCurrencyPl] = useState("");
  const [unitPl, setUnitPl] = useState("");
  const [currencyBs, setCurrencyBs] = useState("");
  const [unitBs, setUnitBs] = useState("");

  useEffect(() => {
    const fetchCurrencyConversionRates = async () => {
      try {
        const response = await axios.get(
          "https://open.er-api.com/v6/latest/INR",
        );

        if (response) {
          // setConversionRates(response?.data?.rates);
        }
      } catch (error) {
        console.error("Error fetching currency conversion rates:", error);
      }
    };

    fetchCurrencyConversionRates();
  }, []);

  const handleDataConverted1 = ({ sheetType, data, currency, unit }) => {
    if (sheetType === "pl") {
      setConvertedDataPl(data);
      setCurrencyPl(currency);
      setUnitPl(unit);
    }
  };

  const handleDataConverted2 = ({ sheetType, data, currency, unit }) => {
    if (sheetType === "balance_sheet") {
      setConvertedDataBs(data);
      setCurrencyBs(currency);
      setUnitBs(unit);
    }
  };

  // console.log("convertedData------------->", convertedData);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  // const handlePrint = useReactToPrint({
  //   content: () => printRef.current,
  //   onBeforeGetContent: () => {
  //     // You can add scaling logic here
  //     if (printRef.current) {
  //       printRef.current.style.transform = "scale(0.9)"; // Adjust scale as needed
  //       printRef.current.style.transformOrigin = "top left";
  //       printRef.current.style.width = "100%"; // Adjust the width if necessary
  //     }
  //   },
  //   onAfterPrint: () => {
  //     // Reset styles after printing
  //     if (printRef.current) {
  //       printRef.current.style.transform = "none";
  //       printRef.current.style.width = "auto";
  //     }
  //   },
  // });

  useEffect(() => {
    const hasBalanceSheetData =
      data?.balanceSheetData?.[auditStatus]?.[periodType] &&
      Object.keys(data.balanceSheetData[auditStatus][periodType]).length !== 0;

    const hasPLData =
      data?.plData?.[auditStatus]?.[periodType] &&
      Object.keys(data.plData[auditStatus][periodType]).length !== 0;

    // Update state based on conditions
    if (hasBalanceSheetData && hasPLData) {
      setShowGeneral(true);
    } else {
      setShowGeneral(false);
    }
  }, [data, auditStatus, periodType]);

  useEffect(() => {
    // Extract currencyAndUnits from the financialData
    const { currencyAndUnits } = data || {};

    // Extract the 'balanceSheetData' which contains 'audited' and 'yearly'
    const balanceSheetCurrencyAndUnits =
      currencyAndUnits?.balanceSheetData?.audited?.yearly;

    if (balanceSheetCurrencyAndUnits) {
      // Get the keys of the balanceSheetCurrencyAndUnits object (i.e., the years)
      const years = Object.keys(balanceSheetCurrencyAndUnits);

      if (years.length > 0) {
        // Access the first year or choose the latest year if required
        const latestYear = years[years.length - 1]; // Assuming the last year in the list is the latest

        // Set currency and units using the selected year's values
        setSelectedCurrency(balanceSheetCurrencyAndUnits[latestYear].currency);
        setSelectedUnit(balanceSheetCurrencyAndUnits[latestYear].units);
      } else {
        // Handle the case where plSheetCurrencyAndUnits is undefined or null
        console.warn("balanceSheetCurrencyAndUnits is undefined or null");
      }
    }
  }, [data, auditStatus, periodType]); // Ensure the effect re-runs when 'data' changes

  useEffect(() => {
    // Handle balance sheet data conversion
    if (selectedCurrency && selectedUnit) {
      const convertedBalanceSheetData = {};
      Object.keys(
        data?.balanceSheetData?.[auditStatus]?.[periodType] || {},
      ).forEach((year) => {
        convertedBalanceSheetData[year] = {};
        Object.keys(
          data?.balanceSheetData?.[auditStatus]?.[periodType][year] || {},
        ).forEach((metric) => {
          const originalValue =
            data?.balanceSheetData?.[auditStatus]?.[periodType]?.[year][metric];
          convertedBalanceSheetData[year][metric] = convertValue(
            originalValue,
            // selectedCurrency,
            // selectedUnit,
            year,
          );
        });
      });
      setConvertedBalanceSheetData(convertedBalanceSheetData);
    }

    // Handle PL data conversion
    if (selectedCurrency2 && selectedUnit2) {
      const convertedPlData = {};
      Object.keys(data?.plData?.[auditStatus]?.[periodType] || {}).forEach(
        (year) => {
          convertedPlData[year] = {};
          Object.keys(
            data?.plData?.[auditStatus]?.[periodType]?.[year] || {},
          ).forEach((metric) => {
            const originalValue =
              data?.plData?.[auditStatus]?.[periodType]?.[year][metric];
            convertedPlData[year][metric] = convertValuePL(
              originalValue,
              // selectedCurrency2,
              // selectedUnit2,
              year,
            );
          });
        },
      );
      setConvertedPlData(convertedPlData);
    }
  }, [
    selectedCurrency,
    selectedUnit,
    selectedCurrency2,
    selectedUnit2,
    data,
    auditStatus,
    periodType,
  ]);

  useEffect(() => {
    // Extract currencyAndUnits from the financialData
    const { currencyAndUnits } = data || {}; // Ensure data is not undefined or null

    // Extract the 'balanceSheetData' which contains 'audited' and 'yearly'
    const plSheetCurrencyAndUnits =
      currencyAndUnits?.plData?.[auditStatus]?.[periodType];

    if (plSheetCurrencyAndUnits) {
      // Get the keys of the balanceSheetCurrencyAndUnits object (i.e., the years)
      const years = Object.keys(plSheetCurrencyAndUnits);

      if (years.length > 0) {
        // Access the first year or choose the latest year if required
        const latestYear = years[years.length - 1]; // Assuming the last year in the list is the latest

        // Set currency and units using the selected year's values
        setSelectedCurrency2(plSheetCurrencyAndUnits[latestYear].currency);
        setSelectedUnit2(plSheetCurrencyAndUnits[latestYear].units);
      }
    } else {
      // Handle the case where plSheetCurrencyAndUnits is undefined or null
      console.warn("plSheetCurrencyAndUnits is undefined or null");
    }
  }, [data, auditStatus, periodType]); // Ensure the effect re-runs when 'data', 'auditStatus', or 'periodType' changes

  const currencyOptions = [
    { value: "INR", label: "INR" },
    { value: "USD", label: "USD" },
    // Add more currencies as needed
  ];

  const unitOptions = [
    { value: "Hundreds", label: "Hundreds" },
    { value: "Thousands", label: "Thousands" },
    { value: "Lakhs", label: "Lakhs" },
    { value: "Crore", label: "Crore" },
    { value: "Million", label: "Million" },
    { value: "Billion", label: "Billion" },
  ];

  const conversionRates = {
    INR: {
      Billion: 0.00001, // 1 billion = 1,000,000,000, so 1 billion in thousands = 0.00001
      Crore: 0.0001,
      Million: 0.001,
      Lakhs: 0.01, // 1 lakh = 100,000, so 1 lakh in thousands = 0.01
      Thousands: 1,
      Hundreds: 10,
    },
    USD: {
      Billion: 0.0000012, // Example conversion rate for billion
      Crore: 0.0000012, // Example conversion rate
      Million: 0.000012, // Example conversion rate
      Lakhs: 0.00012, // Example conversion rate for lakhs
      Thousands: 0.012,
      Hundreds: 0.12,
    },
  };

  // const convertValue = (value, year) => {
  //   // Extract current currency and unit for the given year
  //   console.log("VALUEEEEEEEEEEEEEEEEEE", value);
  //   const currentCurrency =
  //     data?.currencyAndUnits?.balanceSheetData?.[auditStatus]?.[periodType]?.[
  //       year
  //     ]?.currency;
  //   const currentUnit =
  //     data?.currencyAndUnits?.balanceSheetData?.[auditStatus]?.[periodType]?.[
  //       year
  //     ]?.units;

  //   console.log("currentCurrency", currentCurrency);
  //   console.log("currentUnit", currentUnit);

  //   // Define conversion function for individual values
  //   const convert = (val) => {
  //     if (
  //       !currentCurrency ||
  //       !currentUnit ||
  //       !conversionRates[currentCurrency] ||
  //       !conversionRates[currentCurrency][currentUnit] ||
  //       !conversionRates[selectedCurrency] ||
  //       !conversionRates[selectedCurrency][selectedUnit]
  //     ) {
  //       console.error(
  //         `Conversion rates not defined for ${currentCurrency}, ${currentUnit}, ${selectedCurrency}, or ${selectedUnit}`,
  //       );
  //       return val; // or handle this case as needed
  //     }

  //     // Convert the value to base unit
  //     const inBaseUnit =
  //       parseFloat(val) / conversionRates[currentCurrency][currentUnit];

  //     // Convert from base unit to the target unit
  //     const convertedValue =
  //       inBaseUnit * conversionRates[selectedCurrency][selectedUnit];

  //     // Return the value rounded to 2 decimal places
  //     return parseFloat(convertedValue.toFixed(2));
  //   };

  //   // Check if value is an object and handle accordingly
  //   if (typeof value === "object" && value !== null) {
  //     return Object.fromEntries(
  //       Object.entries(value).map(([key, val]) => [
  //         key,
  //         convert(val), // Apply conversion to each nested value
  //       ]),
  //     );
  //   }

  //   // Handle simple (non-nested) values
  //   return typeof value === "string"
  //     ? convert(value)
  //     : convert(value?.toString());
  // };

  const convertValue = (value, year) => {
    // Extract current currency and unit for the given year
    const currentCurrency =
      data?.currencyAndUnits?.balanceSheetData?.[auditStatus]?.[periodType]?.[
        year
      ]?.currency;
    const currentUnit =
      data?.currencyAndUnits?.balanceSheetData?.[auditStatus]?.[periodType]?.[
        year
      ]?.units;

    // Define conversion function for individual values
    const convert = (val) => {
      if (
        !currentCurrency ||
        !currentUnit ||
        !conversionRates[currentCurrency] ||
        !conversionRates[currentCurrency][currentUnit] ||
        !conversionRates[selectedCurrency] ||
        !conversionRates[selectedCurrency][selectedUnit]
      ) {
        console.error(
          `Conversion rates not defined for ${currentCurrency}, ${currentUnit}, ${selectedCurrency}, or ${selectedUnit}`,
        );
        return val; // or handle this case as needed
      }

      // Convert the value to base unit
      const inBaseUnit =
        parseFloat(val) / conversionRates[currentCurrency][currentUnit];

      // Convert from base unit to the target unit
      const convertedValue =
        inBaseUnit * conversionRates[selectedCurrency][selectedUnit];

      // Return the value rounded to 2 decimal places
      return parseFloat(convertedValue.toFixed(2));
    };

    // Recursive conversion for nested objects and arrays
    const recursiveConvert = (val) => {
      if (typeof val === "object" && val !== null) {
        if (Array.isArray(val)) {
          // Handle arrays
          return val.map(recursiveConvert);
        }
        // Handle objects
        return Object.fromEntries(
          Object.entries(val).map(([key, nestedVal]) => [
            key,
            recursiveConvert(nestedVal), // Recursively apply conversion
          ]),
        );
      }
      // Handle simple (non-nested) values
      return typeof val === "string" ? convert(val) : convert(val?.toString());
    };

    return recursiveConvert(value);
  };

  const convertValuePL = (value, year) => {
    // Extract current currency and unit for the given year
    const currentCurrency =
      data?.currencyAndUnits?.plData?.[auditStatus]?.[periodType]?.[year]
        ?.currency;
    const currentUnit =
      data?.currencyAndUnits?.plData?.[auditStatus]?.[periodType]?.[year]
        ?.units;

    // Check if conversion rates are defined
    if (
      !currentCurrency ||
      !currentUnit ||
      !conversionRates[currentCurrency] ||
      !conversionRates[currentCurrency][currentUnit] ||
      !conversionRates[selectedCurrency2] ||
      !conversionRates[selectedCurrency2][selectedUnit2]
    ) {
      console.error(
        `Conversion rates not defined for ${currentCurrency}, ${currentUnit}, ${selectedCurrency2}, or ${selectedUnit2}`,
      );
      return value; // or handle this case as needed
    }

    // Define conversion function for individual values
    const convert = (val) => {
      // Ensure val is a string that can be parsed as a number
      if (isNaN(parseFloat(val))) {
        console.warn(`Value "${val}" cannot be converted.`);
        return val; // Return as-is if it's not a number
      }

      // Convert the value to base unit
      const inBaseUnit =
        parseFloat(val) / conversionRates[currentCurrency][currentUnit];

      // Convert from base unit to the target unit
      const convertedValue =
        inBaseUnit * conversionRates[selectedCurrency2][selectedUnit2];

      // Return the value rounded to 2 decimal places
      return parseFloat(convertedValue.toFixed(2));
    };

    // Check if value is an object and handle accordingly
    if (typeof value === "object" && value !== null) {
      // Process nested values
      return Object.fromEntries(
        Object.entries(value).map(([key, val]) => [
          key,
          convertValuePL(val, year), // Apply conversion to each nested value
        ]),
      );
    }

    // Handle simple (non-nested) values

    return typeof value === "string"
      ? convert(value)
      : convert(value?.toString());
  };

  const bsData = useMemo(() => {
    if (
      !data ||
      !data.balanceSheetData ||
      !data.balanceSheetData[auditStatus] ||
      !data.balanceSheetData[auditStatus][periodType]
    ) {
      return {};
    }
    return data.balanceSheetData[auditStatus][periodType];
  }, [data, auditStatus, periodType]);

  const plData = useMemo(() => {
    if (
      !data ||
      !data.plData ||
      !data.plData?.[auditStatus] ||
      !data.plData?.[auditStatus][periodType]
    ) {
      return {};
    }
    return data.plData[auditStatus][periodType];
  }, [data, auditStatus, periodType]);

  const years = useMemo(() => Object.keys(bsData).sort(), [bsData]);
  const plYears = useMemo(() => Object.keys(plData).sort(), [plData]);

  const metrics = useMemo(() => {
    const allMetrics = years.reduce((acc, year) => {
      const yearMetrics = Object.keys(bsData[year] || {});
      yearMetrics.forEach((metric) => acc.add(metric));
      return acc;
    }, new Set());

    return Array.from(allMetrics);
  }, [bsData, years]);

  const plMetrics = useMemo(() => {
    const allMetrics = plYears.reduce((acc, year) => {
      const yearMetrics = Object.keys(plData[year] || {});
      yearMetrics.forEach((metric) => acc.add(metric));
      return acc;
    }, new Set());
    return Array.from(allMetrics);
  }, [plData, plYears]);

  const tableData = useMemo(() => {
    const dataByMetric = metrics.reduce((acc, metric) => {
      acc[metric] = years.map((year) => ({
        year,
        value:
          bsData[year]?.[metric] !== undefined ? bsData[year][metric] : "-",
      }));
      return acc;
    }, {});
    return dataByMetric;
  }, [bsData, metrics, years]);

  // const metricOptions = useMemo(() => {
  //   const options = [];

  //   Object.keys(bsData).forEach((year) => {
  //     Object.keys(bsData[year]).forEach((parentKey) => {
  //       const parentMetrics = bsData[year][parentKey];
  //       if (typeof parentMetrics === "object" && parentMetrics !== null) {
  //         // If the parentKey has nested values
  //         Object.keys(parentMetrics).forEach((childKey) => {
  //           options.push({
  //             value: `${parentKey} - ${childKey}`,
  //             label: `${parentKey} - ${childKey}`,
  //           });
  //         });
  //       } else {
  //         // If it's a simple metric
  //         options.push({
  //           value: parentKey,
  //           label: parentKey,
  //         });
  //       }
  //     });
  //   });

  //   return options;
  // }, [bsData]);

  // const metricOptions = useMemo(() => {
  //   const options = [];
  //   const uniqueValues = new Set(); // Set to track unique values

  //   Object.keys(bsData).forEach((year) => {
  //     Object.keys(bsData[year]).forEach((parentKey) => {
  //       const parentMetrics = bsData[year][parentKey];

  //       if (typeof parentMetrics === "object" && parentMetrics !== null) {
  //         // If the parentKey has nested values
  //         Object.keys(parentMetrics).forEach((childKey) => {
  //           const value = `${parentKey} - ${childKey}`;
  //           if (!uniqueValues.has(value)) {
  //             uniqueValues.add(value);
  //             options.push({
  //               value,
  //               label: value,
  //             });
  //           }
  //         });
  //       } else {
  //         // If it's a simple metric
  //         const value = parentKey;
  //         if (!uniqueValues.has(value)) {
  //           uniqueValues.add(value);
  //           options.push({
  //             value,
  //             label: value,
  //           });
  //         }
  //       }
  //     });
  //   });

  //   console.log("options", options);

  //   return options;
  // }, [bsData]);

  const metricOptions = useMemo(() => {
    const options = [];
    const uniqueValues = new Set(); // Set to track unique values

    const traverse = (obj, prefix = "") => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        const newPrefix = prefix ? `${prefix} - ${key}` : key;

        if (typeof value === "object" && value !== null) {
          // If the value is an object, recursively traverse it
          traverse(value, newPrefix);
        } else {
          // If it's a simple metric, add it to the options
          if (!uniqueValues.has(newPrefix)) {
            uniqueValues.add(newPrefix);
            options.push({
              value: newPrefix,
              label: newPrefix,
            });
          }
        }
      });
    };

    Object.keys(bsData).forEach((year) => {
      traverse(bsData[year]);
    });

    return options;
  }, [bsData]);

  // const plMetricOptions = useMemo(() => {
  //   const options = [];

  //   Object.keys(plData).forEach((year) => {
  //     Object.keys(plData[year]).forEach((parentKey) => {
  //       const parentMetrics = plData[year][parentKey];
  //       if (typeof parentMetrics === "object" && parentMetrics !== null) {
  //         // If the parentKey has nested values
  //         Object.keys(parentMetrics).forEach((childKey) => {
  //           options.push({
  //             value: `${parentKey} - ${childKey}`,
  //             label: `${parentKey} - ${childKey}`,
  //           });
  //         });
  //       } else {
  //         // If it's a simple metric
  //         options.push({
  //           value: parentKey,
  //           label: parentKey,
  //         });
  //       }
  //     });
  //   });

  //   return options;
  // }, [plData]);

  const plMetricOptions = useMemo(() => {
    const options = [];
    const uniqueValues = new Set(); // Set to track unique values

    Object.keys(plData).forEach((year) => {
      Object.keys(plData[year]).forEach((parentKey) => {
        const parentMetrics = plData[year][parentKey];

        if (typeof parentMetrics === "object" && parentMetrics !== null) {
          // If the parentKey has nested values
          Object.keys(parentMetrics).forEach((childKey) => {
            const value = `${parentKey} - ${childKey}`;
            if (!uniqueValues.has(value)) {
              uniqueValues.add(value);
              options.push({
                value,
                label: value,
              });
            }
          });
        } else {
          // If it's a simple metric
          const value = parentKey;
          if (!uniqueValues.has(value)) {
            uniqueValues.add(value);
            options.push({
              value,
              label: value,
            });
          }
        }
      });
    });

    return options;
  }, [plData]);

  const convertedTableData = useMemo(() => {
    const dataByMetric = metrics.reduce((acc, metric) => {
      acc[metric] = years.map((year) => ({
        year,
        value:
          bsData[year]?.[metric] !== undefined
            ? convertValue(bsData[year][metric], year)
            : "-",
      }));
      return acc;
    }, {});

    return dataByMetric;
  }, [bsData, metrics, years, selectedCurrency, selectedUnit, data]);

  const plTableData = useMemo(() => {
    const dataByMetric = plMetrics.reduce((acc, metric) => {
      acc[metric] = plYears.map((year) => ({
        year,
        value:
          plData[year]?.[metric] !== undefined ? plData[year][metric] : "-",
      }));
      return acc;
    }, {});
    return dataByMetric;
  }, [plData, plMetrics, plYears]);

  const convertedPlTableData = useMemo(() => {
    const dataByMetric = plMetrics.reduce((acc, metric) => {
      acc[metric] = plYears.map((year) => ({
        year,
        value:
          plData[year]?.[metric] !== undefined
            ? convertValuePL(plData[year][metric], year)
            : "-",
      }));
      return acc;
    }, {});
    return dataByMetric;
  }, [plData, plMetrics, plYears, selectedCurrency2, selectedUnit2, data]);

  const getChartData = (metric) => {
    const filteredYears =
      selectedYears.length > 0
        ? selectedYears.map((year) => year.value)
        : years;

    // Split metric into parent and child keys
    const [parentKey, childKey] = metric.split(" - ");

    return filteredYears.map((year) => {
      const parentData = bsData[year]?.[parentKey];
      let value;

      if (typeof parentData === "object" && parentData !== null) {
        // Access the value for the childKey
        value = parentData[childKey];
      } else {
        // Handle case where parentData is not an object
        value = parentData;
      }

      // Convert the value as needed
      const convertedValue = convertValue(
        value,
        year,
        selectedCurrency,
        selectedUnit,
      );

      return {
        name: year,
        value: parseFloat(convertedValue) || 0,
      };
    });
  };
  const getPlChartData = (metric) => {
    const filteredYears =
      selectedPLYears.length > 0
        ? selectedPLYears.map((year) => year.value)
        : plYears;

    // Split metric into parent and child keys
    const [parentKey, childKey] = metric.split(" - ");

    return filteredYears.map((year) => {
      const parentData = plData[year]?.[parentKey];
      let value;

      if (typeof parentData === "object" && parentData !== null) {
        // Access the value for the childKey
        value = parentData[childKey];
      } else {
        // Handle case where parentData is not an object
        value = parentData;
      }

      // Convert the value as needed
      const convertedValue = convertValue(
        value,
        year,
        selectedCurrency2,
        selectedUnit2,
      );

      return {
        name: year,
        value: parseFloat(convertedValue) || 0,
      };
    });
  };

  // const getPlChartData = (metric) => {
  //   const filteredYears =
  //     selectedPLYears.length > 0
  //       ? selectedPLYears.map((year) => year.value)
  //       : plYears;
  //   // return filteredYears.map((year) => ({
  //   //   name: year,
  //   //   value: parseFloat(plData[year]?.[metric]) || 0,
  //   // }));
  //   return filteredYears.map((year) => {
  //     const value = plData[year]?.[metric];
  //     const convertedValue = convertValuePL(
  //       value,
  //       year,
  //       // selectedCurrency2,
  //       // selectedUnit2
  //     );
  //     return {
  //       name: year,
  //       value: parseFloat(convertedValue) || 0,
  //     };
  //   });
  // };

  useEffect(() => {
    if (selectedYears.length === 0 && years.length > 0) {
      setSelectedYears(years.map((year) => ({ value: year, label: year })));
    }
  }, [years]);

  useEffect(() => {
    if (selectedPLYears.length === 0 && plYears.length > 0) {
      setSelectedPLYears(plYears.map((year) => ({ value: year, label: year })));
    }
  }, [plYears]);

  useEffect(() => {
    if (metrics.length > 0 && selectedMetrics.length === 0) {
      setSelectedMetrics([{ value: metrics[0], label: metrics[0] }]);
    }
  }, [metrics]);

  useEffect(() => {
    if (plMetrics.length > 0 && selectedPLMetrics.length === 0) {
      setSelectedPLMetrics([{ value: plMetrics[0], label: plMetrics[0] }]);
    }
  }, [plMetrics]);

  if (periodType !== "YTD") {
    if (Object.keys(bsData).length === 0 && Object.keys(plData).length === 0) {
      return <div>No data available</div>;
    }
  }
  const yearOptions = years.map((year) => ({ value: year, label: year }));
  // const plMetricOptions = plMetrics.map((metric) => ({
  //   value: metric,
  //   label: metric,
  // }));
  const plYearOptions = plYears.map((year) => ({ value: year, label: year }));
  const chartOptions = [
    { value: "line", label: "Line Chart" },
    { value: "bar", label: "Bar Chart" },
  ];

  const handleMetricChartTypeChange = (metric, selectedChartType) => {
    setMetricChartTypes((prev) => ({
      ...prev,
      [metric]: selectedChartType,
    }));
  };
  const handleToggle = () => {
    setPreview(!preview);
  };

  const getBasicInfo = (field) => {
    return data?.[field] || data?.data?.basicInformation?.[field] || "";
  };

  return (
    <div>
      {/* Preview Toggle Section */}
      <div className="mt-4 d-flex align-items-center justify-content-end no-print">
        <span className="preview-mode-text">Preview Mode</span>
        <ToggleSwitch isToggled={preview} onToggle={handleToggle} />
      </div>
      <div ref={printRef} className="print-container no-page-break">
        {!preview && (
          <h1 style={{ fontWeight: "bold", textAlign: "center" }}>
            Financial Report{" "}
          </h1>
        )}

        {/* Company Details Section */}
        {!preview && (
          <div className="section">
            <h3 className="section-heading">Company Details</h3>
            <div className="company-details-report">
              <div className="company-details">
                <table className="company-basic-info">
                  <tbody>
                    {getBasicInfo("name") && (
                      <tr>
                        <td className="label">Company Name</td>
                        <td>{getBasicInfo("name")}</td>
                      </tr>
                    )}
                    {getBasicInfo("domain") && (
                      <tr>
                        <td className="label">Website</td>
                        <td>{getBasicInfo("domain")}</td>
                      </tr>
                    )}
                    {getBasicInfo("dateIncorporation") && (
                      <tr>
                        <td className="label">Date of Incorporation</td>
                        <td>
                          {new Date(
                            getBasicInfo("dateIncorporation"),
                          ).toLocaleDateString()}
                        </td>
                      </tr>
                    )}
                    {getBasicInfo("cin") && (
                      <tr>
                        <td className="label">CIN</td>
                        <td>{getBasicInfo("cin")}</td>
                      </tr>
                    )}
                    {getBasicInfo("email") && (
                      <tr>
                        <td className="label">Email</td>
                        <td>{getBasicInfo("email")}</td>
                      </tr>
                    )}
                    {getBasicInfo("phone") && (
                      <tr>
                        <td className="label">Phone</td>
                        <td>
                          {getBasicInfo("phoneCountryCode")}{" "}
                          {getBasicInfo("phone")}
                        </td>
                      </tr>
                    )}
                    {getBasicInfo("alternatePhone") && (
                      <tr>
                        <td className="label">Alternate Phone</td>
                        <td>
                          {getBasicInfo("alternatePhoneCountryCode")}{" "}
                          {getBasicInfo("alternatePhone")}
                        </td>
                      </tr>
                    )}
                    {getBasicInfo("GST") && (
                      <tr>
                        <td className="label">GST</td>
                        <td>{getBasicInfo("GST")}</td>
                      </tr>
                    )}
                    {getBasicInfo("category") && (
                      <tr>
                        <td className="label">Category</td>
                        <td>{getBasicInfo("category")}</td>
                      </tr>
                    )}
                    {getBasicInfo("registeredOffice") &&
                      [
                        getBasicInfo("registeredOffice")?.address,
                        getBasicInfo("registeredOffice")?.city,
                        getBasicInfo("registeredOffice")?.state,
                        getBasicInfo("registeredOffice")?.country,
                        getBasicInfo("registeredOffice")?.pincode,
                      ].some(Boolean) && (
                        <tr>
                          <td className="label">Registered Office</td>
                          <td>
                            {[
                              getBasicInfo("registeredOffice")?.address,
                              getBasicInfo("registeredOffice")?.city,
                              getBasicInfo("registeredOffice")?.state,
                              getBasicInfo("registeredOffice")?.country,
                              getBasicInfo("registeredOffice")?.pincode,
                            ]
                              .filter(Boolean)
                              .join(", ")
                              .replace(/, ([^,]*)$/, " - $1")}
                          </td>
                        </tr>
                      )}
                    {getBasicInfo("businessOffice") &&
                      [
                        getBasicInfo("businessOffice")?.address,
                        getBasicInfo("businessOffice")?.city,
                        getBasicInfo("businessOffice")?.state,
                        getBasicInfo("businessOffice")?.country,
                        getBasicInfo("businessOffice")?.pincode,
                      ].some(Boolean) && (
                        <tr>
                          <td className="label">Business Office</td>
                          <td>
                            {[
                              getBasicInfo("businessOffice")?.address,
                              getBasicInfo("businessOffice")?.city,
                              getBasicInfo("businessOffice")?.state,
                              getBasicInfo("businessOffice")?.country,
                              getBasicInfo("businessOffice")?.pincode,
                            ]
                              .filter(Boolean)
                              .join(", ")
                              .replace(/, ([^,]*)$/, " - $1")}
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>

                {/* Directors Section */}
                {/* {data?.directors && data.directors.length > 0 && (
                  <>
                    <h2 className="section-heading">Directors</h2>
                    <table className="directors-table">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Name</th>
                          <th>Date of Appointment</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.directors.map((director, index) => (
                          <tr key={director.DIN_PAN}>
                            <td>{index + 1}</td>
                            <td>{director.directorName}</td>
                            <td>
                              {new Date(
                                director.dateOfAppointment,
                              ).toLocaleDateString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )} */}
                {data?.directors &&
                  data.directors.some(
                    (director) =>
                      director.directorName || director.dateOfAppointment,
                  ) && (
                    <>
                      <h2 className="section-heading">Directors</h2>
                      <table className="directors-table">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Name</th>
                            <th>Date of Appointment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.directors
                            .filter(
                              (director) =>
                                director.directorName ||
                                director.dateOfAppointment,
                            )
                            .map((director, index) => (
                              <tr key={director.DIN_PAN || index}>
                                <td>{index + 1}</td>
                                <td>{director.directorName}</td>
                                <td>
                                  {director.dateOfAppointment
                                    ? new Date(
                                        director.dateOfAppointment,
                                      ).toLocaleDateString()
                                    : ""}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  )}

                {/* Shareholders Section */}
                {/* {data?.shareholders && data.shareholders.length > 0 && (
                  <>
                    <h2 className="section-heading">Shareholders</h2>
                    <table className="shareholders-table">
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Name</th>
                          <th>Folio</th>
                          <th>DP ID</th>
                          <th>Shares Held</th>
                          <th>Class of Shares</th>
                          <th>Percentage of Shareholding</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.shareholders.map((shareholder, index) => (
                          <tr key={shareholder.dp_id}>
                            <td>{index + 1}</td>
                            <td>{shareholder.shareholderName}</td>
                            <td>{shareholder.folio}</td>
                            <td>{shareholder.dp_id}</td>
                            <td>{shareholder.sharesHeldNumber}</td>
                            <td>{shareholder.classOfShares}</td>
                            <td>{shareholder.percentageOfShareholding}%</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )} */}
                {data?.shareholders &&
                  data.shareholders.some(
                    (shareholder) => shareholder.shareholderName,
                  ) && (
                    <>
                      <h2 className="section-heading">Shareholders</h2>
                      <table className="shareholders-table">
                        <thead>
                          <tr>
                            <th>S.No.</th>
                            <th>Name</th>
                            <th>Folio</th>
                            <th>DP ID</th>
                            <th>Shares Held</th>
                            <th>Class of Shares</th>
                            <th>Percentage of Shareholding</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.shareholders
                            .filter(
                              (shareholder) => shareholder.shareholderName,
                            )
                            .map((shareholder, index) => (
                              <tr key={shareholder.dp_id || index}>
                                <td>{index + 1}</td>
                                <td>{shareholder.shareholderName || ""}</td>
                                <td>{shareholder.folio || ""}</td>
                                <td>{shareholder.dp_id || ""}</td>
                                <td>{shareholder.sharesHeldNumber || ""}</td>
                                <td>{shareholder.classOfShares || ""}</td>
                                <td>
                                  {/* {shareholder.percentageOfShareholding || ""}% */}
                                  {shareholder.percentageOfShareholding
                                    ? `${shareholder.percentageOfShareholding}%`
                                    : ""}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  )}
              </div>
            </div>
          </div>
        )}

        {data?.data?.data2?.sheetType === "plData" &&
          data?.data?.data2?.jsonData && (
            <div className="section">
              {/* <h3 className="section-heading">Balance Sheet Table</h3> */}
              <div
                style={{
                  backgroundColor: "#f0f0f0",
                  padding: "10px",
                  // borderRadius: "5px",
                  borderBottom: "2px solid #145b64",
                  marginTop: "30px",
                  marginBottom: "0px",
                }}
              >
                <h3 className="section-heading">Profit & Loss</h3>
              </div>

              {/* <div>
                YTD : {data?.data?.data2?.ytdStartMonth} -{" "}
                {data?.data?.data2?.ytdEndMonth}
              </div>
              <div>
                Gaps : {data?.data?.data2?.gaps[0]?.start} -{" "}
                {data?.data?.data2?.gaps[0]?.end}
              </div> */}
              {data?.data?.data2?.jsonData?.YTD ? (
                <div>
                  <div style={{ marginTop: "20px" }}>
                    <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                      YTD:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {data?.data?.data2?.ytdStartMonth} -{" "}
                        {data?.data?.data2?.ytdEndMonth} (Excluding missing
                        data)
                      </span>
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                      Missing Data :{" "}
                      {data?.data?.data2?.gaps[0]?.start &&
                      data?.data?.data2?.gaps[0]?.end ? (
                        <span style={{ fontWeight: "normal" }}>
                          {data?.data?.data2?.gaps[0]?.start ===
                          data?.data?.data2?.gaps[0]?.end
                            ? `${data?.data?.data2?.gaps[0]?.start}`
                            : `${data?.data?.data2?.gaps[0]?.start} -
                              ${data?.data?.data2?.gaps[0]?.end}`}
                        </span>
                      ) : (
                        <span style={{ fontWeight: "normal" }}>No gaps</span>
                      )}
                    </div>
                  </div>

                  <div style={{ overflowX: "auto", marginTop: "20px" }}>
                    <FinancialDataTable
                      sheetType="pl"
                      financialData={{
                        data: data?.data?.data2?.jsonData,
                        // currencyAndUnits:
                        //   data?.currencyAndUnits?.plData?.[auditStatus]?.[
                        //     periodType
                        //   ],
                        currencyAndUnits: {
                          currency:
                            data?.data?.data2?.currencyAndUnits?.currency,
                          units:
                            data?.data?.data2?.currencyAndUnits?.currency ===
                            "INR"
                              ? "Actual"
                              : "Actual",
                        },
                      }}
                      edit={false}
                      onDataConverted1={handleDataConverted1} // Pass callback prop
                      preview={preview}
                    />
                    <FinancialAnalysisGenerator
                      data={convertedDataPl}
                      currency={currencyPl}
                      units={unitPl}
                      auditStatus={auditStatus}
                      periodType={periodType}
                      preview={preview}
                      sheetType="plSheet"
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    No YTD for Profit and Loss Data as Half-Yearly , Quaterly ,
                    Monthly Financial Data not available.
                  </span>
                </div>
              )}
            </div>
          )}

        {/* Profit & Loss Data Section */}
        {data?.plData?.[auditStatus]?.[periodType] &&
          Object.keys(data.plData[auditStatus][periodType]).length !== 0 && (
            <div className="section">
              {/* <h3 className="section-heading">Profit & Loss Table</h3> */}
              <div
                style={{
                  backgroundColor: "#f0f0f0",
                  padding: "10px",
                  // borderRadius: "5px",
                  marginTop: "30px",
                  marginBottom: "0px",
                  borderBottom: "2px solid #145b64",
                }}
              >
                <h3 className="section-heading">Profit & Loss</h3>
              </div>
              {/* {preview && (
                <Select
                  isMulti
                  options={plYearOptions}
                  value={selectedPLYears}
                  onChange={setSelectedPLYears}
                  placeholder="Select years"
                  className="select-year"
                />
              )} */}
              {/* {preview && (
                <div className="currency-unit-selection">
                  <Select
                    options={currencyOptions}
                    value={currencyOptions.find(
                      (option) => option.value === selectedCurrency2,
                    )}
                    onChange={(option) => setSelectedCurrency2(option.value)}
                    className="select-currency"
                    placeholder="Select Currency"
                  />
                  <Select
                    options={unitOptions}
                    value={unitOptions.find(
                      (option) => option.value === selectedUnit2,
                    )}
                    onChange={(option) => setSelectedUnit2(option.value)}
                    className="select-unit"
                    placeholder="Select Unit"
                  />
                </div>
              )} */}

              <div style={{ overflowX: "auto", marginTop: "20px" }}>
                <FinancialDataTable
                  sheetType="pl"
                  financialData={{
                    data: data.plData[auditStatus][periodType],
                    // currencyAndUnits:
                    //   data?.currencyAndUnits?.plData?.[auditStatus]?.[
                    //     periodType
                    //   ],
                    currencyAndUnits: {
                      currency: data?.currency,
                      // units: data?.currency === "INR" ? "Crore" : "Million",
                      // units:
                      //   data?.plSheetUnits === "Actual"
                      //     ? "Actual"
                      //     : data?.currency === "Actual"
                      //     ? "Actual"
                      //     : "Actual",
                      units: data?.plSheetUnits,
                    },
                  }}
                  edit={false}
                  onDataConverted1={handleDataConverted1} // Pass callback prop
                  preview={preview}
                />
              </div>
              {/* <h3 className="section-heading">Profit & Loss Metric Charts</h3>
              {preview && (
                <Select
                  isMulti
                  options={plMetricOptions}
                  value={selectedPLMetrics}
                  onChange={setSelectedPLMetrics}
                  placeholder="Select metrics to display"
                  className="select-metric"
                />
              )}
              {selectedPLMetrics.length > 0 &&
                selectedPLMetrics.map((metric) => (
                  <div key={metric.value} className="chart-container">
                    <h2 className="graph-heading">
                      {capitalizeWords(metric.label)}
                    </h2>
                    {preview && (
                      <Select
                        options={chartOptions}
                        value={chartOptions.find(
                          (option) =>
                            option.value === plMetricChartTypes[metric.value],
                        )}
                        onChange={(option) =>
                          setPlMetricChartTypes((prev) => ({
                            ...prev,
                            [metric.value]: option.value,
                          }))
                        }
                        placeholder="Select chart type"
                        className="select-chart-type"
                      />
                    )}
                    <div
                      style={{
                        marginTop: "30px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ResponsiveContainer width="50%" height={400}>
                        {plMetricChartTypes[metric.value] === "line" ? (
                          <LineChart data={getPlChartData(metric.value)}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="monotone"
                              dataKey="value"
                              stroke="#8884d8"
                              name={`${metric.label}`}
                            />
                          </LineChart>
                        ) : (
                          <BarChart data={getPlChartData(metric.value)}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="value"
                              fill="#8884d8"
                              barSize="60"
                              name={`${metric.label}`}
                            />
                          </BarChart>
                        )}
                      </ResponsiveContainer>
                    </div>
                    <CustomPrompt
                      metric={metric}
                      data={{
                        balanceSheet: {
                          data: convertedBalanceSheetData,
                          currency: selectedCurrency,
                          unit: selectedUnit,
                        },
                        plData: {
                          data: convertedPlData,
                          currency: selectedCurrency2,
                          unit: selectedUnit2,
                        },
                      }}
                      preview={preview}
                      sheetType="plGraph"
                    />
                    <FinancialAnalysisGenerator
                      data={convertedPlData}
                      currency={selectedCurrency2}
                      units={selectedUnit2}
                      auditStatus={auditStatus}
                      periodType={periodType}
                      preview={preview}
                      sheetType="plSheet"
                    />
                  </div>
                ))} */}
              <FinancialAnalysisGenerator
                data={convertedDataPl}
                currency={currencyPl}
                units={unitPl}
                auditStatus={auditStatus}
                periodType={periodType}
                preview={preview}
                sheetType="plSheet"
              />
            </div>
          )}

        {data?.data?.data1?.sheetType === "balanceSheetData" &&
          data?.data?.data1?.jsonData &&
          data?.data?.data1?.jsonData?.YTD && (
            <div className="section">
              {/* <h3 className="section-heading">Balance Sheet Table</h3> */}
              <div
                style={{
                  backgroundColor: "#f0f0f0",
                  padding: "10px",
                  // borderRadius: "5px",
                  borderBottom: "2px solid #145b64",
                  marginTop: "30px",
                  marginBottom: "0px",
                }}
              >
                <h3 className="section-heading">Balance Sheet </h3>
              </div>
              {/* <div style={{ fontWeight: "bold" }}>
                YTD : {data?.data?.data1?.ytdStartMonth} -{" "}
                {data?.data?.data1?.ytdEndMonth}
              </div>
              <div style={{ fontWeight: "bold" }}>
                Gap : {data?.data?.data1?.gaps[0]?.start} -{" "}
                {data?.data?.data1?.gaps[0]?.end}
              </div> */}
              {data?.data?.data1?.jsonData?.YTD ? (
                <div>
                  {" "}
                  <div style={{ marginTop: "20px" }}>
                    <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                      YTD:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {data?.data?.data1?.ytdStartMonth} -{" "}
                        {data?.data?.data1?.ytdEndMonth} (Excluding missing
                        data)
                      </span>
                    </div>

                    <div style={{ fontWeight: "bold" }}>
                      Missing Data :{" "}
                      {data?.data?.data1?.gaps[0]?.start &&
                      data?.data?.data1?.gaps[0]?.end ? (
                        <span style={{ fontWeight: "normal" }}>
                          {data?.data?.data1?.gaps[0]?.start ===
                          data?.data?.data1?.gaps[0]?.end
                            ? `${data?.data?.data1?.gaps[0]?.start}`
                            : `${data?.data?.data1?.gaps[0]?.start} -
                              ${data?.data?.data1?.gaps[0]?.end}`}
                        </span>
                      ) : (
                        <span style={{ fontWeight: "normal" }}>No gaps</span>
                      )}
                    </div>
                  </div>
                  <div style={{ overflowX: "auto", marginTop: "20px" }}>
                    <FinancialDataTable
                      sheetType="balance_sheet"
                      financialData={{
                        data: data?.data?.data1?.jsonData,
                        // currencyAndUnits:
                        //   data?.currencyAndUnits?.balanceSheetData?.[auditStatus]?.[
                        //     periodType
                        //   ],
                        currencyAndUnits: {
                          currency:
                            data?.data?.data1?.currencyAndUnits?.currency,
                          units:
                            data?.data?.data1?.currencyAndUnits?.currency ===
                            "INR"
                              ? "Actual"
                              : "Actual",
                        },
                      }}
                      edit={false}
                      onDataConverted2={handleDataConverted2} // Pass callback prop
                      preview={preview}
                    />
                    <FinancialAnalysisGenerator
                      data={convertedDataBs}
                      currency={currencyBs}
                      units={unitBs}
                      auditStatus={auditStatus}
                      periodType={periodType}
                      preview={preview}
                      sheetType="balanceSheet"
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    No YTD for Balance Sheet Data as Half-Yearly , Quaterly ,
                    Monthly Financial Data not available.
                  </span>
                </div>
              )}
            </div>
          )}

        {/* Balance Sheet Data Section */}
        {data?.balanceSheetData?.[auditStatus]?.[periodType] &&
          Object.keys(data.balanceSheetData[auditStatus][periodType]).length !==
            0 && (
            <div className="section">
              {/* <h3 className="section-heading">Balance Sheet Table</h3> */}
              <div
                style={{
                  backgroundColor: "#f0f0f0",
                  padding: "10px",
                  // borderRadius: "5px",
                  borderBottom: "2px solid #145b64",
                  marginTop: "30px",
                  marginBottom: "0px",
                }}
              >
                <h3 className="section-heading">Balance Sheet </h3>
              </div>
              {/* {preview && (
                <Select
                  isMulti
                  options={yearOptions}
                  value={selectedYears}
                  onChange={setSelectedYears}
                  placeholder="Select years"
                  className="select-year"
                />
              )} */}
              {/* {preview && (
                <div className="currency-unit-selection">
                  <Select
                    options={currencyOptions}
                    value={currencyOptions.find(
                      (option) => option.value === selectedCurrency,
                    )}
                    onChange={(option) => setSelectedCurrency(option.value)}
                    className="select-currency"
                    placeholder="Select Currency"
                  />
                  <Select
                    options={unitOptions}
                    value={unitOptions.find(
                      (option) => option.value === selectedUnit,
                    )}
                    onChange={(option) => setSelectedUnit(option.value)}
                    className="select-unit"
                    placeholder="Select Unit"
                  />
                </div>
              )} */}
              {/* <div className="section-heading">Table</div> */}
              <div style={{ overflowX: "auto", marginTop: "20px" }}>
                <FinancialDataTable
                  sheetType="balance_sheet"
                  financialData={{
                    data: data.balanceSheetData[auditStatus][periodType],
                    currencyAndUnits: {
                      currency: data?.currency,
                      units: data?.balanceSheetUnits,

                      //   units:
                      //     data?.balanceSheetUnits === "Actual"
                      //       ? "Actual"
                      //       : data?.currency === "Actual"
                      //       ? "Actual"
                      //       : "Actual",
                    },
                  }}
                  edit={false}
                  onDataConverted2={handleDataConverted2} // Pass callback prop
                  preview={preview}
                />
              </div>
              {/* <h3 className="section-heading">Balance Sheet Metric Charts</h3>
              {preview && (
                <Select
                  isMulti
                  options={metricOptions}
                  value={selectedMetrics}
                  onChange={setSelectedMetrics}
                  placeholder="Select metrics to display"
                  className="select-metric"
                />
              )}
              {selectedMetrics.length > 0 &&
                selectedMetrics.map((metric) => (
                  <>
                    <div key={metric.value} className="chart-container">
                      <h2 className="graph-heading">
                        {capitalizeWords(metric.label)}
                      </h2>
                      {preview && (
                        <Select
                          options={chartOptions}
                          value={chartOptions.find(
                            (option) =>
                              option.value === metricChartTypes[metric.value],
                          )}
                          onChange={(option) =>
                            handleMetricChartTypeChange(
                              metric.value,
                              option.value,
                            )
                          }
                          placeholder="Select chart type"
                          className="select-chart-type"
                        />
                      )}
                      <div
                        style={{
                          marginTop: "30px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ResponsiveContainer width="50%" height={400}>
                          {metricChartTypes[metric.value] === "line" ? (
                            <LineChart
                              width={200}
                              data={getChartData(metric.value)}
                            >
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Line
                                type="monotone"
                                dataKey="value"
                                stroke="#8884d8"
                                name={`${metric.label}`}
                              />
                            </LineChart>
                          ) : (
                            <BarChart data={getChartData(metric.value)}>
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Bar
                                dataKey="value"
                                fill="#8884d8"
                                name={`${metric.label}`}
                                barSize="60"
                              />
                            </BarChart>
                          )}
                        </ResponsiveContainer>
                      </div>
                      {console.log("metric", metric)}
                      <CustomPrompt
                        metric={metric}
                        data={{
                          balanceSheet: {
                            data: convertedBalanceSheetData,
                            currency: selectedCurrency,
                            unit: selectedUnit,
                          },
                          plData: {
                            data: convertedPlData,
                            currency: selectedCurrency2,
                            unit: selectedUnit2,
                          },
                        }}
                        preview={preview}
                        sheetType="bsGraph"
                      />
                    </div>
                    <FinancialAnalysisGenerator
                      data={convertedBalanceSheetData}
                      currency={selectedCurrency}
                      units={selectedUnit}
                      auditStatus={auditStatus}
                      periodType={periodType}
                      preview={preview}
                      sheetType="balanceSheet"
                    />
                  </>
                ))} */}
              <FinancialAnalysisGenerator
                data={convertedDataBs}
                currency={currencyBs}
                units={unitBs}
                auditStatus={auditStatus}
                periodType={periodType}
                preview={preview}
                sheetType="balanceSheet"
              />
            </div>
          )}

        {/* Custom Prompt Section */}
        {/* <CustomPrompt
          data={{
            balanceSheet: {
              data: convertedBalanceSheetData,
              currency: selectedCurrency,
              unit: selectedUnit,
            },
            plData: {
              data: convertedPlData,
              currency: selectedCurrency2,
              unit: selectedUnit2,
            },
          }}
          preview={preview}
          sheetType="general"
        /> */}
        {preview && showGeneral && (
          <div
            style={{
              backgroundColor: "#f0f0f0",
              padding: "10px",
              // borderRadius: "5px",
              borderBottom: "2px solid #145b64",
              marginTop: "30px",
              marginBottom: "0px",
            }}
          >
            <h3 className="section-heading">General </h3>
          </div>
        )}
        {showGeneral && (
          <FinancialAnalysisGenerator
            data={{
              balanceSheet: {
                data: convertedDataBs,
                currency: currencyBs,
                unit: unitBs,
              },
              plData: {
                data: convertedDataPl,
                currency: currencyPl,
                unit: unitPl,
              },
            }}
            // currency={selectedCurrency2}
            // units={selectedUnit2}
            // auditStatus={auditStatus}
            // periodType={periodType}
            preview={preview}
            sheetType="general"
          />
        )}
      </div>
      {!preview && (
        <div
          className="download-section no-print "
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "30px",
            marginTop: "-15px",
          }}
        >
          <CustomButton
            type="btn-primary"
            iconRequired={false}
            handleClick={handlePrint}
          >
            <Download color="white" /> &nbsp;Download PDF
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default FinancialSheetDataTable;
