import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import PageCard from "../../../../atoms/PageCard";
import PageHeader from "../../../../atoms/PageHeader";
import { getData } from "../../../../../services";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import "./styles.scss";
import BackBtn from "../../../../atoms/BackBtn";

const AddEmployee = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies] = useCookies(["t"]);
  const [formData, setFormData] = useState(null);

  const getCompanyData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "company/getCompanyById",
        params: {
          companyId: id,
        },
        token: cookies.t,
      });

      if (res) {
        setFormData(res?.data?.data);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [id, cookies.t]);

  useEffect(() => {
    getCompanyData();
  }, [getCompanyData]);

  if (!formData) {
    return <div>Loading...</div>;
  }

  const {
    name,
    domain,
    dateIncorporation,
    cin,
    email,
    phoneCountryCode,
    phone,
    alternatePhoneCountryCode,
    alternatePhone,
    GST,
    category,
    registeredOffice = {},
    businessOffice = {},
    directors = [],
    shareholders = [],
  } = formData;

  return (
    <>
      <div style={{ width: "20px", marginBottom: "10px" }}>
        <BackBtn />
      </div>
      <PageHeader title="View Company" />

      <PageCard className="page-card">
        <div className="box">
          <h2 className="heading">Company Details</h2>
          <hr />
          <div className="details-table">
            <div className="details-row">
              <div className="details-label">Name</div>
              <div className="details-value">{name ? name : "-"}</div>
            </div>
            <div className="details-row">
              <div className="details-label">Domain</div>
              <div className="details-value">{domain ? domain : "-"}</div>
            </div>
            <div className="details-row">
              <div className="details-label">Date of Incorporation</div>
              <div className="details-value">
                {dateIncorporation
                  ? new Date(dateIncorporation).toLocaleDateString()
                  : "-"}
              </div>
            </div>
            <div className="details-row">
              <div className="details-label">CIN</div>
              <div className="details-value">{cin ? cin : "-"}</div>
            </div>
            <div className="details-row">
              <div className="details-label">Email</div>
              <div className="details-value">{email ? email : "-"}</div>
            </div>
            <div className="details-row">
              <div className="details-label">Phone Number</div>
              {/* <div className="details-value">
                {phoneCountryCode} {phone}
              </div> */}
              <div className="details-value">
                {
                  phoneCountryCode && phone
                    ? `${phoneCountryCode} ${phone}` // If both are present, show them together
                    : phoneCountryCode
                    ? phoneCountryCode // If only phoneCountryCode is present, show it
                    : phone
                    ? phone // If only phone is present, show it
                    : "-" // If neither is present, show a dash
                }
              </div>
            </div>
            <div className="details-row">
              <div className="details-label">Alternate Phone Number</div>
              {/* <div className="details-value">
                {alternatePhoneCountryCode} {alternatePhone}
              </div> */}
              <div className="details-value">
                {
                  alternatePhoneCountryCode && alternatePhone
                    ? `${alternatePhoneCountryCode} ${alternatePhone}` // If both are present, show them together
                    : alternatePhoneCountryCode
                    ? alternatePhoneCountryCode // If only alternatePhoneCountryCode is present, show it
                    : alternatePhone
                    ? alternatePhone // If only alternatePhone is present, show it
                    : "-" // If neither is present, show a dash
                }
              </div>
            </div>
            <div className="details-row">
              <div className="details-label">GST</div>
              <div className="details-value">{GST ? GST : "-"}</div>
            </div>
            <div className="details-row">
              <div className="details-label">Category</div>
              <div className="details-value">{category ? category : "-"}</div>
            </div>
          </div>
        </div>

        <div className="box">
          <h2 className="heading">Registered Office</h2>
          <hr />
          <div className="details-table">
            <div className="details-row">
              <div className="details-label">Address</div>
              <div className="details-value">
                {registeredOffice.address ? registeredOffice.address : "-"}
              </div>
            </div>
            <div className="details-row">
              <div className="details-label">City</div>
              <div className="details-value">
                {registeredOffice.city ? registeredOffice.city : "-"}
              </div>
            </div>
            <div className="details-row">
              <div className="details-label">State</div>
              <div className="details-value">
                {registeredOffice.state ? registeredOffice.state : "-"}
              </div>
            </div>
            <div className="details-row">
              <div className="details-label">Country</div>
              <div className="details-value">
                {registeredOffice.country ? registeredOffice.country : "-"}
              </div>
            </div>
            <div className="details-row">
              <div className="details-label">Pincode</div>
              <div className="details-value">
                {registeredOffice.pincode ? registeredOffice.pincode : "-"}
              </div>
            </div>
          </div>
        </div>

        <div className="box">
          <h2 className="heading">Business Office</h2>
          <hr />
          <div className="details-table">
            <div className="details-row">
              <div className="details-label">Address</div>
              <div className="details-value">
                {businessOffice.address ? businessOffice.address : "-"}
              </div>
            </div>
            <div className="details-row">
              <div className="details-label">City</div>
              <div className="details-value">
                {businessOffice.city ? businessOffice.city : "-"}
              </div>
            </div>
            <div className="details-row">
              <div className="details-label">State</div>
              <div className="details-value">
                {businessOffice.state ? businessOffice.state : "-"}
              </div>
            </div>
            <div className="details-row">
              <div className="details-label">Country</div>
              <div className="details-value">
                {businessOffice.country ? businessOffice.country : "-"}
              </div>
            </div>
            <div className="details-row">
              <div className="details-label">Pincode</div>
              <div className="details-value">
                {businessOffice.pincode ? businessOffice.pincode : "-"}
              </div>
            </div>
          </div>
        </div>

        <div className="box">
          <h2 className="heading">Directors</h2>
          <hr />
          {directors.map((director, index) => (
            <div key={index} className="details-table">
              <div className="details-row">
                <div className="details-label">DIN/PAN</div>
                <div className="details-value">
                  {director.DIN_PAN ? director.DIN_PAN : "-"}
                </div>
              </div>
              <div className="details-row">
                <div className="details-label">Name</div>
                <div className="details-value">
                  {director.directorName ? director.directorName : "-"}
                </div>
              </div>
              <div className="details-row">
                <div className="details-label">Date of Appointment</div>
                <div className="details-value">
                  {director.dateOfAppointment
                    ? new Date(director.dateOfAppointment).toLocaleDateString()
                    : "-"}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="box">
          <h2 className="heading">Shareholders</h2>
          <hr />
          {shareholders.map((shareholder, index) => (
            <div key={index} className="details-table">
              <div className="details-row">
                <div className="details-label">Name</div>
                <div className="details-value">
                  {shareholder.shareholderName
                    ? shareholder.shareholderName
                    : "-"}
                </div>
              </div>
              <div className="details-row">
                <div className="details-label">Folio</div>
                <div className="details-value">
                  {shareholder.folio ? shareholder.folio : "-"}
                </div>
              </div>
              <div className="details-row">
                <div className="details-label">DP ID</div>
                <div className="details-value">
                  {shareholder.dp_id ? shareholder.dp_id : "-"}
                </div>
              </div>
              <div className="details-row">
                <div className="details-label">Shares Held</div>
                <div className="details-value">
                  {shareholder.sharesHeldNumber
                    ? shareholder.sharesHeldNumber
                    : "-"}
                </div>
              </div>
              <div className="details-row">
                <div className="details-label">Class of Shares</div>
                <div className="details-value">
                  {shareholder.classOfShares ? shareholder.classOfShares : "-"}
                </div>
              </div>
              <div className="details-row">
                <div className="details-label">Percentage of Shareholding</div>
                <div className="details-value">
                  {shareholder.percentageOfShareholding
                    ? `${shareholder.percentageOfShareholding} %`
                    : "-"}
                </div>
              </div>
            </div>
          ))}
        </div>
      </PageCard>
    </>
  );
};

export default AddEmployee;
