import React, { useEffect, useRef, useState } from "react";
import SelectDropdown from "../../molecules/Dropdown";
import "./styles.scss";
import Delete from "../../icons/Delete";
import CustomButton from "../../atoms/CustomButton";
import PageHeader from "../../atoms/PageHeader";
import { textAlign } from "@mui/system";
import Close from "../../icons/Close";
import { postData } from "../../../services";
import { useCookies } from "react-cookie";
import { confimationStyles } from "../../../assets/styles/toast";
import { errorStyles } from "../../molecules/Dropdown/dropdown";
import toast from "react-hot-toast";
import Modal from "../modals/Modal";
import { formatValue } from "../../../helpers/formatValue";
import MultipleUserSelectComponent from "../../molecules/Multiselect/index";
import { useNavigate } from "react-router-dom";

const AddCategoryModal = ({ isOpen, onClose, onSave }) => {
  const [newCategory, setNewCategory] = useState("");

  const handleSave = () => {
    if (newCategory.trim()) {
      onSave(newCategory);
      setNewCategory(""); // Clear input field
      onClose(); // Close modal
    }
  };

  return (
    isOpen && (
      <div style={styles.modalOverlay}>
        <div style={styles.modalContent}>
          <h2>Add Category</h2>
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Enter category name"
            style={styles.input}
          />
          <div style={styles.buttonGroup}>
            <button onClick={onClose} style={styles.cancelButton}>
              Cancel
            </button>
            <button onClick={handleSave} style={styles.saveButton}>
              Save
            </button>
          </div>
        </div>
      </div>
    )
  );
};

const AddSubcategoryModal = ({ isOpen, onClose, onSave, category, year }) => {
  const [newSubcategory, setNewSubcategory] = useState("");
  console.log(
    "isOpen, onClose, onSave, category, year,newSubcategory",
    isOpen,
    onClose,
    onSave,
    category,
    year,
    newSubcategory,
  );

  const handleSave = () => {
    console.log("Hello 1");
    if (newSubcategory.trim()) {
      console.log("Hello 2", newSubcategory);

      onSave(year, category, newSubcategory); // Save the new subcategory
      setNewSubcategory(""); // Clear the input field
      onClose(); // Close the modal
    }
  };

  return (
    isOpen && (
      <Modal
        id="add-year-modal"
        title="Add Subcategory"
        isActive={isOpen}
        onClose={onClose}
        width="400px"
      >
        <div className="p-0">
          <input
            type="text"
            value={newSubcategory}
            onChange={(e) => setNewSubcategory(e.target.value)}
            placeholder="Enter subcategory name"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSave();
              }
            }}
            style={{ width: "100%" }}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <CustomButton
              type="btn-primary"
              iconRequired={false}
              handleClick={handleSave}
            >
              Add Subcategory
            </CustomButton>
          </div>
        </div>
      </Modal>
    )
  );
};

const styles = {
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "15px",
    minWidth: "400px",
    textAlign: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginBottom: "15px",
    borderRadius: "3px",
    border: "1px solid #ccc",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelButton: {
    backgroundColor: "#ccc",
    border: "none",
    padding: "10px 15px",
    cursor: "pointer",
    borderRadius: "3px",
  },
  saveButton: {
    backgroundColor: "#4CAF50",
    color: "#fff",
    border: "none",
    padding: "10px 15px",
    cursor: "pointer",
    borderRadius: "3px",
  },
};

const TableBuilder = ({
  companyId,
  sheetType,
  auditStatus,
  excurrency,
  exunit,
  periodType,
  mydata,
  categories,
  setCategories,
  subcategories = {},
  data,
  setData,
  years,
  setYears,
  availableData,
  currentTime,
}) => {
  const [cookies] = useCookies(["t"]);
  const [selectedOption, setSelectedOption] = useState([null]);
  const [selectedOption1, setSelectedOption1] = useState([null]);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isYearModalOpen, setIsYearModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true); // Start with the dropdown open
  const dropdownRef = useRef(null);

  console.log("categories----->", categories);
  console.log("auditStatus", auditStatus);
  console.log("periodType", periodType);
  console.log("sheetType", sheetType);

  const checkDataAvailability = ({ sheetType, periodType, auditStatus }) => {
    let data;
    if (sheetType === "balance_sheet") {
      data = myData?.balanceSheetData;
    } else if (sheetType === "pl") {
      data = myData?.plData;
    } else {
      return `Invalid sheetType: ${sheetType}`;
    }
    if (!data) {
      return false;
    }

    // Accessing the specific audit status (audited or unaudited)
    const auditData = data[auditStatus];
    if (!auditData) {
      // return `Data not available for auditStatus: ${auditStatus}`;
      return false;
    }

    // Check for specific period (quarterly, yearly, etc.)
    if (periodType && auditData[periodType]) {
      // return `Data is available for periodType: ${periodType}, auditStatus: ${auditStatus}`;
      return true;
    } else {
      // return `Data not available for periodType: ${periodType}`;
      return false;
    }
  };

  const myData = availableData;

  const [result, setResult] = useState(false);
  useEffect(() => {
    setResult(
      checkDataAvailability({
        sheetType: sheetType,
        periodType: periodType,
        auditStatus: auditStatus,
      }),
    );
  }, [sheetType, periodType, companyId, auditStatus, currentTime]);

  const [modalActive, setModalActive] = useState(false);

  useEffect(() => {
    if (result === true) {
      setModalActive(true);
    }
  }, [result, periodType, sheetType, auditStatus, companyId, currentTime]);

  const handleNavigate = () => {
    navigate(`/dashboard/company/table/${companyId}`);
  };

  console.log("result", result);

  const generateOptions = (periodType) => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 25 }, (_, i) => currentYear - i); // Last 25 years in descending order

    if (periodType === "yearly") {
      return years.map((year) => ({ label: year.toString(), value: year }));
    }

    if (periodType === "halfYearly") {
      return years.flatMap((year) => [
        { label: `${year} - H2`, value: `${year} - H2` },
        { label: `${year} - H1`, value: `${year} - H1` },
      ]);
    }

    if (periodType === "quarterly") {
      return years.flatMap((year) => [
        { label: `${year} - Q4`, value: `${year} - Q4` },
        { label: `${year} - Q3`, value: `${year} - Q3` },
        { label: `${year} - Q2`, value: `${year} - Q2` },
        { label: `${year} - Q1`, value: `${year} - Q1` },
      ]);
    }

    if (periodType === "monthly") {
      const months = [
        "Dec",
        "Nov",
        "Oct",
        "Sep",
        "Aug",
        "Jul",
        "Jun",
        "May",
        "Apr",
        "Mar",
        "Feb",
        "Jan",
      ];
      return years.flatMap((year) =>
        months.map((month) => ({
          label: `${year} - ${month}`,
          value: `${year} - ${month}`,
        })),
      );
    }

    return [];
  };

  // const AddYearModal = ({ isOpen, onClose, onSave }) => {
  //   const [newYear, setNewYear] = useState("");

  //   const handleSave = () => {
  //     onSave(selectedOption1); // Pass the array of years
  //     setNewYear(""); // Clear the input
  //     onClose(); // Close the modal
  //   };

  //   return (
  //     isOpen && (
  //       <Modal
  //         id="add-year-modal"
  //         // title="Add Year"
  //         title={`Add ${
  //           periodType === "yearly"
  //             ? "Year"
  //             : periodType === "halfYearly"
  //             ? "Half Year"
  //             : periodType === "quarterly"
  //             ? "Quarter"
  //             : periodType === "monthly"
  //             ? "Month"
  //             : "Other"
  //         }`}
  //         isActive={isOpen}
  //         onClose={onClose}
  //         width="400px"
  //         scrollBar="visible" // Pass 'hidden' to hide overflow
  //       >
  //         <div className="p-0">
  //           <SelectDropdown
  //             data={generateOptions(periodType)}
  //             selectedValue={selectedOption1}
  //             onChange={(option) => {
  //               const sortedOptions = [...option].sort((a, b) => {
  //                 if (typeof a === "string" && typeof b === "string") {
  //                   return b.localeCompare(a); // Descending order for strings
  //                 }
  //                 return b - a; // Descending order for numbers
  //               });
  //               setSelectedOption1(sortedOptions);
  //             }}
  //             placeholder={`Select ${
  //               periodType === "yearly"
  //                 ? "Year"
  //                 : periodType === "halfYearly"
  //                 ? "Half Year"
  //                 : periodType === "quarterly"
  //                 ? "Quarter"
  //                 : periodType === "monthly"
  //                 ? "Month"
  //                 : "Other"
  //             }`}
  //             isClearable={true}
  //             multiSelect={true}
  //             onClear={() => setSelectedOption1([])} // Clear selected options
  //             onClose={false}
  //           />
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "flex-end",
  //               marginTop: "20px",
  //             }}
  //           >
  //             <CustomButton
  //               type="btn-primary"
  //               iconRequired={false}
  //               handleClick={handleSave}
  //             >
  //               {/* Add Year */}
  //               {`Add ${
  //                 periodType === "yearly"
  //                   ? "Year"
  //                   : periodType === "halfYearly"
  //                   ? "Half Year"
  //                   : periodType === "quarterly"
  //                   ? "Quarter"
  //                   : periodType === "monthly"
  //                   ? "Month"
  //                   : "Other"
  //               }`}
  //             </CustomButton>
  //           </div>
  //         </div>
  //       </Modal>
  //     )
  //   );
  // };

  // Handle clicks outside of the dropdown

  // const AddYearModal = ({ isOpen, onClose, onSave }) => {
  //   const [newYear, setNewYear] = useState("");
  //   const [selectedOption1, setSelectedOption1] = useState([]);

  //   const handleSave = () => {
  //     if (newYear.trim() === "" && selectedOption1.length === 0) {
  //       alert(
  //         "Please select a value from the dropdown or enter a custom name.",
  //       );
  //       return;
  //     }

  //     const dataToSave = newYear.trim() !== "" ? [newYear] : selectedOption1;
  //     onSave(dataToSave); // Pass the selected or custom value
  //     setNewYear(""); // Clear the input
  //     setSelectedOption1([]); // Clear selected options
  //     onClose(); // Close the modal
  //   };

  //   return (
  //     isOpen && (
  //       <Modal
  //         id="add-year-modal"
  //         title={`Add ${
  //           periodType === "yearly"
  //             ? "Year"
  //             : periodType === "halfYearly"
  //             ? "Half Year"
  //             : periodType === "quarterly"
  //             ? "Quarter"
  //             : periodType === "monthly"
  //             ? "Month"
  //             : "Custom Name"
  //         }`}
  //         isActive={isOpen}
  //         onClose={onClose}
  //         width="400px"
  //         scrollBar="visible"
  //       >
  //         <div className="p-0">
  //           <SelectDropdown
  //             data={generateOptions(periodType)}
  //             selectedValue={selectedOption1}
  //             onChange={(option) => {
  //               const sortedOptions = [...option].sort((a, b) => {
  //                 if (typeof a === "string" && typeof b === "string") {
  //                   return b.localeCompare(a); // Descending order for strings
  //                 }
  //                 return b - a; // Descending order for numbers
  //               });
  //               setSelectedOption1(sortedOptions);
  //               setNewYear(""); // Clear custom input if dropdown is used
  //             }}
  //             placeholder={`Select ${
  //               periodType === "yearly"
  //                 ? "Year"
  //                 : periodType === "halfYearly"
  //                 ? "Half Year"
  //                 : periodType === "quarterly"
  //                 ? "Quarter"
  //                 : periodType === "monthly"
  //                 ? "Month"
  //                 : "Other"
  //             }`}
  //             isClearable={true}
  //             multiSelect={true}
  //             onClear={() => setSelectedOption1([])} // Clear selected options
  //             onClose={false}
  //           />
  //           <div style={{ marginTop: "20px" }}>
  //             <input
  //               type="text"
  //               value={newYear}
  //               onChange={(e) => {
  //                 setNewYear(e.target.value);
  //                 setSelectedOption1([]); // Clear dropdown if custom input is used
  //               }}
  //               placeholder="Enter custom name"
  //               className="form-control"
  //             />
  //           </div>
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "flex-end",
  //               marginTop: "20px",
  //             }}
  //           >
  //             <CustomButton
  //               type="btn-primary"
  //               iconRequired={false}
  //               handleClick={handleSave}
  //             >
  //               {`Add ${
  //                 periodType === "yearly"
  //                   ? "Year"
  //                   : periodType === "halfYearly"
  //                   ? "Half Year"
  //                   : periodType === "quarterly"
  //                   ? "Quarter"
  //                   : periodType === "monthly"
  //                   ? "Month"
  //                   : "Custom Name"
  //               }`}
  //             </CustomButton>
  //           </div>
  //         </div>
  //       </Modal>
  //     )
  //   );
  // };

  const AddYearModal = ({ isOpen, onClose, onSave }) => {
    const [newYear, setNewYear] = useState(""); // For custom name input
    const [selectedOptions, setSelectedOptions] = useState([]); // For dropdown options

    const handleSave = () => {
      if (newYear.trim() === "" && selectedOptions.length === 0) {
        alert("Please select at least one value or enter a custom name.");
        return;
      }

      const combinedData = [...selectedOptions];
      if (newYear.trim() !== "") {
        combinedData.push(newYear.trim()); // Add custom name to the combined array
      }

      onSave(combinedData); // Pass the combined data to the parent component
      setNewYear(""); // Clear the input field
      setSelectedOptions([]); // Clear selected options
      onClose(); // Close the modal
    };

    return (
      isOpen && (
        <Modal
          id="add-year-modal"
          title={`Add ${
            periodType === "yearly"
              ? "Year"
              : periodType === "halfYearly"
              ? "Half Year"
              : periodType === "quarterly"
              ? "Quarter"
              : periodType === "monthly"
              ? "Month"
              : "Custom Name"
          }`}
          isActive={isOpen}
          onClose={onClose}
          width="400px"
          scrollBar="visible"
        >
          <div className="p-0">
            <SelectDropdown
              data={generateOptions(periodType)}
              selectedValue={selectedOptions}
              onChange={(options) => {
                const sortedOptions = [...options].sort((a, b) => {
                  if (typeof a === "string" && typeof b === "string") {
                    return b.localeCompare(a); // Descending order for strings
                  }
                  return b - a; // Descending order for numbers
                });
                setSelectedOptions(sortedOptions);
              }}
              placeholder={`Select ${
                periodType === "yearly"
                  ? "Year"
                  : periodType === "halfYearly"
                  ? "Half Year"
                  : periodType === "quarterly"
                  ? "Quarter"
                  : periodType === "monthly"
                  ? "Month"
                  : "Other"
              }`}
              isClearable={true}
              multiSelect={true}
              onClear={() => setSelectedOptions([])} // Clear selected options
              onClose={false}
            />
            <div style={{ marginTop: "20px" }}>
              <input
                type="text"
                value={newYear}
                onChange={(e) => setNewYear(e.target.value)}
                placeholder="Enter custom name (e.g., YTD(Jan-Feb))"
                className="form-control"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <CustomButton
                type="btn-primary"
                iconRequired={false}
                handleClick={handleSave}
              >
                {`Add ${
                  periodType === "yearly"
                    ? "Year"
                    : periodType === "halfYearly"
                    ? "Half Year"
                    : periodType === "quarterly"
                    ? "Quarter"
                    : periodType === "monthly"
                    ? "Month"
                    : "Custom Name"
                }`}
              </CustomButton>
            </div>
          </div>
        </Modal>
      )
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  console.log("data---->", data);

  // const [years, setYears] = useState([]);

  const handleValueChange = (year, category, subcategory, value) => {
    setData((prevData) => {
      if (subcategory === null) {
        return {
          ...prevData,
          [year]: {
            ...prevData[year],
            [category]: value,
          },
        };
      }
      return {
        ...prevData,
        [year]: {
          ...prevData[year],
          [category]: {
            ...prevData[year][category],
            [subcategory]: value,
          },
        },
      };
    });
  };

  const [isSubcategoryModalOpen, setIsSubcategoryModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const [doopData, setDoopData] = useState({});
  const handleSubcategoryAdd = (year, category, selectedSubcategories) => {
    console.log("year, category", year, category, selectedSubcategories);

    setDoopData((prev) => ({
      ...prev, // spread the previous state
      [category]: selectedSubcategories, // update the category with selected subcategories
    }));

    // Pass prevData as an argument in the setter function
    setData((prevData) => {
      // Clone the previous data to avoid direct mutation
      const updatedData = { ...prevData };

      // Get the existing subcategories for the specific year and category
      const previousSubcategories = Object.keys(
        updatedData[year]?.[category] || {},
      );
      console.log("Previous subcategories:", previousSubcategories);

      // Determine which subcategories to add
      const subcategoriesToAdd = selectedSubcategories.filter(
        (subcategory) => !previousSubcategories.includes(subcategory),
      );
      console.log("subcategoriesToAdd", subcategoriesToAdd);

      // Determine which subcategories to remove
      const subcategoriesToRemove = previousSubcategories.filter(
        (subcategory) => !selectedSubcategories.includes(subcategory),
      );
      console.log("subcategoriesToRemove", subcategoriesToRemove);

      // Add new subcategories
      subcategoriesToAdd.forEach((newSubcategory) => {
        if (newSubcategory === "Other") {
          // Open the modal for "Other"
          setSelectedCategory(category);
          setSelectedYear(year);
          setIsSubcategoryModalOpen(true);
        } else {
          // Update the selected subcategory within the data
          if (!updatedData[year]) {
            updatedData[year] = {};
          }

          if (!updatedData[year][category]) {
            updatedData[year][category] = {};
          }

          // Add the new subcategory to the category under the specified year
          updatedData[year][category][newSubcategory] = ""; // Set initial value (can be adjusted)
        }
      });

      // Remove unselected subcategories
      subcategoriesToRemove.forEach((subcategoryToRemove) => {
        if (updatedData[year]?.[category]) {
          delete updatedData[year][category][subcategoryToRemove]; // Remove the subcategory
          console.log(`Removed subcategory: ${subcategoryToRemove}`);
        }
      });

      // Log the updated state for tracking
      console.log("Updated data:", updatedData);

      // Return the updated state
      return updatedData;
    });

    // Update selected options in state (to keep track of selected subcategories)
    setSelectedOption(selectedSubcategories);
  };

  console.log("DoopData", doopData);

  const handleDeleteRow = (year, category, subcategory) => {
    const getSubCategories = doopData[category].filter(
      (item) => item !== subcategory,
    );

    setDoopData((prev) => ({
      ...prev, // spread the previous state
      [category]: getSubCategories, // update the category with the filtered subcategories
    }));
    setData((prevData) => {
      const updatedCategory = { ...prevData[year][category] };
      delete updatedCategory[subcategory]; // Delete only the subcategory
      return {
        ...prevData,
        [year]: {
          ...prevData[year],
          [category]: updatedCategory,
        },
      };
    });
  };

  const handleAddYear = (newYears) => {
    const uniqueYears = newYears.filter((year) => !years.includes(year)); // Filter out duplicates
    if (uniqueYears.length > 0) {
      setYears((prevYears) => [...prevYears, ...uniqueYears]);
      setData((prevData) => ({
        ...prevData,
        ...Object.fromEntries(
          uniqueYears.map((year) => [
            year,
            Object.fromEntries(categories.map((category) => [category, ""])),
          ]),
        ),
      }));
    }
  };

  const handleDeleteColumn = (year) => {
    setYears((prevYears) => prevYears.filter((yr) => yr !== year));
    setData((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[year];
      return updatedData;
    });
  };

  const handleAddCategory = (newCategory) => {
    if (!categories.includes(newCategory)) {
      setCategories((prevCategories) => [...prevCategories, newCategory]);
      setData((prevData) => ({
        ...prevData,
        [years[0]]: {
          ...prevData[years[0]],
          [newCategory]: "", // Initialize with default value
        },
      }));
    }
  };
  const [isView, setIsView] = useState(false);

  const handleToggleView = () => {
    setIsView((prevState) => !prevState); // Toggle between true and false
  };
  const handleSubmit = async () => {
    if (sheetType === "balance_sheet") {
      const isValid = validateBalanceSheet(data);

      if (!isValid) {
        alert("Balance sheet is invalid. Please check the data.");
        return;
      }
    }

    if (data.hasOwnProperty("undefined")) {
      delete data["undefined"];
    }

    console.log("daatatat---->", data);
    setIsLoading(true); // Set loading to true when API call starts

    const payload = {
      sheetType: sheetType,
      periodType: periodType,
      auditStatus: auditStatus,
      units: exunit,
      currency: excurrency,
      data: data,
    };

    console.log("payload---->", payload);

    try {
      const res = await postData({
        endpoint: "extract",
        params: {
          companyId: companyId,
        },
        token: cookies.t,
        data: payload,
      });

      if (res?.data?.status) {
        toast.success("Table Created Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      } else {
        toast.error(res?.data?.message || "Failed to Create financial table", {
          style: errorStyles,
          duration: 1000,
        });
      }
    } catch (error) {
      console.error("Error adding financial data:", error);

      toast.error(error?.error?.response?.data?.error, {
        duration: 1000,
        style: errorStyles,
      });
    } finally {
      setIsLoading(false); // Set loading to false when API call completes
    }
    setIsView(true);
  };

  const updateCategoryTotal = (updateKey, lineItemKey, year, data) => {
    // Ensure the year exists in the data object
    if (!data[year]) {
      data[year] = {};
    }

    // Sum up all the values under the specified lineItemKey
    const total = Object.values(data[year]?.[lineItemKey] || {}).reduce(
      (acc, value) => acc + (parseFloat(value) || 0),
      0,
    );

    // Update the data object with the calculated total
    data[year][updateKey] = total;

    // Return the calculated total
    return total || "";
  };

  const calculateTotalAssets = (year, data) => {
    // Ensure "Total Current Assets" and "Total Non-Current Assets" are calculated
    const totalCurrentAssets = updateCategoryTotal(
      "Total Current Assets",
      "CURRENT ASSETS",
      year,
      data,
    );

    const totalNonCurrentAssets = updateCategoryTotal(
      "Total Non-Current Assets",
      "NON-CURRENT ASSETS",
      year,
      data,
    );

    // Calculate the sum of both
    const totalAssets =
      (totalCurrentAssets || 0) + (totalNonCurrentAssets || 0);

    // Update the data object with the calculated total
    if (!data[year]) {
      data[year] = {};
    }
    data[year]["TOTAL ASSETS"] = totalAssets;

    // Return the calculated total
    return totalAssets || "";
  };

  const calculateEbitda = (year, data) => {
    // Ensure "Total Current Assets" and "Total Non-Current Assets" are calculated
    const grossProfit = calculateGrossProfit(year, data);

    const totalOperatingExpenses = updateCategoryTotal(
      "Total Operating Expenses",
      "Operating Expenses/Income",
      year,
      data,
    );

    // Calculate the sum of both
    const totalAssets = (grossProfit || 0) - (totalOperatingExpenses || 0);

    // Update the data object with the calculated total
    if (!data[year]) {
      data[year] = {};
    }
    data[year]["EBITDA"] = totalAssets;

    // Return the calculated total
    return totalAssets || "";
  };
  const calculateEbit = (year, data) => {
    // Ensure EBITDA is calculated
    const ebitda = calculateEbitda(year, data);

    // Calculate the sum of "Depreciation and Amortization" line items manually
    const depreciationAndAmortization = Object.values(
      data[year]?.["Depreciation and Amortization"] || {},
    ).reduce((acc, value) => acc + (parseFloat(value) || 0), 0);

    console.log("depreciationAndAmortization", depreciationAndAmortization);

    // Calculate EBIT by subtracting Depreciation and Amortization from EBITDA
    const ebit = (ebitda || 0) - (depreciationAndAmortization || 0);

    // Update the data object with the calculated EBIT
    if (!data[year]) {
      data[year] = {};
    }
    data[year]["EBIT"] = ebit;

    // Return the calculated EBIT
    return ebit || "";
  };

  const calculateEbt = (year, data) => {
    // Ensure "Total Current Assets" and "Total Non-Current Assets" are calculated

    const EBIT = calculateEbit(year, data);
    const TotalFinanceExpenses = updateCategoryTotal(
      "Total Finance Expenses/Income",
      "Finance Expenses/Income",
      year,
      data,
    );

    const TotalNonOperatingExpenses = updateCategoryTotal(
      "Total Non-Operating Expenses",
      "Other Non-Operating Expenses",
      year,
      data,
    );

    // Calculate the sum of both
    const ebt =
      (EBIT || 0) -
      ((TotalFinanceExpenses || 0) + (TotalNonOperatingExpenses || 0));

    // Update the data object with the calculated total
    if (!data[year]) {
      data[year] = {};
    }
    data[year]["EBT"] = ebt;

    // Return the calculated total
    return ebt || "";
  };

  const calculateNetIncome = (year, data) => {
    // Ensure EBITDA is calculated
    const EBT = calculateEbt(year, data);

    // Calculate the sum of "Depreciation and Amortization" line items manually
    const TaxExpenses = Object.values(
      data[year]?.["Tax Expenses"] || {},
    ).reduce((acc, value) => acc + (parseFloat(value) || 0), 0);

    console.log("TaxExpenses", TaxExpenses);

    // Calculate EBIT by subtracting Depreciation and Amortization from EBITDA
    const netIncome = (EBT || 0) - (TaxExpenses || 0);

    // Update the data object with the calculated EBIT
    if (!data[year]) {
      data[year] = {};
    }
    data[year]["Net Income/ Profit/ (Loss)"] = netIncome;

    // Return the calculated EBIT
    return netIncome || "";
  };

  const calculateGrossProfit = (year, data) => {
    // Ensure "Total Current Assets" and "Total Non-Current Assets" are calculated
    const totalCurrentAssets = updateCategoryTotal(
      "Total Sales/Revenue/Turnover",
      "Sales/Revenue/Turnover",
      year,
      data,
    );

    const totalNonCurrentAssets = updateCategoryTotal(
      "Total COGS",
      "COGS/Cost of goods sold/Cost of Sales",
      year,
      data,
    );

    // Calculate the sum of both
    const totalAssets =
      (totalCurrentAssets || 0) - (totalNonCurrentAssets || 0);

    // Update the data object with the calculated total
    if (!data[year]) {
      data[year] = {};
    }
    data[year]["Gross Profit"] = totalAssets;

    // Return the calculated total
    return totalAssets || "";
  };

  console.log("data--->", data);

  const calculateTotalLiabilities = (year, data) => {
    // Ensure "Total Current Assets" and "Total Non-Current Assets" are calculated
    const totalCurrentAssets = updateCategoryTotal(
      "Total Current Liabilities",
      "CURRENT LIABILITIES",
      year,
      data,
    );

    const totalNonCurrentAssets = updateCategoryTotal(
      "Total Non-Current Liabilities",
      "NON-CURRENT LIABILITIES",
      year,
      data,
    );

    // Calculate the sum of both
    const totalAssets =
      (totalCurrentAssets || 0) + (totalNonCurrentAssets || 0);

    // Update the data object with the calculated total
    if (!data[year]) {
      data[year] = {};
    }
    data[year]["TOTAL LIABILITIES"] = totalAssets;

    // Return the calculated total
    return totalAssets || "";
  };

  const calculateTotalShareHolderandLiabilities = (year, data) => {
    // Ensure "Total Current Assets" and "Total Non-Current Assets" are calculated
    const totalCurrentAssets = updateCategoryTotal(
      "Total Current Liabilities",
      "CURRENT LIABILITIES",
      year,
      data,
    );

    const totalNonCurrentAssets = updateCategoryTotal(
      "Total Non-Current Liabilities",
      "NON-CURRENT LIABILITIES",
      year,
      data,
    );

    const totalEquity = updateCategoryTotal(
      "Total Shareholder's Equity",
      "SHAREHOLDERS EQUITY",
      year,
      data,
    );

    // Calculate the sum of both
    const totalAssets =
      (totalCurrentAssets || 0) +
      (totalNonCurrentAssets || 0) +
      (totalEquity || 0);

    // Update the data object with the calculated total
    if (!data[year]) {
      data[year] = {};
    }
    data[year]["TOTAL SHAREHOLDER'S EQUITY AND LIABILITIES"] = totalAssets;

    // Return the calculated total
    return totalAssets || "";
  };

  const validateBalanceSheet = (editedData) => {
    // Loop through all the years in editedData
    for (const year in editedData) {
      if (editedData.hasOwnProperty(year)) {
        const yearData = editedData[year];

        // Extract categories for assets and liabilities
        const currentAssets = yearData?.["CURRENT ASSETS"] || [];
        const nonCurrentAssets = yearData?.["NON-CURRENT ASSETS"] || [];
        const currentLiabilities = yearData?.["CURRENT LIABILITIES"] || [];
        const nonCurrentLiabilities =
          yearData?.["NON-CURRENT LIABILITIES"] || [];
        const shareholderEquity = yearData?.["SHAREHOLDERS EQUITY"] || [];

        // Calculate totals using proper sum functions
        const totalAssets =
          sumAssets(currentAssets) + sumAssets(nonCurrentAssets);
        const totalLiabilities =
          sumLiabilities(currentLiabilities) +
          sumLiabilities(nonCurrentLiabilities);
        const totalEquity = sumEquity(shareholderEquity);

        console.log(
          `Year ${year} -> Total Assets: ${totalAssets}, Total Liabilities: ${totalLiabilities}, Total Equity: ${totalEquity}`,
        );

        // Validate balance sheet for the current year
        if (
          totalAssets?.toFixed(2) !==
          (totalLiabilities + totalEquity)?.toFixed(2)
        ) {
          console.log(`Balance sheet validation failed for year ${year}`);
          return false; // Return false if any year is invalid
        }
      }
    }

    // Return true if all years are valid
    return true;
  };

  // Helper functions to sum the respective categories
  const sumAssets = (assets) => {
    return Object.values(assets || {}).reduce(
      (acc, asset) => acc + (parseFloat(asset) || 0),
      0,
    );
  };

  const sumLiabilities = (liabilities) => {
    return Object.values(liabilities || {}).reduce(
      (acc, liability) => acc + (parseFloat(liability) || 0),
      0,
    );
  };

  const sumEquity = (equity) => {
    return Object.values(equity || {}).reduce(
      (acc, equityItem) => acc + (parseFloat(equityItem) || 0),
      0,
    );
  };

  return (
    <>
      {modalActive && result && (
        <Modal
          id="myModal"
          title="Message"
          isActive={modalActive && result === true} // Show the modal when true
          onClose={() => {
            setModalActive(false);
          }} // Close the modal
          width="500px"
          scrollBar="auto"
          maxHeight="500px"
        >
          <p>
            {/* Financial data has already been added for the{" "} */}
            The financial data for <strong>{availableData?.name}</strong>{" "}
            Company is already available, with the following details:
            <ul></ul>
            <ul>
              <li>
                Audit Status:{" "}
                <strong>
                  {auditStatus?.charAt(0).toUpperCase() + auditStatus?.slice(1)}
                </strong>
              </li>
              <li>
                Period Type:{" "}
                <strong>
                  {periodType?.charAt(0).toUpperCase() + periodType?.slice(1)}
                </strong>
              </li>
              <li>
                Sheet Type:{" "}
                <strong>
                  {sheetType === "pl" ? "Profit and Loss" : "Balance Sheet"}
                </strong>
              </li>
            </ul>
            You may view the existing data, make updates, or add additional{" "}
            {periodType?.charAt(0).toUpperCase() + periodType?.slice(1)} data as
            required.
          </p>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <CustomButton
              type="btn-primary"
              iconRequired={false}
              handleClick={() => {
                setModalActive(false);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="btn-primary"
              iconRequired={false}
              handleClick={() => handleNavigate()}
            >
              Edit Data
            </CustomButton>
          </div>
        </Modal>
      )}
      {result === false && (
        <div className="table-container">
          <div style={{ fontSize: "18px" }}>
            <i>
              <b>*Instruction : </b>
              Please ensure to{" "}
              <b>
                add{" "}
                {periodType === "yearly"
                  ? "Year"
                  : periodType === "halfYearly"
                  ? "Half Year"
                  : periodType === "quarterly"
                  ? "Quarter"
                  : periodType === "monthly"
                  ? "Month"
                  : "Other"}{" "}
              </b>
              first, before proceeding to add any line items.
            </i>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontSize: "28px", fontWeight: "bold" }}>
              Table Builder
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "20px",
                gap: "20px",
              }}
            >
              {!isView && (
                <CustomButton
                  type="btn-primary"
                  iconRequired={true}
                  handleClick={() => setIsYearModalOpen(true)}
                >
                  {/* Add Year */}
                  {`Add ${
                    periodType === "yearly"
                      ? "Year"
                      : periodType === "halfYearly"
                      ? "Half Year"
                      : periodType === "quarterly"
                      ? "Quarter"
                      : periodType === "monthly"
                      ? "Month"
                      : "Other"
                  }`}
                </CustomButton>
              )}
              {console.log("periodType", periodType)}

              {isView && (
                <CustomButton
                  type="btn-primary"
                  iconRequired={false}
                  handleClick={handleToggleView}
                >
                  Edit
                </CustomButton>
              )}
            </div>
          </div>

          <div style={{ overflowX: "scroll" }}>
            <table border="1" cellPadding="10">
              <thead>
                <tr style={{ background: "#f2f2f2" }}>
                  <th>
                    {sheetType === "pl" ? "Profit and Loss" : "Balance Sheet"}
                    {excurrency || exunit
                      ? ` (${excurrency} in ${exunit})`
                      : ""}
                  </th>

                  {years.map((year) => (
                    <th key={year} style={{ width: "200px" }}>
                      <span></span>
                      {year}
                      {!isView && (
                        <button
                          onClick={() => handleDeleteColumn(year)}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            color: "red",
                            fontSize: "1.2em",
                          }}
                        >
                          <Delete />
                        </button>
                      )}
                    </th>
                  ))}

                  {!isView && <th style={{ width: "80px" }}>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {categories.map((category) => (
                  <React.Fragment key={category}>
                    <tr>
                      <td>
                        <div
                          style={{
                            alignItems: "center",
                          }}
                        >
                          <strong>
                            {/* {category === "Total Sales/Revenue/Turnover"
                              ? doopData?.["Sales/Revenue/Turnover"]?.length
                                ? `Total ${doopData[
                                    "Sales/Revenue/Turnover"
                                  ]?.join("/")}`
                                : "Total Sales/Revenue/Turnover"
                              : category} */}
                            {category}
                          </strong>
                          {!isView && subcategories[category] && (
                            <div
                              ref={dropdownRef}
                              style={{ width: "250px", marginTop: "10px" }}
                            >
                              <MultipleUserSelectComponent
                                title="Add Line Item"
                                subtitle="Subcategories"
                                data={subcategories[category].map((subcat) => ({
                                  name: subcat,
                                  emp_id: subcat,
                                }))}
                                onSelect={
                                  (selectedOptions) =>
                                    handleSubcategoryAdd(
                                      years[0],
                                      category,
                                      selectedOptions,
                                    ) // Pass array of selected subcategories
                                }
                                setIsSubcategoryModalOpen={
                                  setIsSubcategoryModalOpen
                                }
                                // selectedEmployeeIds={Object.keys(
                                //   Object.keys(data?.[years[0]]),
                                // )}
                                selectedYear={years[0]}
                                setSelectedYear={setSelectedYear}
                                selectedCategory={category}
                                setSelectedCategory={setSelectedCategory}
                                selectedEmployeeIds={doopData[category]}
                              />
                            </div>
                          )}
                        </div>
                      </td>
                      {/* {years.map((year) => (
                        <td key={`${year}-${category}`}>
                          {[
                            "SHAREHOLDERS EQUITY",
                            "NON-CURRENT LIABILITIES",
                            "CURRENT LIABILITIES",
                            "NON-CURRENT ASSETS",
                            "CURRENT ASSETS",
                            "Sales/Revenue/Turnover",
                            "Operating Expenses/Income",
                            "Depreciation and Amortization",
                            "Finance Expenses/Income",
                            "Other Non-Operating Expenses",
                            "Tax Expenses",
                          ].includes(category) ? (
                            (() => {
                              // Ensure an empty object is pushed for these categories
                              if (!data[year]?.[category]) {
                                data[year] = {
                                  ...data[year],
                                  [category]: {}, // Push an empty object
                                };
                              }
                              return null; // Don't render anything for these categories
                            })()
                          ) : !subcategories[category] &&
                            category !== "ASSETS" &&
                            category !== "LIABILITIES" ? (
                            isView ? (
                              // Show value when isView is true
                              <>
                                <span>
                                  {formatValue(
                                    data[year]?.[category],
                                    exunit,
                                    excurrency,
                                  ) || "-"}
                                </span>
                              </>
                            ) : (
                              // Show input when isView is false
                              <input
                                type="text"
                                value={data[year]?.[category] || ""}
                                onChange={(e) =>
                                  handleValueChange(
                                    year,
                                    category,
                                    null,
                                    e.target.value,
                                  )
                                }
                                onKeyDown={(e) => {
                                  const allowedKeys = [
                                    "Backspace",
                                    "Tab",
                                    "ArrowLeft",
                                    "ArrowRight",
                                    "Delete",
                                    "Enter",
                                    "-",
                                    ".",
                                    ",",
                                  ];
                                  if (
                                    !allowedKeys.includes(e.key) && // Allow control keys
                                    !/^\d$/.test(e.key) // Allow digits
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                className="table-input-field" // Add your class here
                              />
                            )
                          ) : null}
                        </td>
                      ))} */}
                      {years.map((year) => (
                        <td key={`${year}-${category}`}>
                          {[
                            "SHAREHOLDERS EQUITY",
                            "NON-CURRENT LIABILITIES",
                            "CURRENT LIABILITIES",
                            "NON-CURRENT ASSETS",
                            "CURRENT ASSETS",
                            "Sales/Revenue/Turnover",
                            "Operating Expenses/Income",
                            "Depreciation and Amortization",
                            "Finance Expenses/Income",
                            "Other Non-Operating Expenses",
                            "Tax Expenses",
                          ].includes(category) ? (
                            (() => {
                              // Ensure an empty object is pushed for these categories
                              if (!data[year]?.[category]) {
                                data[year] = {
                                  ...data[year],
                                  [category]: {}, // Push an empty object
                                };
                              }
                              return null; // Don't render anything for these categories
                            })()
                          ) : // : category === "Total Current Assets" ? (
                          //   <>
                          //     <span>
                          //       {Object.values(
                          //         data[year]?.["CURRENT ASSETS"] || {},
                          //       ).reduce(
                          //         (acc, value) =>
                          //           acc + (parseFloat(value) || 0),
                          //         0,
                          //       ) || "-"}
                          //       {(() => {
                          //         // Update the data object with the calculated sum for Total Current Assets
                          //         const totalCurrentAssets = Object.values(
                          //           data[year]?.["CURRENT ASSETS"] || {},
                          //         ).reduce(
                          //           (acc, value) =>
                          //             acc + (parseFloat(value) || 0),
                          //           0,
                          //         );

                          //         // Ensure data is updated with the calculated total
                          //         if (!data[year]) {
                          //           data[year] = {};
                          //         }
                          //         data[year]["Total Current Assets"] =
                          //           totalCurrentAssets;
                          //       })()}
                          //     </span>
                          //   </>
                          // )
                          category === "Total Current Assets" ? (
                            <span>
                              {(
                                updateCategoryTotal(
                                  "Total Current Assets",
                                  "CURRENT ASSETS",
                                  year,
                                  data,
                                ) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category === "Total Non-Current Assets" ? (
                            <span>
                              {(
                                updateCategoryTotal(
                                  "Total Non-Current Assets",
                                  "NON-CURRENT ASSETS",
                                  year,
                                  data,
                                ) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category === "Total Current Liabilities" ? (
                            <span>
                              {(
                                updateCategoryTotal(
                                  "Total Current Liabilities",
                                  "CURRENT LIABILITIES",
                                  year,
                                  data,
                                ) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category === "Total Non-Current Liabilities" ? (
                            <span>
                              {(
                                updateCategoryTotal(
                                  "Total Non-Current Liabilities",
                                  "NON-CURRENT LIABILITIES",
                                  year,
                                  data,
                                ) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category === "Total Shareholder's Equity" ? (
                            <span>
                              {(
                                updateCategoryTotal(
                                  "Total Shareholder's Equity",
                                  "SHAREHOLDERS EQUITY",
                                  year,
                                  data,
                                ) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category === "TOTAL ASSETS" ? (
                            <span>
                              {(calculateTotalAssets(year, data) || 0).toFixed(
                                2,
                              )}
                            </span>
                          ) : category === "TOTAL LIABILITIES" ? (
                            <span>
                              {(
                                calculateTotalLiabilities(year, data) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category ===
                            "TOTAL SHAREHOLDER'S EQUITY AND LIABILITIES" ? (
                            <span>
                              {(
                                calculateTotalShareHolderandLiabilities(
                                  year,
                                  data,
                                ) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category === "Total Sales/Revenue/Turnover" ? (
                            <span>
                              {parseFloat(
                                updateCategoryTotal(
                                  "Total Sales/Revenue/Turnover",
                                  "Sales/Revenue/Turnover",
                                  year,
                                  data,
                                ) || 0,
                              ).toFixed(2)}
                            </span>
                          ) : category === "Total COGS" ? (
                            <span>
                              {(
                                parseFloat(
                                  updateCategoryTotal(
                                    "Total COGS",
                                    "COGS/Cost of goods sold/Cost of Sales",
                                    year,
                                    data,
                                  ),
                                ) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category === "Gross Profit" ? (
                            <span>
                              {(
                                parseFloat(calculateGrossProfit(year, data)) ||
                                0
                              ).toFixed(2)}
                            </span>
                          ) : category === "Total Operating Expenses" ? (
                            <span>
                              {(
                                parseFloat(
                                  updateCategoryTotal(
                                    "Total Operating Expenses",
                                    "Operating Expenses/Income",
                                    year,
                                    data,
                                  ),
                                ) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category === "EBITDA" ? (
                            <span>
                              {(
                                parseFloat(calculateEbitda(year, data)) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category === "EBIT" ? (
                            <span>
                              {(
                                parseFloat(calculateEbit(year, data)) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category === "Total Finance Expenses/Income" ? (
                            <span>
                              {(
                                parseFloat(
                                  updateCategoryTotal(
                                    "Total Finance Expenses/Income",
                                    "Finance Expenses/Income",
                                    year,
                                    data,
                                  ),
                                ) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category === "Total Non-Operating Expenses" ? (
                            <span>
                              {/* {updateCategoryTotal(
                                "Total Non-Operating Expenses",
                                "Other Non-Operating Expenses",
                                year,
                                data,
                              )} */}
                              {parseFloat(
                                updateCategoryTotal(
                                  "Total Non-Operating Expenses",
                                  "Other Non-Operating Expenses",
                                  year,
                                  data,
                                ) || 0,
                              ).toFixed(2)}
                            </span>
                          ) : category === "EBT" ? (
                            <span>
                              {(
                                parseFloat(calculateEbt(year, data)) || 0
                              ).toFixed(2)}
                            </span>
                          ) : category === "Net Income/ Profit/ (Loss)" ? (
                            <span>
                              {(
                                parseFloat(calculateNetIncome(year, data)) || 0
                              ).toFixed(2)}
                            </span>
                          ) : !subcategories[category] &&
                            category !== "ASSETS" &&
                            category !== "LIABILITIES" ? (
                            isView ? (
                              // Show value when isView is true
                              <>
                                <span>
                                  {formatValue(
                                    data[year]?.[category],
                                    exunit,
                                    excurrency,
                                  ) || "-"}
                                </span>
                              </>
                            ) : (
                              // Show input when isView is false
                              <input
                                type="text"
                                value={data[year]?.[category] || ""}
                                onChange={(e) =>
                                  handleValueChange(
                                    year,
                                    category,
                                    null,
                                    e.target.value,
                                  )
                                }
                                // onKeyDown={(e) => {
                                //   const allowedKeys = [
                                //     "Backspace",
                                //     "Tab",
                                //     "ArrowLeft",
                                //     "ArrowRight",
                                //     "Delete",
                                //     "Enter",
                                //     "-",
                                //     ".",
                                //     ",",
                                //   ];
                                //   if (
                                //     !allowedKeys.includes(e.key) && // Allow control keys
                                //     !/^\d$/.test(e.key) // Allow digits
                                //   ) {
                                //     e.preventDefault();
                                //   }
                                // }}
                                onKeyDown={(e) => {
                                  const allowedKeys = [
                                    "Backspace",
                                    "Tab",
                                    "ArrowLeft",
                                    "ArrowRight",
                                    "Delete",
                                    "Enter",
                                    "-",
                                    ".",
                                  ];

                                  // Allow only digits and control keys
                                  if (
                                    !allowedKeys.includes(e.key) &&
                                    !/^\d$/.test(e.key)
                                  ) {
                                    e.preventDefault();
                                    return;
                                  }

                                  const inputValue = e.target.value;

                                  // Allow only one negative sign at the beginning
                                  if (
                                    e.key === "-" &&
                                    inputValue.includes("-")
                                  ) {
                                    e.preventDefault();
                                    return;
                                  }

                                  // Allow only one dot (.) in the input
                                  if (
                                    e.key === "." &&
                                    inputValue.includes(".")
                                  ) {
                                    e.preventDefault();
                                    return;
                                  }

                                  // Prevent commas
                                  if (e.key === ",") {
                                    e.preventDefault();
                                  }
                                }}
                                className="table-input-field" // Add your class here
                              />
                            )
                          ) : null}
                        </td>
                      ))}

                      {!isView && <td></td>}
                    </tr>
                    {subcategories?.[category] &&
                      Object.keys(data[years[0]]?.[category] || {}).map(
                        (subcategory) => (
                          <tr key={subcategory}>
                            <td
                              style={{
                                paddingLeft: "30px",
                              }}
                            >
                              {subcategory}
                            </td>
                            {years.map((year) => (
                              <td key={`${year}-${category}-${subcategory}`}>
                                {!isView ? (
                                  <input
                                    type="text"
                                    value={
                                      data[year]?.[category]?.[subcategory] ||
                                      ""
                                    }
                                    onChange={(e) =>
                                      handleValueChange(
                                        year,
                                        category,
                                        subcategory,
                                        e.target.value,
                                      )
                                    }
                                    // onKeyDown={(e) => {
                                    //   const allowedKeys = [
                                    //     "Backspace",
                                    //     "Tab",
                                    //     "ArrowLeft",
                                    //     "ArrowRight",
                                    //     "Delete",
                                    //     "Enter",
                                    //     "-",
                                    //     ".",
                                    //     ",",
                                    //   ];
                                    //   if (
                                    //     !allowedKeys.includes(e.key) && // Allow control keys
                                    //     !/^\d$/.test(e.key) // Allow digits
                                    //   ) {
                                    //     e.preventDefault();
                                    //   }
                                    // }}
                                    onKeyDown={(e) => {
                                      const allowedKeys = [
                                        "Backspace",
                                        "Tab",
                                        "ArrowLeft",
                                        "ArrowRight",
                                        "Delete",
                                        "Enter",
                                        "-",
                                        ".",
                                      ];

                                      // Allow only digits and control keys
                                      if (
                                        !allowedKeys.includes(e.key) &&
                                        !/^\d$/.test(e.key)
                                      ) {
                                        e.preventDefault();
                                        return;
                                      }

                                      const inputValue = e.target.value;

                                      // Allow only one negative sign at the beginning
                                      if (
                                        e.key === "-" &&
                                        inputValue.includes("-")
                                      ) {
                                        e.preventDefault();
                                        return;
                                      }

                                      // Allow only one dot (.) in the input
                                      if (
                                        e.key === "." &&
                                        inputValue.includes(".")
                                      ) {
                                        e.preventDefault();
                                        return;
                                      }

                                      // Prevent commas
                                      if (e.key === ",") {
                                        e.preventDefault();
                                      }
                                    }}
                                    className="table-input-field" // Add your class here
                                  />
                                ) : (
                                  <span>
                                    {formatValue(
                                      data[year]?.[category]?.[subcategory],
                                      exunit,
                                      excurrency,
                                    ) || "-"}
                                  </span>
                                )}
                              </td>
                            ))}
                            {!isView && (
                              <td>
                                <button
                                  onClick={() =>
                                    handleDeleteRow(
                                      years[0],
                                      category,
                                      subcategory,
                                    )
                                  }
                                  style={{
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                    color: "red",
                                    fontSize: "1.2em",
                                  }}
                                >
                                  <Delete />
                                </button>
                              </td>
                            )}
                          </tr>
                        ),
                      )}
                  </React.Fragment>
                ))}
                {isCategoryModalOpen && (
                  <AddCategoryModal
                    isOpen={isCategoryModalOpen}
                    onClose={() => setIsCategoryModalOpen(false)}
                    onSave={handleAddCategory}
                  />
                )}
                {isYearModalOpen && (
                  <AddYearModal
                    isOpen={isYearModalOpen}
                    onClose={() => setIsYearModalOpen(false)}
                    onSave={handleAddYear}
                  />
                )}
                {isSubcategoryModalOpen && (
                  <AddSubcategoryModal
                    isOpen={isSubcategoryModalOpen}
                    onClose={() => setIsSubcategoryModalOpen(false)}
                    onSave={(year, category, newSubcategory) => {
                      // Update main data
                      setData((prevData) => ({
                        ...prevData,
                        [year]: {
                          ...prevData[year],
                          [category]: {
                            ...prevData[year]?.[category],
                            [newSubcategory]: "",
                          },
                        },
                      }));

                      // Update doopData
                      setDoopData((prevDoopData) => {
                        // Ensure the category exists in doopData and is an array
                        if (Array.isArray(prevDoopData[category])) {
                          // Add newSubcategory if it doesn't already exist
                          if (
                            !prevDoopData[category].includes(newSubcategory)
                          ) {
                            return {
                              ...prevDoopData,
                              [category]: [
                                ...prevDoopData[category],
                                newSubcategory,
                              ],
                            };
                          }
                        } else {
                          // If category doesn't exist, initialize it as an array with newSubcategory
                          return {
                            ...prevDoopData,
                            [category]: [newSubcategory],
                          };
                        }

                        // Return previous doopData if no changes are made
                        return prevDoopData;
                      });
                    }}
                    category={selectedCategory}
                    year={selectedYear}
                  />
                )}
              </tbody>
            </table>
          </div>
          <div className="row mt-4">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                type="btn-primary"
                disabled={isLoading}
                iconRequired={false}
                handleClick={() => handleSubmit()}
              >
                Save
              </CustomButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TableBuilder;
