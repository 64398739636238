export const statusData = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

export const userRoles = [
  { label: "Admin ", value: "Admin" },
  { label: "Manager", value: "Manager" },
  { label: "Viewer", value: "Viewer" },
];

export const businessTypes = [
  { value: "Private Limited", label: "Private Limited" },
  { value: "Public Limited", label: "Public Limited" },
  { value: "Sole Proprietorship", label: "Sole Proprietorship" },
  { value: "Individual", label: "Individual" },
  { value: "Partnership", label: "Partnership" },
  { value: "Limited Liability Company", label: "Limited Liability Company" },
  {
    value: "Limited Liability Partnership",
    label: "Limited Liability Partnership",
  },
  { value: "Corporation", label: "Corporation" },
  {
    value: "Non-Government Organization",
    label: "Non-Government Organization",
  },
];

export const formType = [
  { value: "MultipleChoice", label: "Multiple choice" },
  { value: "Checkboxes", label: "Checkboxes" },
  { value: "DropDown", label: "Drop-Down" },
];

export const auditStatusOptions = [
  { value: "audited", label: "Audited" },
  { value: "unaudited", label: "Unaudited" },
];

export const periodTypeOptions = [
  { value: "yearly", label: "Yearly" },
  { value: "halfYearly", label: "Half-Yearly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "monthly", label: "Monthly" },
];

export const sheetData = [
  { value: "balance_sheet", label: "Balance Sheet" },
  { value: "pl", label: "P&L" },
  { value: "other", label: "Other" },
];

export const predefinedData = {
  "Sales/Revenue/Turnover": ["Sales", "Revenue", "Turnover"],
  "COGS/Cost of goods sold/Cost of Sales": ["Other"],
  "Operating Expenses/Income": [
    "Accountancy",
    "Accountancy Fees",
    "Administration Wages",
    "Administrative Expenses",
    "Advertising",
    "Advertising and Marketing",
    "Advertising and Trade Marketing",
    "Advertising and Entertaining",
    "Advertising and Promotion",
    "Amazon Advertisement",
    "Amazon Fees",
    "Amortisation",
    "Audit and Accountancy fees",
    "Audit Fees",
    "Automobile Expense",
    "Bad and doubtful debts",
    "Bank and ATM Fee Expense",
    "Bank Fees",
    "Bank Interest and Charges",
    "Bank loan interest",
    "Bank Revaluations",
    "Bank Service Charges",
    "Bonuses",
    "Books",
    "Building repairs",
    "Building Works",
    "Bureau Processing Charges",
    "Business Licenses and Permits",
    "Business Meals Expense",
    "Canteen",
    "Car hire",
    "Charitable and Political Donations",
    "Charitable Contributions",
    "Cleaning",
    "Cleaning and Laundry",
    "Cleaning and Waste",
    "Client Entertainment",
    "Clothing Costs",
    "Commercial Insurances",
    "Commissions",
    "Company Cars (DD)",
    "Compensation of Officer",
    "Computer and Internet Expenses",
    "Computer Costs",
    "Computer Equipment Expense",
    "Computer Software",
    "Consultancy Fees",
    "Contract worker salary",
    "Corporation Tax",
    "Council Chambers",
    "Credit Charges",
    "Credit Insurance",
    "Customs Charges",
    "Directors' pension costs - defined contribution scheme",
    "Directors' Remuneration",
    "Directors Salaries",
    "Directors' social security costs",
    "Discount Allowed and Doubtful Debts",
    "Donations",
    "Dues and Membership Expense",
    "Dues and Subscriptions",
    "eBay Fees",
    "Employee Training Cost",
    "Employers National Insurance",
    "Entertainment",
    "Environmental health and safety",
    "Equipment Expense",
    "Equipment Hire Costs",
    "Equipment Leasing",
    "Equipment repairs",
    "Evergreen and Charges",
    "Facebook advertisement",
    "Facility and Utilities Expense",
    "Fees",
    "Foreign agents' Fee",
    "Foreign Currency Gains and Losses",
    "Foreign Services",
    "Freight",
    "Furniture and Fixtures Expense",
    "Gas and Auto Expense",
    "General Expenses",
    "General Rates",
    "Gifts and Samples",
    "Gifts Expense",
    "Google Advertising and Marketing",
    "Gross Salaries",
    "Gross Wages",
    "Health and safety",
    "Health insurance",
    "Hitachi Charges",
    "Holiday pay adjustment",
    "Hotels",
    "Independent Contractor Expense",
    "Insurance",
    "Insurance Expense - Auto",
    "Insurance Expense - Business",
    "Insurances (not premises)",
    "Internet charges",
    "IT expenses",
    "IT Software and Consumables",
    "IT Support",
    "Job Advertisement",
    "Klarna Fees",
    "Laundry",
    "Lease and Printing Costs",
    "Leasing - motor vehicles",
    "Leasing Costs",
    "Legal and Professional Fees",
    "Legal Expenses",
    "License and Fee Expense",
    "Licenses and Permits",
    "Licensing",
    "Light, Power, water, Heating",
    "Marketing and Advertising Expense",
    "Meals and Entertainment",
    "Medical",
    "Medical Insurance",
    "Merchant Fees Expense",
    "Mileage claims",
    "Motor and Travel",
    "Motor expenses and associated exp (SUNDRY)",
    "Motor Vehicle Expenses",
    "Nondeductible Expense",
    "Nondeductible Fines and Penalties",
    "Office Cleaning",
    "Office Equipment Depreciation",
    "Office Expense",
    "Office Machine Maintenance",
    "Office Stationery",
    "Office Supplies",
    "Office Supply Expense",
    "Operating Lease Payments",
    "Outside Services",
    "Outsourced work",
    "Packaging supplies",
    "Parking and Tolls Expense",
    "Parking Fee",
    "Payroll Expense - Administration",
    "Payroll Expense - Payroll Tax",
    "Payroll Expense - Salary and Wage",
    "Payroll Expenses",
    "Payroll Tax Expenses",
    "Pension Contributions",
    "Pensions Costs",
    "Personnel Expenses",
    "Phone and Internet Expense",
    "Plant Maintenance and Service Contracts",
    "Postage and Delivery",
    "Postage and Shipping Expense",
    "Postage and Carriage",
    "Postage, courier and delivery charges",
    "Postage, Freight and Courier",
    "PPS and telephone",
    "Premises Expenses",
    "Printing and Stationery",
    "Printing and Reproduction",
    "Printing, Postage and Stationery",
    "Productive labour Rent and Rates Electricity Management charges",
    "Professional Fees",
    "Professional Service Expense",
    "Property repairs and maintenance",
    "Rates",
    "Rates and Water (DD)",
    "Recruiting and HR Expense",
    "Rent and Rates",
    "Rent Expense",
    "Rent or Lease Expense",
    "Rent receivable",
    "Repairs and Maintenance",
    "Repairs and Renewals",
    "Research and Development",
    "Research and Development Expense",
    "Revenue Grant Income",
    "Salaries",
    "Salary and Wages",
    "Security and CCTV",
    "Security Costs",
    "Shipping",
    "Social Committee Expenses",
    "Social security costs",
    "Software and Web Hosting Expense",
    "Staff Canteen",
    "Staff pension costs defined contribution",
    "Staff Salaries",
    "Staff Training",
    "Stationery (CONSUMABLES)",
    "Storage Costs",
    "Stripe Fees",
    "Subscriptions and licences",
    "Subsistence",
    "Sundry Expenses",
    "Taxes",
    "Taxes Paid",
    "Telecommunications",
    "Telephone and Internet",
    "Telephone and Fax",
    "Telephone Expense",
    "Temporary staff",
    "Trade Finance Costs",
    "Tradeshow Expense",
    "Training and Education Expense",
    "Training Costs",
    "Transport and Carriage",
    "Travel and Transportation Expense",
    "Travel Expenses",
    "Twitter Advertisement",
    "Utilities",
    "Vehicle Fuel",
    "Wages and salaries",
    "Warranty labour",
    "Waste Collection",
    "Water Rates",
    "Website fees",
    "Write-back of over provision",
  ],
  "Depreciation and Amortization": ["Depreciation", "Amortization"],
  "Finance Expenses/Income": [
    "Bad Debt Provision",
    "Bad Debt Write Off",
    "Bank Charges",
    "CBIL Interest credit",
    "Currency Charges",
    "Donations",
    "evergreen Credit Charges",
    "Exchange Rate Variance",
    "Factoring Charges",
    "H.P. Interest",
    "Hire purchase interest payable",
    "Insurance claim",
    "Interest Paid",
    "Invoice finance interest payable",
    "Lease & HP Charges",
    "Loan Interest",
    "Loan Interest Paid",
    "Mispostings Account",
    "P&L on sale of assets",
    "Other interest",
  ],
  "Other Non-Operating Expenses": [
    "Losses/(Gains) on Foreign Exchange",
    "Profit or loss on foreign exchange",
    "Profit or loss on sale of tangible assets (non exceptional)",
    "Realised Currency Gains",
    "Sale of fixed assets",
    "Unrealised Currency Gains",
  ],
  "Tax Expenses": ["Current Tax", "Deferred Tax"],
  "CURRENT ASSETS": [
    "Accounts Receivable",
    "Advances Suppliers",
    "Associated company accounts",
    "Cash at Bank & in Hand",
    "Corporation Tax",
    "Debtors Control Account",
    "Deferred income",
    "Directors current account",
    "Goods-in Billed Early",
    "Instant Access",
    "Inter-company account",
    "Inventory",
    "Inventory in Transit",
    "Other receivables",
    "Petty Cash",
    "Prepayments",
    "Prepayments & Other Debtors",
    "Stock - Finished Goods",
    "Stock - Raw Material",
    "Stock - WIP",
    "Trade Debtors",
    "Trade Debtors less provision",
    "Marketable Securities",
    "Short-term Investments",
  ],
  "NON-CURRENT ASSETS": [
    "Block plant",
    "Buildings",
    "BUSINESS CAPITAL - (PLANT)",
    "Computer equipment",
    "Fixtures and fittings",
    "Hess Project Costs",
    "Goodwill",
    "Patents",
    "Other Intangible Assets",
    "Leasehold Improvements",
    "Mass Plant Improvements",
    "Mezz Build",
    "Nlotor Vehicles",
    "Office Equipment",
    "Plant & Machinery",
    "Property",
    "Tipping Cells",
    "Long-Term Investments",
  ],
  "CURRENT LIABILITIES": [
    "Accounts Payable",
    "Accruals",
    "Associated company accounts",
    "Cash at Bank & in Hand",
    "Close liquidity loan",
    "Corporation Tax payable",
    "Credit card",
    "Creditors Control Account",
    "Directors' Loan Account",
    "Factoring account",
    "Finance Agreement",
    "Funding circle loan",
    "Hire Purchase due 1 year",
    "Intercompany Accounts",
    "Loan Account",
    "Loan due < 1 year",
    "Loans Due 1 year",
    "Loans due < 1 year",
    "Other Creditors",
    "Other payroll deductions",
    "PAYE Payable",
    "Payroll Taxes",
    "Pensions Payable",
    "Provision for Corporation Tax",
    "Refinance",
    "Social Security Costs",
    "Student Loan Deductions Payable",
    "Suspense account",
    "Trade Capital",
    "Trade Creditors",
    "VAT",
    "Wages Payable - Payroll",
  ],
  "NON-CURRENT LIABILITIES": [
    "Assets Loan (HP)",
    "Child Maintenance Payable",
    "Deferred Tax",
    "Director's Loan",
    "Funding circle - 1 - 5 years",
    "Government Grants",
    "Hire Purchase due > 1 year",
    "Hire Purchase Loan",
    "Loan accounts > 1 year",
    "Loan due > 1 year",
    "Loan Interest",
    "Long Term Bank Loans",
    "Long Term HP & Leasing",
    "Other creditor",
    "Refinance - 1 - 5 years",
    "Sundry provision",
    "Total Creditors: amounts falling due after more than one year",
    "Trade Creditors",
    "Unit refit loan > 1 year",
  ],
  "SHAREHOLDERS EQUITY": [
    "Called-up share capital",
    "Capital & Reserves",
    "Share Capital",
    "Capital redemption reserve",
    "Capital Stock",
    "Current Year Earnings",
    "Dividends declared",
    "Legal Reserve",
    "Loss for period",
    "P & L Account",
    "P&L brought forward",
    "Paid-up Share Capital",
    "Reserves",
    "Retained Earnings",
    "Revaluation Reserve",
    "Share premium",
    "Shareholder's Current Account",
    "Total Capital and Reserves",
  ],
};
