import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";
import Select from "react-select";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getData, postData } from "../../../../../services";
import "./styles.scss";
import CustomButton from "../../../../atoms/CustomButton";
import { capitalizeWords } from "../../../../../helpers/capitalize";
import SelectDropdown from "../../../../molecules/Dropdown";
import Info from "../../../../icons/Info";
import Information from "./info";
import Delete from "../../../../icons/Delete";
import { sortObjectByDateKeys } from "../../../../../helpers/sortYearKeys";

const FinancialAnalysisGenerator = ({
  data,
  preview,
  currency,
  units,
  sheetType,
}) => {
  const [cookies] = useCookies(["t"]);
  const [prompts, setPrompts] = useState([]);
  const [predefinedPrompts, setPredefinedPrompts] = useState([]);
  const [analyses, setAnalyses] = useState([]);
  const [loading, setLoading] = useState({});

  const getPromptList = useCallback(async () => {
    try {
      const res = await getData({
        params: { sheetType },
        endpoint: "prompt/getPromptList",
        token: cookies.t,
      });

      if (res && res?.data && Array.isArray(res?.data.data)) {
        const mappedPrompts = res?.data?.data?.map((prompt) => ({
          label: prompt.prompt,
          value: prompt.prompt,
        }));
        setPredefinedPrompts(mappedPrompts);
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t]);

  useEffect(() => {
    getPromptList();
  }, [getPromptList]);

  const handleDropdownChange = (id, option) => {
    const selectedValue = option && option.label ? option.label : "";
    handlePromptChange(id, selectedValue);

    setAnalyses((analyses) =>
      analyses.map((analysis) =>
        analysis.id === id ? { ...analysis, selectedOption: option } : analysis,
      ),
    );
  };

  const handlePromptChange = (id, value) => {
    setAnalyses(
      analyses.map((analysis) =>
        analysis.id === id ? { ...analysis, prompt: value } : analysis,
      ),
    );
  };

  const handleGenerateAnalysis = async (id) => {
    setLoading((prev) => ({ ...prev, [id]: true }));
    try {
      const analysis = analyses.find((a) => a.id === id);
      const promptToUse = analysis.selectedOption
        ? analysis.selectedOption
        : analysis.prompt;

      const res = await postData({
        endpoint: "extract/generate-analysis",
        data: {
          data: JSON.stringify(data),
          input: promptToUse,
          currency: currency,
          units: units,
        },
        token: cookies.t,
      });
      setAnalyses(
        analyses.map((a) =>
          a.id === id
            ? {
                ...a,
                data: res?.data?.jsonData?.data,
                dataType: res?.data?.jsonData?.type,
                inference: res?.data?.inference,
                calculation: res?.data?.calculation,
                showAnalysis: true,
                selectedMetrics: [],
                chartTypes: {},
                showGraphs: false,
              }
            : a,
        ),
      );
    } catch (error) {
      console.error("Error generating analysis:", error);
    } finally {
      setLoading((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleAddAnalysis = () => {
    setAnalyses([
      ...analyses,
      {
        id: Date.now(),
        prompt: "",
        data: null,
        showAnalysis: false,
        selectedMetrics: [],
        chartTypes: {},
        showTable: true,
        showGraphs: false,
      },
    ]);
  };

  const handleRemoveAnalysis = (id) => {
    setAnalyses(analyses.filter((analysis) => analysis.id !== id));
  };

  const flattenData = (data, prefix = "") => {
    return Object?.entries(data)?.reduce((acc, [key, value]) => {
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object" && value !== null) {
        return { ...acc, ...flattenData(value, newKey) };
      }
      return { ...acc, [newKey]: value };
    }, {});
  };

  const getMetrics = (analysisData) => {
    if (!analysisData) return [];
    const firstYear = Object.keys(analysisData)[0];
    const flattenedData = flattenData(analysisData[firstYear]);
    return Object.keys(flattenedData);
  };

  // const getMetrics = (analysisData) => {
  //   if (!analysisData) return [];
  //   const firstYear = Object.keys(analysisData)[0];
  //   return Object.keys(analysisData[firstYear]).filter(
  //     (metric) => analysisData[firstYear][metric] !== null,
  //   );
  // };

  const getMetricOptions = (analysisData) => {
    const metrics = getMetrics(analysisData);
    return metrics.map((metric) => ({ value: metric, label: metric }));
  };

  const chartTypeOptions = [
    { value: "line", label: "Line Chart" },
    { value: "bar", label: "Bar Chart" },
    { value: "area", label: "Area Chart" },
  ];

  const handleMetricChange = (id, selectedOptions) => {
    const newSelectedMetrics = selectedOptions.map((option) => option.value);
    setAnalyses(
      analyses.map((analysis) => {
        if (analysis.id === id) {
          const newChartTypes = { ...analysis.chartTypes };
          newSelectedMetrics.forEach((metric) => {
            if (!newChartTypes[metric]) {
              newChartTypes[metric] = "line";
            }
          });
          return {
            ...analysis,
            selectedMetrics: newSelectedMetrics,
            chartTypes: newChartTypes,
          };
        }
        return analysis;
      }),
    );
  };

  const handleChartTypeChange = (id, metric, selectedType) => {
    setAnalyses(
      analyses.map((analysis) => {
        if (analysis.id === id) {
          return {
            ...analysis,
            chartTypes: {
              ...analysis.chartTypes,
              [metric]: selectedType.value,
            },
          };
        }
        return analysis;
      }),
    );
  };

  const renderTable = (analysisData, dataType) => {
    if (!analysisData || Object.keys(analysisData).length === 0) {
      return <p>No data available to display.</p>;
    }

    const sortedAnalysisData = sortObjectByDateKeys(analysisData);

    // const years = Object.keys(analysisData);
    // const metrics = getMetrics(analysisData);

    const years = Object.keys(sortedAnalysisData);
    const metrics = getMetrics(sortedAnalysisData);

    return (
      <table className="balance-sheet-table">
        <thead>
          <tr>
            {/* <th>Metric</th> */}
            {/* <th>{`Metric (${dataType})`}</th> */}
            <th>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  contentEditable={true}
                  suppressContentEditableWarning={true} // To avoid React's warning about contentEditable
                  style={{
                    outline: "none",
                    textAlign: "left",
                    cursor: "text",
                    display: "inline-block",
                    // width: "100%",
                  }}
                >
                  {`Metric (${dataType})`}
                </div>
                {preview && (
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "normal",
                      marginLeft: "10px",
                    }}
                  >
                    (Editable)
                  </div>
                )}
              </div>
            </th>

            {years.map((year) => (
              <th key={year}>{year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {metrics.map((metric) => (
            <tr key={metric}>
              <td>{metric}</td>
              {years.map((year) => {
                const flattenedData = flattenData(sortedAnalysisData[year]);
                return (
                  <td key={`${metric}-${year}`}>
                    {flattenedData[metric] !== undefined
                      ? flattenedData[metric]
                      : "N/A"}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const prepareGraphData = (analysisData) => {
    const years = Object.keys(analysisData);
    const metrics = getMetrics(analysisData);
    return years.map((year) => {
      const flattenedData = flattenData(analysisData[year]);
      return {
        year,
        ...Object.fromEntries(
          metrics.map((metric) => [
            metric,
            flattenedData[metric] !== undefined
              ? parseFloat(flattenedData[metric])
              : null,
          ]),
        ),
      };
    });
  };

  // const renderChart = (metric, graphData, chartType) => {
  //   const ChartComponent =
  //     chartType === "bar"
  //       ? BarChart
  //       : chartType === "area"
  //       ? AreaChart
  //       : LineChart;
  //   const DataComponent =
  //     chartType === "bar" ? Bar : chartType === "area" ? Area : Line;

  //   return (
  //     <div style={{ display: "flex", justifyContent: "center" }}>
  //       <ResponsiveContainer width="50%" height={400}>
  //         <ChartComponent data={graphData}>
  //           <XAxis dataKey="year" />
  //           <YAxis />
  //           <Tooltip />
  //           <Legend />
  //           <DataComponent
  //             type="monotone"
  //             dataKey={metric}
  //             fill="#145b64"
  //             stroke="#145b64"
  //             barSize="60"
  //           />
  //         </ChartComponent>
  //       </ResponsiveContainer>
  //     </div>
  //   );
  // };
  const renderChart = (metric, graphData, chartType, dataType) => {
    const ChartComponent =
      chartType === "bar"
        ? BarChart
        : chartType === "area"
        ? AreaChart
        : LineChart;
    const DataComponent =
      chartType === "bar" ? Bar : chartType === "area" ? Area : Line;

    const renderCustomizedLabel = ({ x, y, value }) => {
      return (
        <text
          x={x}
          y={y}
          dy={-10}
          fill="#333"
          // textAnchor="middle"
          fontSize={15}
        >
          {value}
        </text>
      );
    };

    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* Currency and Unit Display */}
        <div
          style={{
            position: "absolute",
            top: "55%",
            left: "22%",
            transform: "rotate(-90deg) translateY(-50%)",
            transformOrigin: "left center",
            fontSize: "16px",
            // fontWeight: "bold",
            color: "#145b64",
            zIndex: 1,
          }}
        >
          {` Values (${dataType})`}
        </div>
        <ResponsiveContainer width="50%" height={400}>
          <ChartComponent data={graphData}>
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Legend />
            <DataComponent
              type="monotone"
              dataKey={metric}
              fill="#145b64"
              stroke="#145b64"
              barSize="60"
              label={renderCustomizedLabel} // Apply label to show values on the chart
            />
          </ChartComponent>
        </ResponsiveContainer>
      </div>
    );
  };

  const renderGraphs = (analysis) => {
    if (!analysis.data || Object.keys(analysis.data).length === 0) {
      return <p>No graph data available.</p>;
    }

    const graphData = prepareGraphData(analysis.data);
    const hasMetrics = analysis.selectedMetrics.length > 0;

    return analysis.selectedMetrics.map((metric) => (
      <>
        {hasMetrics && (
          <div key={metric} className="mb-8">
            {/* <h4 className="graph-heading">{capitalizeWords(metric)}</h4> */}
            <h4 className="graph-heading">{metric}</h4>
            <div className="mb-4">
              {preview && (
                <Select
                  options={chartTypeOptions}
                  onChange={(selectedType) =>
                    handleChartTypeChange(analysis.id, metric, selectedType)
                  }
                  value={chartTypeOptions.find(
                    (option) => option.value === analysis.chartTypes[metric],
                  )}
                  className="w-48"
                />
              )}
            </div>
            {renderChart(
              metric,
              graphData,
              analysis.chartTypes[metric] || "line",
              analysis.dataType,
            )}
          </div>
        )}
      </>
    ));
  };

  const toggleGraphs = (id) => {
    setAnalyses(
      analyses.map((analysis) =>
        analysis.id === id
          ? { ...analysis, showGraphs: !analysis.showGraphs }
          : analysis,
      ),
    );
  };

  return (
    <div>
      {preview && analyses.length === 0 && (
        <CustomButton
          handleClick={handleAddAnalysis}
          type="btn-primary"
          classes="mt-4"
        >
          Add Analysis
        </CustomButton>
      )}

      {analyses.map((analysis, index) => (
        <div
          key={analysis.id}
          className="mt-4"
          style={
            preview
              ? {
                  border: "1px solid lightgrey",
                  borderRadius: "5px",
                  padding: "20px",
                }
              : {}
          }
        >
          {preview && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>
                  Analysis {index + 1}
                </h3>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    marginTop: "5px",
                    fontStyle: "italic",
                  }}
                >
                  (Data Source&nbsp;:&nbsp;
                  {sheetType === "balanceSheet"
                    ? "Balance Sheet Data"
                    : sheetType === "plSheet"
                    ? "Profit and Loss Data"
                    : "Balance Sheet and Profit and Loss Data"}
                  )
                </div>
              </div>

              <div className="mt-3">
                <CustomButton
                  handleClick={() => handleRemoveAnalysis(analysis.id)}
                  type="btn-primary"
                  iconRequired={false}
                >
                  <Delete color="white" />
                </CustomButton>
              </div>
            </div>
          )}
          <div className="flex justify-between items-center mb-4">
            {preview && (
              <div className="mb-3">
                <label htmlFor="">Prompt</label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <SelectDropdown
                    data={predefinedPrompts}
                    placeholder="Select a predefined prompt"
                    onChange={(option) => {
                      handleDropdownChange(prompt.id, option);
                    }}
                    selectedValue={prompt.selectedOption || null}
                    isClearable={true}
                    onClear={() => handlePromptChange(prompt.id, "")}
                    classes={`w-100`}
                    // isRemoveOption={true}
                  /> */}
                  <SelectDropdown
                    data={predefinedPrompts}
                    placeholder="Select a predefined prompt"
                    onChange={(option) => {
                      handleDropdownChange(analysis.id, option);
                    }}
                    selectedValue={analysis.selectedOption || null}
                    isClearable={true}
                    onClear={() => handlePromptChange(analysis.id, "")}
                    classes={`w-100`}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontStyle: "italic",
                      fontSize: "16px",
                    }}
                  >
                    or
                  </span>
                </div>
                <input
                  type="text"
                  value={analysis.prompt}
                  onChange={(e) =>
                    handlePromptChange(analysis.id, e.target.value)
                  }
                  placeholder="Enter your analysis prompt"
                  className="w-100"
                />
              </div>
            )}
            {preview && (
              <CustomButton
                handleClick={() => handleGenerateAnalysis(analysis.id)}
                disabled={loading[analysis.id]}
                type="btn-primary"
                iconRequired={false}
              >
                Generate Analysis
                {/* {loading[analysis.id] ? "Generating..." : "Generate Analysis"} */}
                {loading[analysis.id] && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </CustomButton>
            )}
          </div>

          {analysis.showAnalysis && analysis.data && (
            <div className="mt-4 no-page-break">
              {sheetType === "general" && index === 0 && !preview && (
                <div
                  style={{
                    backgroundColor: "#f0f0f0",
                    padding: "10px",
                    borderBottom: "2px solid #145b64",
                    marginTop: "30px",
                    marginBottom: "10px",
                  }}
                >
                  <h3 className="section-heading">General</h3>
                </div>
              )}
              <h3
                className="text-xl font-semibold mb-2"
                style={{
                  border: "none",
                  outline: "none",
                  fontWeight: "bold",
                }}
                contentEditable={true}
              >
                Financial Analysis {index + 1}
              </h3>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <span
                  contentEditable={true}
                  className="graph-heading"
                  style={{
                    border: "none",
                    outline: "none",
                    fontWeight: "bold",
                  }}
                >
                  Result{" "}
                </span>
                {preview && (
                  <>
                    <Information calculation={analysis?.calculation} />
                    <span style={{ fontSize: "12px" }}>(Calculation)</span>
                  </>
                )}
              </div>
              {renderTable(analysis.data, analysis?.dataType)}
              <div className="">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="section-heading" style={{ fontSize: "22px" }}>
                    Inference
                  </div>
                  {preview && (
                    <span
                      style={{
                        fontSize: "12px",
                        // marginTop: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      (Editable)
                    </span>
                  )}
                </div>
                <div
                  contentEditable={true}
                  style={{
                    whiteSpace: "pre-wrap",
                    marginTop: "10px",
                    fontWeight: 500,
                    backgroundColor: "whitesmoke",
                    padding: "10px",
                    borderRadius: "5px",
                    outline: "none",
                    border: "none",
                  }}
                  className=""
                >
                  {analysis?.inference}
                </div>
              </div>

              <div className="mt-4">
                {preview && (
                  <div className="flex justify-between items-center mb-2">
                    <CustomButton
                      handleClick={() => toggleGraphs(analysis.id)}
                      type="btn-primary"
                      iconRequired={false}
                    >
                      {analysis.showGraphs ? "Remove Graphs" : "Add Graphs"}
                    </CustomButton>
                  </div>
                )}
                {analysis.showGraphs && (
                  <>
                    {preview && (
                      <div>
                        <h4 className="section-heading">Graphs</h4>
                        <Select
                          isMulti
                          options={getMetricOptions(analysis.data)}
                          value={analysis.selectedMetrics.map((metric) => ({
                            value: metric,
                            label: metric,
                          }))}
                          onChange={(selectedOptions) =>
                            handleMetricChange(analysis.id, selectedOptions)
                          }
                          className="mb-4"
                          placeholder="Select metrics for graphs"
                        />
                      </div>
                    )}
                    {renderGraphs(analysis)}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
      {preview && analyses.length > 0 && (
        <CustomButton
          handleClick={handleAddAnalysis}
          type="btn-primary"
          classes="mt-4"
        >
          Add More Analysis
        </CustomButton>
      )}
    </div>
  );
};

export default FinancialAnalysisGenerator;
